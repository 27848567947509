<template>
  <div
    ref="pdfContent"
    class="onprint bg-blueGray-100 md:ml-8 p-1 md:p-0 mx-auto md:mx-0 w-full md:w-11/12 min-h-screen"
  >
    <div
      :class="downloadPage ? 'hidden' : undefined"
      class="flex flex-row-reverse justify-between md:items-start items-center md:flex-col"
    >
      <div class="text-right md:text-left md:mt-5 font-medium text-sm">
        <p class="text-headerText">
          {{
            withdraw
              ? "Wallet/Withdraw To Bank"
              : topup
              ? "Wallet/Fund Wallet"
              : report
              ? "Wallet/ Generate Report"
              : "Wallet"
          }}
        </p>
      </div>
      <div
        v-if="topup || withdraw || report"
        @click="
          () => {
            topup = false;
            withdraw = false;
            report = false;
          }
        "
        class="no-printable cursor-pointer text-left md:mt-5 font-bold text-xl flex items-center gap-2 text-2xl"
      >
        <button class="text-left text-dark-800 font-medium focus:outline-none">
          <i class="fas fa-angle-left mr-3 text-left cursor-pointer"></i>
        </button>
        <span class="text-base text-dark-800"> Back</span>
      </div>
    </div>
    <div v-show="!withdraw && !report" class="text-left mt-5 font-bold text-xl text-2xl">
      {{ topup ? "Please select funding option" : "Wallet" }}
    </div>

    <div class="text-left bg-blueGray-100">
      <!-- <SettingsMenu /> -->
      <!-- <header-stats /> -->
      <div v-if="!topup && !withdraw && !report" class="md:mx-auto w-full mt-10">
        <div class="grid grid-cols-5 gap-6">
          <div
            :class="downloadPage ? 'col-span-5 text-center' : 'col-span-5 md:col-span-3'"
            class="bg-primary addWave shadow-lg border-none border-white py-9 px-6 flex flex-col gap-1 rounded rounded-2xl"
          >
            <span class="font-medium text-white">Wallet Balance</span>
            <div
              :class="downloadPage ? 'text-center justify-center' : undefined"
              class="flex items-center pt-2 gap-x-3"
            >
              <span v-show="hideBalance" class="font-bold text-2xl sm:text-3xl text-white"
                ><span v-for="len in formatAmount(balance.balance).length" :key="len">*</span></span
              >
              <span v-show="!hideBalance" class="font-bold text-2xl sm:text-3xl text-white">{{
                balance.balance ? formatAmount(balance.balance) : formatAmount(0)
              }}</span>
              <img
                class="cursor-pointer -mt-3"
                @click="hideBalance = !hideBalance"
                v-show="!hideBalance"
                src="@/assets/icons/hide-icon.svg"
                alt=""
              />
              <img
                class="cursor-pointer -mt-3"
                @click="hideBalance = !hideBalance"
                v-show="hideBalance"
                src="@/assets/icons/eye-icon.svg"
                alt=""
              />
            </div>
          </div>
          <div :class="downloadPage ? 'hidden' : undefined" class="flex w-full gap-3 col-span-5 md:col-span-2">
            <div
              @click="
                () => {
                  topup = true;
                  withdraw = false;
                  report = false;
                }
              "
              class="group hover:bg-primary cursor-pointer bg-background w-32 justify-self-start border border-primary rounded rounded-3xl py-3 px-0 flex flex-col gap-4 items-center shadow-lg"
            >
              <img class="w-16 mt-4 md:mt-0 md:w-20" src="../../assets/icons/fund-wallet.svg" alt="" />
              <span
                class="group-hover:text-white group-hover:font-bold md:mt-3 cursor-pointer text-grey text-sm font-medium capitalize"
                >Fund Wallet</span
              >
            </div>
            <div
              @click="
                () => {
                  report = false;
                  topup = false;
                  withdraw = true;
                }
              "
              class="group hover:bg-secondary-strawberry cursor-pointer bg-secondary-crepe w-32 justify-self-start border border-secondary-strawberry rounded rounded-3xl py-6 px-0 flex flex-col gap-4 text-center items-center shadow-lg"
            >
              <img class="w-16 md:w-20" src="../../assets/icons/withdraw-money.svg" alt="" />
              <span
                class="group-hover:text-white group-hover:font-bold cursor-pointer text-grey text-sm font-medium capitalize"
                >Withdraw To Bank</span
              >
            </div>
            <div
              @click="
                () => {
                  report = true;
                  topup = false;
                  withdraw = false;
                }
              "
              class="group hover:bg-deepGold cursor-pointer bg-lightGold w-32 justify-self-start border border-deepGold rounded rounded-3xl py-6 px-0 flex flex-col gap-4 text-center items-center shadow-lg"
            >
              <img class="w-16 md:w-20" src="../../assets/icons/generate-report.svg" alt="" />
              <span
                class="group-hover:text-white px-6 md:px-0 group-hover:font-bold cursor-pointer text-dark-800 text-sm font-medium capitalize"
                >Generate Report</span
              >
            </div>
          </div>
        </div>

        <div :class="downloadPage ? 'hidden' : undefined" class="flex justify-between items-center mt-10">
          <span class="font-bold text-dark-800 text-xl">Transactions</span>
          <!-- <div class="flex gap-x-3 md:gap-x-6">
            <button
              @click="handlePage('download')"
              class="flex items-center gap-2 focus:outline-none border border-primary rounded-full text-primary text-sm font-medium py-2 px-4"
            >
              <span class="md:block hidden"> Download</span>
              <img
                class="w-5 md:w-3"
                src="../../assets/icons/download-grey.svg"
                alt=""
              />
            </button>

            <button
              class="flex items-center gap-2 focus:outline-none border border-primary rounded-full text-primary text-sm font-medium py-2 px-4"
            >
              <span class="md:block hidden"> Share</span>
              <img
                class="w-5 md:w-3"
                src="../../assets/icons/share-grey.svg"
                alt=""
              />
            </button>
            <button
              @click="handlePage('print')"
              class="flex items-center gap-2 focus:outline-none border border-primary rounded-full text-primary text-sm font-medium py-2 px-4"
            >
              <span class="md:block hidden"> Print </span>
              <img
                class="w-5 md:w-3"
                src="../../assets/icons/print-grey.svg"
                alt=""
              />
            </button>
          </div> -->
        </div>

        <div
          :class="downloadPage ? 'hidden' : undefined"
          class="flex flex-wrap justify-start md:justify-center w-full gap-2 md:gap-x-5 mt-10 items-center"
        >
          <div :class="toggleFilterView ? 'md:w-1/2 w-10/12' : 'md:w-1/2 w-auto'">
            <walletSearch
              @show="
                ($event) => (
                  (toggleFilterView = $event), $event ? (typeOfTransaction = '') : (typeOfTransaction = 'BOTH')
                )
              "
              :shrink="toggleFilterView"
              @search="searchTrans"
            />
          </div>
          <walletFilter
            class="w-auto cursor-pointer"
            ref="walletFilterRef"
            :hideActiveFilter="toggleFilterView"
            @update="filterData($event)"
          />

          <div
            @click="
              () => {
                toggleFilterView = false;
              }
            "
            class="self-center w-auto"
          >
            <easiSelectInput2
              :placeholder="toggleFilterView ? '>' : 'Select'"
              :shrink="toggleFilterView"
              @update="updateTransactionByType($event)"
              :value="typeOfTransaction"
              :options="[
                { label: 'Inflow & Outflow', value: 'BOTH' },
                { label: 'Inflow', value: 'CREDIT' },
                { label: 'Outflow', value: 'DEBIT' },
              ]"
              :autoHeight="true"
            />
          </div>
        </div>
        <easiActiveFilter class="mt-3" @close="removeFilter" :filters="activeFilters" />

        <empty v-if="visibleData && !visibleData.length" :text="'No Wallet Transactions Yet'" />

        <div v-else class="flex flex-col justify-center w-full gap-y-5 my-6 md:my-5 items-center">
          <p class="text-dark-800 text-lg font-medium text-center mb-4 md:mb-4">
            Inflow and outflow transactions till date
          </p>

          <div class="flex w-full items-center justify-center gap-x-8">
            <div v-if="typeOfTransaction !== 'DEBIT'" class="flex flex-col gap-4 items-center justify-center">
              <span class="text-sm text-dark-800 font-medium"><i class="fas fa-arrow-down mr-2"></i> Inflow</span>

              <div
                style="border: 1px solid #b9bbc0"
                class="rounded-lg bg-successStatus text-white font-bold text-sm w-40 text-center md:w-56 md:text-center md:text-2xl py-4 md:py-2 px-3 text-wrapper"
              >
                {{ formatAmount(getTransactionHistory.inflow) }}
              </div>
            </div>

            <div v-if="typeOfTransaction !== 'CREDIT'" class="flex flex-col gap-4 items-center justify-center">
              <span class="text-sm text-dark-800 font-medium"><i class="fas fa-arrow-up mr-2"></i> Outflow</span>

              <div
                style="border: 1px solid #b9bbc0"
                class="rounded-lg bg-secondary text-white font-bold text-sm w-40 text-center md:w-56 md:text-center md:text-2xl py-4 md:py-2 px-3 text-wrapper"
              >
                {{ formatAmount(getTransactionHistory.outflow) }}
              </div>
            </div>
          </div>

          <div
            :style="downloadPage ? undefined : 'max-height: 700px'"
            class="overflow-y-auto w-full darkBg mt-4 md:p-5"
          >
            <div class="w-full" v-for="(head, i) in headers" :key="i">
              <span class="font-medium text-sm text-dark-800 text-left mb-3">{{ head }}</span>
              <div
                @click="openBar(data)"
                class="flex self-start cursor-pointer flex-col w-full gap-2"
                v-for="(data, k) in visibleData[0][head]"
                :key="k"
              >
                <div class="flex self-start flex-col w-full my-2 gap-2">
                  <div
                    :class="
                      data.transactionType === 'CREDIT'
                        ? 'md:px-5 px-2 md:py-3 py-6 flex md:flex md:justify-between gap-x-3 grid-cols-5 items-center border border-outlineGray bg-white w-full rounded rounded-2xl shadow-sm'
                        : 'px-2 md:px-5 md:py-3 py-6 flex md:flex grid-cols-5 gap-x-4 md:justify-between items-center border border-outlineGray bg-white w-full rounded rounded-2xl shadow-sm'
                    "
                  >
                    <div
                      v-if="data.transactionType === 'CREDIT'"
                      class="hidden md:w-10 md:h-10 md:flex flex-col justify-center items-center rounded-full bg-successStatus"
                    >
                      <!-- <i class="fas fa-arrow-down text-white"></i> -->
                      <img class="w-2 md:w-4" src="../../assets/icons/arrow-down.svg" alt="" />
                    </div>
                    <img
                      v-if="data.transactionType === 'CREDIT'"
                      class="md:hidden"
                      src="../../assets/icons/inflow.svg"
                      alt=""
                    />

                    <div
                      v-if="data.transactionType === 'DEBIT'"
                      class="hidden md:w-10 md:h-10 md:flex flex-col justify-center items-center rounded-full bg-error"
                    >
                      <img class="w-2 md:w-4" src="../../assets/icons/arrow-up.svg" alt="" />
                    </div>
                    <img
                      v-if="data.transactionType === 'DEBIT'"
                      class="md:hidden"
                      src="../../assets/icons/outflow.svg"
                      alt=""
                    />

                    <div class="flex flex-col flex-shrink-0">
                      <span class="text-xs text-dark-200 hidden md:block font-medium">Time</span>
                      <span class="text-xs text-sm text-dark-800">{{ getDateString(data.transactionDate).time }}</span>
                    </div>

                    <div class="text-wrapper flex self-center w-1/2 flex-grow flex-col">
                      <span class="text-xs text-dark-200 hidden md:block font-medium">Description</span>
                      <div class="text-wrapper text-xs text-sm text-dark-800 capitalize">
                        {{ data.description }}
                      </div>
                    </div>

                    <div class="flex flex-col flex-shrink-0">
                      <span class="text-xs text-dark-200 capitalize hidden md:block font-medium">{{
                        data.transactionType.toLowerCase()
                      }}</span>
                      <span
                        :class="data.transactionType === 'CREDIT' ? 'text-success' : 'text-error'"
                        class="text-xs md:text-sm font-bold"
                        >{{ formatAmount(data.amount) }}
                      </span>
                    </div>

                    <div class="hidden md:flex flex-col flex-shrink-0">
                      <span class="text-xs text-dark-200 hidden md:block font-medium">Balance</span>
                      <span class="text-xs text-sm text-dark-800">{{ formatAmount(data.walletBalance) }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <router-view /> -->
      </div>

      <div v-if="topup && !withdraw && !report" class="mt-10">
        <topUp
          @done="closeAll"
          :adminEmail="companyData && companyData.companyAdmin && companyData.companyAdmin.email"
        />
      </div>

      <div v-if="!topup && withdraw && !report" class="mt-10">
        <withdrawComponent
          @close="withdraw = false"
          :adminEmail="companyData && companyData.companyAdmin && companyData.companyAdmin.email"
          :adminPhoneNumber="companyData && companyData.companyAdmin && companyData.companyAdmin.phoneNumber"
        />
      </div>

      <div v-if="!topup && !withdraw && report" class="mt-10">
        <reportComponent />
      </div>
    </div>

    <div class="card flex justify-content-center">
      <SideBar
        v-model:visible="hasData"
        position="right"
        class="md:w-96 w-11/12 bg-greyBg h-full md:pb-4 lg:w-1/2"
        :showCloseIcon="false"
      >
        <div class="relative px-1 w-full h-screen">
          <div ref="headNav" class="bg-white p-4 flex justify-between items-center w-full mb-6">
            <button
              @click="hasData = false"
              class="focus:outline-none float-right bg-newAsh p-3 rounded-full cursor-pointer"
            >
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.05806 5.50142C5.30214 5.25734 5.69786 5.25734 5.94194 5.50142L10.5 10.0595L15.0581 5.50142C15.3021 5.25734 15.6979 5.25734 15.9419 5.50142C16.186 5.7455 16.186 6.14122 15.9419 6.3853L11.3839 10.9434L15.9419 15.5014C16.186 15.7455 16.186 16.1412 15.9419 16.3853C15.6979 16.6294 15.3021 16.6294 15.0581 16.3853L10.5 11.8272L5.94194 16.3853C5.69786 16.6294 5.30214 16.6294 5.05806 16.3853C4.81398 16.1412 4.81398 15.7455 5.05806 15.5014L9.61612 10.9434L5.05806 6.3853C4.81398 6.14122 4.81398 5.7455 5.05806 5.50142Z"
                  fill="#707170"
                />
              </svg>
            </button>
            <p class="text-dark-800 text-lg font-bold text-center w-full justify-self-center">Transaction Details</p>
          </div>

          <ViewReceipt :receiptInfo="infoData" />
        </div>
      </SideBar>
    </div>

    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import html2pdf from "html2pdf.js";

import ViewReceipt from "@/components/Dashboard/ViewReceipt.vue";

import topUp from "@/components/Wallet/Topup.vue";
import withdrawComponent from "@/components/Wallet/Withdraw.vue";
import reportComponent from "@/components/Wallet/GenerateReport.vue";
import walletSearch from "@/components/Search/WalletSearch.vue";
import walletFilter from "@/components/Filters/WalletFilter.vue";
import empty from "@/components/global/EmptyTransaction.vue";

const route = useRoute();
const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;

const { formatAmount, calculatePercentage } = helperFunctions;
const { getTransactionHistory } = storeToRefs(store);

const balance = computed(() => store.getWalletBalance);
const companyData = computed(() => (store.getCompanyAdmin ? store.getCompanyAdmin : {}));

const viewTransactions = computed(() => getTransactionHistory.value);
const companyAccount = computed(() => store.getCompanyAccountDetails);
const typeOfTransaction = ref("BOTH");
const topup = ref(false);
const hideBalance = ref(true);
const withdraw = ref(false);
const report = ref(false);
const loading = ref(false);
const downloadPage = ref(false);
const toggleFilterView = ref(false);
const viewRec = ref(false);
const hasData = ref(false);
const headers = ref([]);
const visibleData = ref([]);
const primaryData = ref([]);

const activeFilters = ref([]);
const walletFilterRef = ref(null);

const infoData = ref({});

const openBar = (obj) => {
  console.log(obj, "obj");
  if (obj.reference) {
    infoData.value = obj;
    hasData.value = true;
  }
};
const transactionArgs = ref({
  startDate: "",
  endDate: "",
  // companyId: "",
});

async function queryCompanyAdmin() {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  await queryCompanyAdmin();
});

function updateData(arr) {
  visibleData.value = [];
  const groupedTransactions = arr.reduce((acc, transaction) => {
    const dateObj = new Date(transaction.transactionDate);
    const transactionDate = dateObj.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    acc[transactionDate] = acc[transactionDate] || [];
    acc[transactionDate].push(transaction);
    return acc;
  }, {});

  const sortedTransactions = Object.entries(groupedTransactions)
    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  // use .push to update template after update
  // for (let d of result) {
  //   visibleData.value.push(d);
  // }
  visibleData.value.push(sortedTransactions);
  headers.value = Object.keys(sortedTransactions);
}

function searchArrayObjects(arr, searchString) {
  return arr.filter((item) => {
    return item.description.toLowerCase().includes(searchString);
  });
}

const searchTrans = (e) => {
  if (e && e.length && e !== undefined) {
    console.log(e);
    visibleData.value = primaryData.value;
    const data = searchArrayObjects(getTransactionHistory.value.transactions, e);
    console.log(data);

    updateData(data);
    console.log(visibleData.value);
  } else {
    visibleData.value = primaryData.value;
    toggleFilterView.value = false;
  }
};
const queryTransactions = async () => {
  // transactionArgs.value.companyId = companyData.value.company._id;
  loading.value = true;
  try {
    const res = await query({
      endpoint: "ViewInflowOutflowBalance",
      payload: {
        input: transactionArgs.value,
      },
      service: "PAYROLL",
      storeKey: "transactionHistory",
    });

    loading.value = false;
    updateData(getTransactionHistory.value.transactions);
    primaryData.value = visibleData.value.map((d) => d);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
queryTransactions();

const balanceInWallet = ref(0);
onMounted(() => {
  balanceInWallet.value = balance.value.balance;
});
const queryWalletBalance = async (recurs) => {
  const id = "";
  if (!recurs) {
    loading.value = true;
  }
  try {
    let res = await query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: id,
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    if (!recurs) {
      loading.value = false;
    }
  }
};
const startQueryingBalance = async () => {
  try {
    const initialBalance = balanceInWallet.value;

    while (true) {
      const newBalance = await queryWalletBalance(true);

      if (newBalance !== initialBalance) {
        balanceInWallet.value = newBalance;
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay between queries
    }
  } catch (err) {
    console.error(err);
  }
};

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}
async function callFuncs() {
  await queryCompanyAccountDetails();
  await queryTransactions();
  await queryWalletBalance(false);
}

callFuncs();

function getDateString(utc) {
  const days = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thur: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };

  const months = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  const dateArr = utc.split(" ");
  const dayOfWeek = days[dateArr[0]];
  const month = months[dateArr[1]];
  const dayAndYear = `${dateArr[2]}, ${dateArr[3]}`;
  const time = dateArr[4].slice(0, -3);

  const fullDate = `${dayOfWeek}, ${month} ${dayAndYear}`;

  return {
    fullDate,
    time,
  };
}

async function filterData({ filter, active }) {
  // console.log(filter, active);
  if (filter) {
    visibleData.value = [];
    transactionArgs.value = filter;
    await queryTransactions();
    updateData(getTransactionHistory.value.transactions);

    activeFilters.value = active;
  }
}

const removeFilter = (arg) => {
  if (walletFilterRef.value && walletFilterRef.value.removeFilter) {
    walletFilterRef.value.removeFilter(arg);
  }
};

onMounted(async () => {
  // queryTransactions();
  const id = route.params.id;
  await callFuncs();
  if (id && id === "fund-wallet") {
    topup.value = true;
    withdraw.value = false;
    report.value = false;
  }
  // updateData();
});

const updateTransactionByType = async (event) => {
  if (event) {
    typeOfTransaction.value = event;
  }
  visibleData.value = [];
  // let fullData = {
  //   inflow: getTransactionHistory.value.inflow,
  //   outflow: getTransactionHistory.value.outflow,
  //   transactions: []
  // }
  if (event === "BOTH") {
    await queryTransactions();
    return "done";
  }
  const data = getTransactionHistory.value.transactions.filter((trans) => trans.transactionType === event);
  updateData(data);
};

async function closeAll() {
  topup.value = false;
  withdraw.value = false;
  report.value = false;
  await queryWalletBalance(false);
}
const pdfContent = ref(null);

const generatePDF = async (action) => {
  try {
    const fileName = "Wallet-report";

    const pdfData = await html2pdf()
      .set({
        margin: 5,
        useCORS: true,
        filename: fileName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      })
      .from(pdfContent.value)
      // .toPdf()
      .save();
    // .output("datauristring");

    // console.log(pdfData, "DD");
    // Convert the PDF data to base64
    // const pdfBase64 = btoa(pdfData);
  } catch (e) {
    console.log(e);
  }
};

function handlePage(action) {
  console.log(action);
  hideBalance.value = false;
  downloadPage.value = true;
  if (action === "download") {
    generatePDF();
  } else if (action === "print") {
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  setTimeout(() => {
    downloadPage.value = false;
  }, 2000);
}
</script>

<style>
@media print {
  .onprint {
    background-color: #ffffff !important;
  }
}

.addWave {
  background-image: url("../../assets/img/wav.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.darkBg {
  background: rgba(242, 241, 241, 0.5);
}
.text-wrapper {
  white-space: normal; /* Override the white-space property */
  word-wrap: break-word;
}
</style>
