<template>
  <div
    @click="emit('click')"
    v-bind="$attrs"
    :class="[color, background]"
    class="cursor-pointer text-sm flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center"
  >
    <slot v-if="$slots.default" />
    <i v-else class="pi pi-times text-sm" />
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "",
  },
  background: {
    type: String,
    default: "bg-newGrey3",
  },
});

const emit = defineEmits(["click"]);
</script>

<style></style>
