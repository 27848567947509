<template>
  <main class="flex flex-col w-full gap-10">
    <div class="no-printable flex flex-col gap-4 w-full md:w-8/12">
      <div
        class="flex justify-between w-full md:justify-self-start self-start gap-x-24"
      >
        <h3 class="font-black text-2xl text-dark-800">Report</h3>

        <div
          class="no-printable w-full flex items-center justify-end gap-2 md:gap-4"
        >
          <easiButton
            @click="downloadAndShare(true, false)"
            variant="outlined"
            color="dark-600"
            class="rounded-sm flex items-center md:gap-2"
            size="small"
          >
            <span class="hidden md:block"> Download</span>
            <img src="@/assets/icons/download.svg" />
          </easiButton>
          <easiButton
            @click="
              () => {
                shareLink = true;
              }
            "
            variant="outlined"
            color="dark-600"
            class="rounded-sm flex items-center gap-2"
            size="small"
          >
            <span class="hidden md:block"> Share</span>
            <img class="w-4" src="@/assets/icons/share.svg" />
          </easiButton>
          <easiButton
            @click="downloadAndShare(true, true)"
            variant="outlined"
            color="dark-600"
            class="rounded-sm flex items-center gap-2"
            size="small"
          >
            <span class="hidden md:block"> Print</span>
            <img src="@/assets/icons/printer.svg" />
          </easiButton>
        </div>
      </div>
      <p class="cursor-pointer text-sm text-grey">
        You must advise Eazipay of any discrepancies observed in this report
        within 15 days of receipt, otherwise it will be deemed correct. All
        products are subject to terms and conditions. <br />
        <span @click="openChat" class="text-hoverColor font-medium">
          Customer care</span
        >
      </p>
    </div>
    <div ref="vue3SimpleHtml2pdf">
      <div
        ref="htmlTemplate"
        :class="
          !showAll
            ? 'shadow-md md:shadow-lg md:mx-auto md:w-10/12 rounded rounded-2xl border border-boxBorder'
            : ''
        "
        class="bg-white w-full px-4 py-4 md:p-8"
      >
        <div
          :class="!showAll ? 'flex-row flex-wrap md:flex-row' : 'flex-row'"
          class="flex gap-y-8 justify-between items-start"
        >
          <div class="flex flex-col gap-12">
            <img
              v-if="companyData && companyData.company.logo"
              :src="companyData.company.logo"
              class="w-16"
              alt=""
            />
            <img v-else src="@/assets/img/comp-logo.png" class="w-16" alt="" />
          </div>

          <div
            v-if="companyData && fullCompanyData"
            class="flex flex-col gap-4 text-sm"
          >
            <div class="flex items-center gap-x-4">
              <img class="w-4" src="@/assets/img/comp-icon.svg" alt="" />
              <span class="text-dark-800 font-medium capitalize">
                {{ companyData.company.companyName }}
              </span>
            </div>

            <div class="flex items-center gap-x-4">
              <img class="w-4" src="@/assets/img/mail-icon.svg" alt="" />
              <span class="text-dark-800 font-medium lowercase">
                {{ fullCompanyData.companyAdmin.email || "N/A" }}
              </span>
            </div>

            <div class="flex items-center gap-x-4">
              <img class="w-4" src="@/assets/img/loc-icon.svg" alt="" />
              <span class="text-dark-800 font-medium capitalize">
                {{ companyData.company.address || "N/A" }}
              </span>
            </div>
          </div>

          <div
            :class="!showAll ? 'w-full md:w-64' : 'w-64'"
            class="flex flex-col bg-background border border-primary text-xs rounded-tr-3xl p-2"
          >
            <div
              :class="
                !showAll
                  ? 'flex-row md:flex-row items-center md:items-start'
                  : 'flex-row'
              "
              class="flex gap-x-2"
            >
              <span class="text-dark-800">Date Generated:</span>
              <span class="text-primary-deep font-bold">{{
                getDateString(currentDate) || "N/A"
              }}</span>
            </div>
            <hr class="border-0.5 border-dark-200 mt-1" />
            <div class="flex gap-1 flex-row mt-1">
              <span class="text-dark-800">Wallet:</span>
              <span class="text-primary-deep font-bold">myeazipaywallet</span>
            </div>
            <hr class="border-0.5 border-dark-200 my-1" />
            <div
              :class="
                !showAll
                  ? 'flex-row md:flex-row items-center md:items-center'
                  : 'flex-row'
              "
              class="flex gap-x-2 text-xs mt-1"
            >
              <span class="text-dark-800 text-xs">Requested By:</span>

              <div
                v-if="
                  fullCompanyData &&
                  fullCompanyData.companyAdmin &&
                  fullCompanyData.companyAdmin.firstName &&
                  fullCompanyData.companyAdmin.lastName
                "
                class="flex items-center gap-x-1"
              >
                <div class="flex gap-x-1 items-center">
                  <div
                    :style="`background-color:${
                      getAvatar({
                        profile: {
                          firstName: fullCompanyData.companyAdmin.firstName,
                          lastName: fullCompanyData.companyAdmin.lastName,
                        },
                      }).color
                    }`"
                    :class="
                      !showAll
                        ? 'pt-1 pl-1 pr-1  md:h-7 md:w-7 w-7 h-7'
                        : 'p-1  h-6 w-6 w-7 h-7'
                    "
                    class="text-xs md:text-xs text-white text-center font-medium rounded-full uppercase"
                  >
                    {{
                      getAvatar({
                        profile: {
                          firstName: fullCompanyData.companyAdmin.firstName,
                          lastName: fullCompanyData.companyAdmin.lastName,
                        },
                      }).name
                    }}
                  </div>
                </div>
                <span class="text-dark-800 text-xs font-bold capitalize"
                  >{{ fullCompanyData.companyAdmin.firstName }}
                  {{ fullCompanyData.companyAdmin.lastName }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="w-full bg-dark-900 md:p-3 px-12 py-3 mt-7 text-sm text-center text-boxBorder"
        >
          Your Wallet Report From {{ getDateString(args.startDate) }} -
          {{ getDateString(args.endDate) }}
        </div>
        <div
          :class="!showAll ? 'flex-col md:flex-row' : 'flex-row'"
          class="flex items-center w-full"
        >
          <div class="flex w-full flex-col items-center justify-center">
            <div
              class="bg-successStatus text-white flex flex-col gap-1 md:gap-3 w-full text-center text-2xl md:text-2xl py-4 md:py-3 px-3"
            >
              <span class="uppercase text-white text-sm md:text-base"
                >total inflow</span
              >
              <span class="font-bold">
                {{ formatAmount(walletReportData.totalInflow) }}</span
              >
            </div>
          </div>

          <div class="flex w-full flex-col items-center justify-center">
            <div
              class="bg-secondary text-white flex flex-col gap-1 md:gap-3 w-full text-center text-2xl md:text-2xl py-4 md:py-3 px-3"
            >
              <span class="uppercase text-white text-sm md:text-base"
                >total outflow</span
              >
              <span class="font-bold">
                {{ formatAmount(walletReportData.totalOutflow) }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="w-full bg-background flex justify-between p-3 text-sm text-center text-dark-800 font-bold"
        >
          <span>
            Opening Balance:
            {{ formatAmount(walletReportData.openningBalance) }}</span
          >
          <span
            >Closing Balance:
            {{ formatAmount(walletReportData.closingBalance) }}</span
          >
        </div>

        <!--Table Starts -->
        <div v-if="!showAll" class="w-full mt-4">
          <ReportTable
            @date="assignDates($event)"
            :data="walletReportData.transactions"
          />
        </div>

        <div v-else class="w-full mt-4">
          <FullReport :data="walletReportData.transactions" />
        </div>
      </div>
    </div>

    <easiModal v-if="selectDuration" @close="selectDuration = false">
      <template v-slot:header>
        <h1 class="text-dark-800 font-medium">Select Duration</h1>
      </template>
      <form
        @submit.prevent="queryWalletReport"
        class="flex flex-col items-center gap-9 w-full mt-3 px-5"
      >
        <span
          class="time-span-color px-3 py-2 text-sm rounded-md w-44 text-center text-grey font-bold"
          >From</span
        >

        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          v-model="args.startDate"
        />

        <span
          class="time-span-color px-3 py-2 text-sm rounded-md w-44 text-center text-grey font-bold"
          >To</span
        >

        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          :min="args.startDate"
          v-model="args.endDate"
        />

        <div class="w-full">
          <hr
            class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor"
          />

          <easiButton type="submit" :block="true" class="w-full"
            >Generate Report</easiButton
          >
        </div>
      </form>
    </easiModal>

    <easiModal v-if="enterOtp" @close="enterOtp = false" :isBorder="false">
      <easiContainer :hideCancel="true" class="w-full py-4 mx-auto text-center">
        <div class="mb-4">
          <h2 class="text-primary font-bold text-2xl sm:text-3xl md:text-4xl">
            OTP Verification
          </h2>
          <span class="text-base md:text-md mt-1 font-medium block">
            Please input OTP sent to your email and phone number
          </span>
        </div>
        <form class="w-full" @submit.prevent="confirmOtp">
          <span class="mb-4 flex justify-around items-center">
            <easiOtp
              size="10"
              :token_length="6"
              :max-length="1"
              v-model="otpCode"
              @input="checkOTP"
              :error="errorRules.otpCode"
            />
          </span>
          <div
            class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
          >
            Did not receive OTP?
            <easiButton
              type="button"
              @click="sendOTP"
              variant="text"
              color="secondary"
              class="text-sm px-0"
              :loading="resendOtpLoading"
              >Resend</easiButton
            >
          </div>
          <easiButton block color="primary" :loading="confirmOtpLoading">
            Submit
          </easiButton>
        </form>
      </easiContainer>
    </easiModal>

    <easiModal
      v-if="shareLink"
      @close="
        () => {
          shareLink = false;
        }
      "
    >
      <template v-slot:header>
        <div class="flex items-center gap-x-4 justify-center">
          <img src="@/assets/icons/share-black.svg" alt="" />
          <h1 class="capitalize md:text-3xl font-medium text-headerText">
            Share
          </h1>
        </div>
      </template>

      <form
        @submit.prevent="downloadAndShare(false, false)"
        class="flex flex-col"
        autocomplete="on"
      >
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <!-- <label for="email" class="text-left text-dark-800 text-sm font-medium"
            >Email Address</label
          > -->
          <easiTextInput
            v-model="args.email"
            placeholder="Input Email Address"
            type="email"
            name="email"
            class="mt-1 mb-5"
            required
          />
        </div>
        <hr
          class="mt-3 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="px-10 w-full">
          <easiButtonNew
            :loading="loading"
            :block="true"
            class="w-full mb-4 md:mb-5"
            >Send Mail</easiButtonNew
          >
        </div>
      </form>
    </easiModal>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1 v-show="generating">Generating Report</h1>
      </template>

      <!-- <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template> -->
    </easiLoading>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Report sent successfully! </span>
      </template>
    </easiSuccess>
  </main>
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import html2pdf from "html2pdf.js";

import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import ReportTable from "@/components/Wallet/ReportTable.vue";
import FullReport from "@/components/Wallet/FullReportPrint.vue";

export default {
  components: {
    ReportTable,
    FullReport,
  },
  setup() {
    const toast = useToast();

    const { formatAmount, calculatePercentage, processDate } = helperFunctions;

    const store = useDataStore();
    const { mutate, query } = useDataStore();
    async function queryCompany() {
      await query({
        endpoint: "FetchCompaniesByAdminId",
        service: "SETTINGS",
        storeKey: "companyDetails",
      });
    }
    const fetchCompanyAdminDetails = async () => {
      await query({
        endpoint: "FetchCompanyAdmin",
        service: "SETTINGS",
        storeKey: "companyAdmin",
      });

      console.log("Company Admin Details");
      // log(adminDetails);
    };

    fetchCompanyAdminDetails();
    queryCompany();

    const addBank = ref(false);
    const generating = ref(false);

    const showPin = ref(false);
    const showOtp = ref(false);
    const showAll = ref(false);
    const bankName = ref("");
    const selectDuration = ref(false);
    const otp = { pin: "" };
    const fullCompanyData = computed(() => store.getCompanyAdmin);
    const companyData = computed(() => store.getCompanyDetails);
    const walletReportData = computed(() => store.getWalletReport);

    const args = ref({
      companyId: "",
      startDate: "",
      endDate: "",
      download: false,
      email: "",
    });

    const updateSuccess = ref(false);
    const otpReference = ref("");
    const enterOtp = ref(false);
    const otpCode = ref("");
    const errorRules = reactive({
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      companyName: false,
      pin: false,
      confirmPin: false,
      referralCode: false,
      otpCode: false,
    });

    const loading = ref(false);
    const otpLoading = ref(false);
    const resendOtpLoading = ref(false);
    const confirmOtpLoading = ref(false);
    const loadingProgress = ref(false);

    const startDate = ref("");

    const argsArray = ref([]);
    console.log(companyData);

    const bankTransfer = ref(true);
    const accountNumber = ref("0711456765");
    const link = ref(
      "9DIJxLyx3H85tP87hYF8eg/EAZIPAY-NEW-WEB-APP-PROJECT?node-id=6150%3EAZIPAY-NEW-WEB-Flutterwaveaccount7."
    );
    const topupOption = ref(false);
    const payStack = ref(false);
    const linkGenerated = ref(false);
    const shareLink = ref(false);
    const transferDetails = ref(false);
    const transferNotification = ref(false);

    function getDateString(dateText) {
      if (!dateText) {
        return "N/A";
      }
      const dateArr = dateText.split("-");
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const dd = dateArr[2];
      const mm = months[Number(dateArr[1]) - 1];
      const yyyy = dateArr[0];
      return `${mm} ${dd}, ${yyyy}`;
    }

    const currentDate = ref(processDate());

    async function queryWalletReport() {
      generating.value = true;
      loadingProgress.value = true;
      try {
        args.value.companyId = companyData.value._id;
        await query({
          endpoint: "ViewWalletReport",
          service: "PAYROLL",
          storeKey: "walletReport",
          payload: { input: args.value },
        });
        // console.log(walletReportData.value, "WR");
        loadingProgress.value = false;
        selectDuration.value = false;
      } catch (e) {
        loadingProgress.value = false;
        console.log(e);
      } finally {
        loadingProgress.value = false;
        generating.value = false;
      }
    }

    function copyText(val) {
      var text = val;
      navigator.clipboard.writeText(text);
      toast.info("Copied!");
    }
    const payrollInterval = ref(["Weekly", "Monthly"]);
    function generatePaymentLink() {
      payStack.value = true;
    }

    function checkPin(otp_input) {
      otp.pin = otp_input;
    }

    function addMore(arg) {
      const newObj = Object.assign({}, args);

      argsArray.value.push(newObj);

      store.$patch((state) => {
        state.profileArr.push(newObj);
      });
      toast.success("Details saved!");
    }

    async function save() {
      addMore(args);
      Object.assign(args, resetObj(args, "args"));
    }

    function getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    }

    const getAvatar = (employee) => {
      let name = "",
        color;
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const empId = employee.job && employee.job.userName;
      const thenum = empId ? Number(empId.replace(/\D/g, "")) : 1;
      const initialOne = String(
        employee &&
          employee.profile &&
          employee.profile.firstName &&
          employee.profile.firstName.charAt(0)
      );
      const initialTwo = String(
        employee &&
          employee.profile &&
          employee.profile.lastName &&
          employee.profile.lastName.charAt(0)
      );
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);
      // Get color

      employee
        ? (name = `${
            employee &&
            employee.profile &&
            employee.profile.firstName &&
            employee.profile.firstName.charAt(0)
          }${
            employee &&
            employee.profile &&
            employee.profile.lastName &&
            employee.profile.lastName.charAt(0)
          }`)
        : "";

      return {
        color,
        name,
      };
    };

    const printPage = () => {
      showAll.value = true;
      setTimeout(() => {
        window.print();
      }, 1000);
    };
    onafterprint = (event) => {
      showAll.value = false;
    };

    async function sendOTP() {
      const admin = fullCompanyData.value.companyAdmin;
      //validate form field
      const payload = {
        channel: "EMAIL",
        token_length: 4,
        email: admin.email,

        // type: "SIGNUP",
        phoneNumber: admin.phoneNumber,

        firstName: admin.firstName,
      };

      otpLoading.value = true;

      try {
        let res = await mutate({
          endpoint: "SendOTP",
          data: { input: payload },
          service: "AUTH",
        });

        if (res.success) {
          enterOtp.value = true;
          otpLoading.value = false;
          otpReference.value = res.data.reference;
          // toast.success("OTP")
          // window.localStorage.setItem("otpReference", res.data.reference);
        }
      } catch (e) {
        otpLoading.value = false;
      }
    }

    async function confirmOtp() {
      if (otpCode.value.trim() === "") {
        errorRules.otpCode = "Please enter the otp code sent to your email";
      } else {
        confirmOtpLoading.value = true;
        loadingProgress.value = true;

        const payload = {
          reference: otpReference.value,
          code: otpCode.value,
        };

        try {
          let res = await mutate({
            endpoint: "ConfirmOTP",
            data: { input: payload },
            service: "AUTH",
          });

          if (res.success) {
            //Clear error state
            errorRules.otpCode = false;
            loadingProgress.value = false;

            //Register user
            // await processPayroll();
            confirmOtpLoading.value = false;
            enterOtp.value = false;
          }
        } catch (e) {
          loadingProgress.value = false;
          confirmOtpLoading.value = false;
          e.message.toLowerCase() == "record not found"
            ? (errorRules.otpCode = "OTP doesn't match. Please try again")
            : null;
        }
      }
    }

    const pdfOptions = reactive({
      margin: 10,
      image: {
        type: "jpeg",
        quality: 1,
      },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "letter",
        orientation: "p",
      },
    });

    function assignDates(e) {
      if (e && Object.keys(e).length) {
        args.value.startDate = e.startDate;
        args.value.endDate = e.endDate;
      }
    }
    const vue3SimpleHtml2pdf = ref(null);
    const generatePDF = async () => {
      showAll.value = true;

      try {
        const fileName = "Wallet-report";

        const pdfData = await html2pdf()
          .set({
            margin: 5,
            useCORS: true,
            filename: fileName,
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: {
              unit: "mm",
              format: "a4",
              orientation: "portrait",
            },
          })
          .from(vue3SimpleHtml2pdf.value)
          // .toPdf()
          .save();
        // .output("datauristring");
        showAll.value = false;

        // console.log(pdfData, "DD");
        // Convert the PDF data to base64
        // const pdfBase64 = btoa(pdfData);
      } catch (e) {
        // showToast(e);
        console.log(e);
      }
    };

    const payslipName = computed(() => {
      return `report.pdf`;
    });

    function downloadBase64AsPDF(base64Data, fileName) {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      // Handle Safari-specific behavior
      if (navigator.userAgent.includes("Safari")) {
        link.target = "_blank";
        // Open the PDF in a new window if download is not supported
      }

      link.click();
    }

    function openPdfInNewTab(base64Pdf) {
      // Create a Blob from the base64 PDF string
      const pdfData = atob(base64Pdf);
      const arrayBuffer = new ArrayBuffer(pdfData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < pdfData.length; i++) {
        uint8Array[i] = pdfData.charCodeAt(i);
      }
      const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new browser tab
      const newTab = window.open(pdfUrl, "_blank");

      // Trigger print dialog when new tab is loaded
      if (newTab) {
        newTab.onload = function () {
          newTab.print();
        };
      }
    }

    async function downloadAndShare(download, print) {
      loadingProgress.value = true;
      if (download === true) {
        args.value.download = true;
      }
      try {
        // args.value.companyId = companyData.value._id;
        let res = await mutate({
          endpoint: "SendWalletReportEmail",
          data: { input: args.value },
          service: "PAYROLL",
        });
        if (res.success) {
          selectDuration.value = false;
          if (download && !print) {
            downloadBase64AsPDF(res.data.base64, res.data.fileName);
          }
          if (print) {
            openPdfInNewTab(res.data.base64);
          }
          if (!print && !download) {
            updateSuccess.value = true;
            shareLink.value = false;
            setTimeout(() => {
              updateSuccess.value = false;
            }, 2000);
          }
        } else {
          toast.error(res.message);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      } finally {
        loadingProgress.value = false;
      }
    }
    function openChat() {
      // Trigger the Tawk.to chat
      if (typeof Tawk_API !== "undefined") {
        Tawk_API.toggle();
      }
    }

    return {
      openChat,
      vue3SimpleHtml2pdf,
      generatePDF,
      assignDates,
      pdfOptions,
      payslipName,
      formatAmount,
      calculatePercentage,

      //Start Here

      store,
      mutate,
      query,

      addBank,
      otp,
      showPin,
      showOtp,
      showAll,
      bankName,
      selectDuration,
      fullCompanyData,
      args,

      updateSuccess,
      otpReference,
      enterOtp,
      otpCode,
      errorRules,

      loading,
      otpLoading,
      resendOtpLoading,
      confirmOtpLoading,
      loadingProgress,

      startDate,
      getDateString,
      currentDate,

      argsArray,
      companyData,

      bankTransfer,
      accountNumber,
      link,
      topupOption,
      payStack,
      linkGenerated,
      shareLink,
      transferDetails,
      transferNotification,
      copyText,
      payrollInterval,
      generatePaymentLink,

      checkPin,

      addMore,

      save,

      getAvatar,
      printPage,
      onafterprint,
      sendOTP,
      confirmOtp,

      queryWalletReport,
      walletReportData,
      toast,
      downloadAndShare,
      generating,
    };
  },
  data() {
    return {
      emailAddress: "",
      mailArgs: {
        HTMLPart: null,
        To: [
          {
            Email: "",
          },
        ],
        Subject: "",
      },
    };
  },
  methods: {
    generate() {
      this.showAll = true;
      setTimeout(() => {
        this.$refs.vue3SimpleHtml2pdf.download();
        this.showAll = false;
      }, 1000);
    },

    async sendEmail() {
      let div = this.$refs.htmlTemplate;
      this.mailArgs.HTMLPart = div.innerHTML;

      this.mailArgs.Subject = `${this.getDateString(
        this.args.startDate
      )} - ${this.getDateString(this.args.endDate)}`;
      this.mailArgs.To[0].Email = this.emailAddress;
      this.loading = true;

      try {
        let res = await this.mutate({
          endpoint: "SendEmail",
          data: { input: this.mailArgs },
          service: "PAYROLL",
        });
        if (res.success) {
          this.loading = false;
          this.shareLink = false;
        } else {
          this.toast.error(res.message);
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style>
@media print {
  .no-printable {
    display: none !important;
  }
  .printable {
    display: block;
  }
}

.time-span-color {
  background: #f2f1f1;
}
</style>
