<template>
  <div class="radio-btns">
    <div class="radio-btn" v-for="option of options" :key="option.value">
      <label>
        <input
          type="radio"
          :value="option.value"
          :name="name"
          :checked="option.value === modelValue"
          @change="handleChange"
        />
        <span>{{ option.label }} </span>
      </label>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  name: String,
  options: {
    type: Array,
  },
  modelValue: String,
});
const emits = defineEmits(["update:modelValue", "change"]);

const handleChange = (e) => {
  let { checked, value } = e.target;
  if (checked) {
    emits("change");
    emits("update:modelValue", value);
  }
};
</script>

<style lang="scss" scoped>
.radio-btns {
  border: 1.15042px solid #e7e8e7;
  padding: 6px;
  border-radius: 16px;
  display: flex;
  gap: 10px;

  & > * {
    flex: 1;
  }
}
.radio-btn {
  cursor: pointer;
  span {
    height: 40px;
    border-radius: 12px;
    background: #f2f1f1;
    color: #515251;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }

  input {
    display: none;
  }

  input:checked ~ span {
    background: #11453b;
    color: white;
    box-shadow: 0px 5.06963px 13.9415px -3.80222px rgba(24, 39, 75, 0.12),
      0px 8.87185px 40.557px -2.53481px rgba(24, 39, 75, 0.12);
  }
}
</style>
