<template>
  <div class="relative">
    <form @submit.prevent="contactUs" class="contact-us-form flex max-w-md mx-auto" :class="{ loading: isLoading }">
      <div class="flex-1">
        <input type="text" class="w-full" placeholder="Your email address" required v-model="emailAddr" />
      </div>
      <button class="flex-shrink-0 px-2 py-2 bg-transparent">
        <SendIcon />
      </button>
    </form>
    <Transition>
      <div class="mt-2 text-sm" v-if="status !== null">
        <div class="text-green-500" v-if="status">
          Thank you for reaching out. we will get back soon.
        </div>
        <div class="text-red-500" v-if="status === false">
          Ooops! we couldn't save your email. Try again!
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import SendIcon from "./icons/Send.vue";

const status = ref(null);
const isLoading = ref(false);
const emailAddr = ref("");

watchEffect((cleanup) => {
  if (status.value !== null) {
    let t = setTimeout(() => {
      status.value = null;
    }, 5000);

    cleanup(() => {
      clearTimeout(t);
    });
  }
});
const contactUs = () => {
  isLoading.value = true;
  setTimeout(() => {
    isLoading.value = false;
    status.value = true;
    emailAddr.value = "";
  }, 5000);
};
</script>

<style lang="scss" scoped>
.contact-us-form {
  background-color: #f2f1f1;
  border-radius: 16px;
  overflow: hidden;
  padding: 0px 10px;

  input {
    border: none;
    outline: none;
    width: 100%;
    padding: 12px 10px;
    background-color: #f2f1f1;
    box-shadow: none;
  }

  button {
    display: inline-block;
    transition: 0.15s ease;
    outline: none;

    &:hover {
      transform: rotate(48deg);
    }
  }

  &.loading {
    opacity: 0.5;
    pointer-events: none;

    button {
      transform: rotate(48deg);
    }
  }
}

.v-enter-active,
.v-leave-active {
  max-height: 200px;
  transition: 0.35s ease;
}


.v-enter-from,
.v-leave-to {
  opacity: 0;
  max-height: 0;
  transform: translateY(5px);
}
</style>
