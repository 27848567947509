<template>
  <div
    v-bind="$attrs"
    :class="[
      classic
        ? 'rounded-full bg-grayLight'
        : 'border border-outlineGray rounded-xl bg-white',
    ]"
    class="flex items-center mx-auto w-full tracking-wide justify-center gap-x-2 p-2 text-sm font-medium cursor-pointer"
  >
    <span
      v-for="tab in tabItems"
      :key="tab"
      @click="setActive(tab)"
      :class="[
        active === tab
          ? `${
              classic ? 'bg-white text-primary ' : 'bg-primary  text-white'
            } shadow-2xl`
          : `${classic ? '' : 'bg-newGrey md:shadow-none shadow-2xl'}`,

        classic ? 'rounded-full' : 'rounded-lg',
      ]"
      class="w-full truncate cursor-pointer capitalize text-sm text-center md:px-5 md:py-2 px-3 py-2"
      >{{ tab }}</span
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  plain: {
    type: Boolean,
    default: false,
  },
  classic: {
    type: Boolean,
    default: false,
  },
  active: String,
  tabItems: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["update:active"]);

const computeStyles = computed(() => {
  let tabStyle = "border border-outlineGray rounded-xl bg-white";
  let itemStyle = "";

  if (props.classic) {
    tabStyle = "ounded-full bg-grayLight";
  }

  return {
    tabStyle,
    itemStyle,
  };
});

const setActive = (arg) => {
  if (!arg) return;
  emit("update:active", arg);
};
</script>

<style></style>
