<template>
  <div v-if="allEmployees.length">
    <!-- //////// Desktop View Start //////// -->
    <div class="hidden md:flex flex-col text-right">
      <div class="flex gap-x-4 items-center justify-end p-2">
        <span
          >{{ startItem }} -
          {{ endItem }}
          of {{ allEmployees.length }}</span
        >
        <select
          name=""
          id=""
          class="px-1 pr-8 md:py-2 py-3 rounded-xl focus:outline-none focus:border-outlineGray border border-outlineGray text-center text-sm font-medium uppercase"
          v-model="pageSize"
          @change="updateSize($event.target.value)"
        >
          <option
            v-for="s in [
              '15',
              '20',
              '30',
              '40',
              '50',
              '60',
              '70',
              '80',
              '90',
              '100',
            ]"
            :key="s"
            :value="s"
            :selected="s === '15' ? true : false"
          >
            {{ s }}
          </option>
        </select>
      </div>
      <!-- border-t border-outlineGray -->
      <div
        class="overflow-x-auto border-t border-outlineGray rounded-t-2xl text-primary"
      >
        <table style="min-width: 900px" class="w-full overflow-hidden">
          <thead class="">
            <tr class="text-header font-bold shadow-md bg-highlight relative">
              <th
                class="bg-tableHead text-left font-bold capitalize text-sm tracking-wide text-dark-800 logo text-center whitespace-nowrap p-0 sticky left-0"
              >
                <div class="border border-outlineGray px-2 py-3">
                  <input
                    type="checkbox"
                    :checked="allChecked"
                    @change="toggleCheckAll()"
                  />
                </div>
              </th>

              <th
                v-for="(title, index) in headers"
                :key="index"
                :class="getStickyClass(index)"
                class="text-left font-bold capitalize text-sm tracking-wide text-dark-800 uppercase logo rounded-lg text-center whitespace-nowrap p-0"
                style="background-color: rgba(240, 247, 235, 0.5)"
              >
                <div class="px-2 py-3 uppercase border border-outlineGray">
                  {{ title }}
                </div>
              </th>
            </tr>
          </thead>
          <div class="p-2"></div>

          <tbody v-if="visibleData.length" class="relative">
            <div v-if="loading" class="absolute left-0 -top-1 w-full">
              <easiLoader />
            </div>
            <tr
              v-for="employee in visibleData"
              :key="employee._id"
              class="hover:bg-easigrey group capitalize text-tableBpdy cursor-pointer relative"
              :class="
                selectedEmployees.includes(employee._id)
                  ? 'bg-mint'
                  : 'bg-white'
              "
            >
              <td
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-mint sticky left-0"
              >
                <div class="text-center">
                  <input
                    :checked="selectedEmployees.includes(employee._id)"
                    @change="selectEmployee(employee._id)"
                    type="checkbox"
                    name=""
                    id=""
                  />
                </div>
              </td>

              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray sticky left-8"
              >
                {{ employee.job.userName ? employee.job.userName : "N/A" }}
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left bg-background-200 text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray sticky left-32"
              >
                <div class="flex items-center gap-2">
                  <div
                    v-if="employee.profile.pfp"
                    class="border w-9 h-9 rounded-full overflow-hidden"
                    :style="`border:1px solid ${getAvatar(employee).color}`"
                  >
                    <img
                      class="w-full h-full object-cover"
                      :src="employee.profile.pfp"
                      :alt="
                        employee.profile.firstName +
                        ' ' +
                        employee.profile.lastName
                      "
                    />
                  </div>
                  <div
                    v-else
                    class="bg-primary p-2 h-9 w-9 text-sm text-white rounded-full flex justify-center items-center font-medium"
                  >
                    {{ getAvatar(employee).name }}
                  </div>
                  {{ employee.profile.firstName }}
                </div>
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left bg-background-200 text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray sticky left-56"
              >
                {{ employee.profile.lastName }}
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray"
              >
                {{ employee.job.jobTitle }}
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray"
              >
                {{
                  employee.department && employee.profile.email
                    ? employee.profile.email
                    : "N/A"
                }}
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray"
              >
                <!-- {{ formatEmploymentType(employee.job.employmentType) }} -->{{
                  (employee && employee.branch && employee.branch.name) || "N/A"
                }}
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray"
              >
                <!-- {{ formatEmploymentType(employee.job.employmentType) }} -->{{
                  (employee && employee.profile && employee.profile.gender) ||
                  "N/A"
                }}
              </td>
              <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray"
              >
                <!-- {{ formatEmploymentType(employee.job.employmentType) }} -->{{
                  (employee &&
                    employee.staffLevel &&
                    employee.staffLevel.paye &&
                    employee.staffLevel.paye.taxId) ||
                  "N/A"
                }}
              </td>
              <!-- <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-outlineGray"
              >
                <div class="flex flex-col gap-3 relative w-full">
                  <Tag
                    v-if="
                      employee &&
                      employee.prorate &&
                      employee.prorate.proratedSalary
                    "
                    style="width: 80px"
                    severity="info"
                    class="self-end -mr-2 -mt-2 text-xs"
                  >
                    <span class="text-xs">PRORATED</span></Tag
                  >
                  {{
                    formatAmount(
                      employee.prorate && employee.prorate.staffLevel
                        ? employee.prorate.staffLevel.netAmount
                        : employee.staffLevel && employee.staffLevel.netAmount
                    )
                  }}
                </div>
              </td> -->
              <!-- <td
                @click="viewEmployee(employee)"
                class="border border-outlineGray text-left text-sm p-2 font-semibold capitalize group-hover:bg-mint group-hover:border-outlineGray"
                :class="getStatusColor(employee.profile.status)"
              >
                {{
                  employee.profile.status === "ACTIVE"
                    ? "Accepted"
                    : employee.profile.status
                }}
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- //////// Desktop View Ends ////////  -->

    <!-- //////// Mobile View Start //////// -->
    <div class="flex md:hidden w-full" v-if="visibleData.length">
      <div class="w-full">
        <div class="w-full flex items-center justify-between mb-2">
          <div class="flex items-center mb-4 gap-2">
            <input
              class="border-2 h-5 w-5"
              type="checkbox"
              :checked="allChecked"
              @change="toggleCheckAll()"
            />

            <span class="text-newLightgrey">Select All</span>
          </div>

          <div class="flex items-center justify-end gap-x-3">
            <span
              >{{ startItem }} -
              {{ endItem }}
              of {{ allEmployees.length }}</span
            >
            <select
              name=""
              id=""
              class="px-1 pr-8 md:py-2 py-3 rounded-xl focus:outline-none focus:border-outlineGray border border-outlineGray text-center text-sm font-medium uppercase"
              v-model="pageSize"
              @change="updateSize($event.target.value)"
            >
              <option
                v-for="s in [
                  '15',
                  '20',
                  '30',
                  '40',
                  '50',
                  '60',
                  '70',
                  '80',
                  '90',
                  '100',
                ]"
                :key="s"
                :value="s"
                :selected="s === '15' ? true : false"
              >
                {{ s }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="loading" class="w-full">
          <easiLoader />
        </div>
        <div
          v-for="employee in visibleData"
          :key="employee._id"
          class="w-full mb-8"
        >
          <table class="table-auto w-full overflow-y-auto">
            <tbody class="font-medium text-sm">
              <tr>
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">
                    <input
                      :checked="selectedEmployees.includes(employee._id)"
                      @change="selectEmployee(employee._id)"
                      type="checkbox"
                      name=""
                      id=""
                    />
                    First Name
                  </div>
                </td>
                <td
                  @click="viewEmployee(employee)"
                  class="w-8/12 border border-outlineGray text-left p-2 px-3 bg-background-200 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  <div class="flex items-center gap-2">
                    <div
                      v-if="employee.profile.pfp"
                      class="border w-8 h-8 rounded-full overflow-hidden"
                    >
                      <img
                        class="-full"
                        :src="employee.profile.pfp"
                        :alt="
                          employee.profile.firstName +
                          ' ' +
                          employee.profile.lastName
                        "
                      />
                    </div>
                    <div
                      v-else
                      class="bg-primary p-2 h-8 w-8 text-xs text-white font-medium rounded-full"
                    >
                      {{ getAvatar(employee).name }}
                    </div>
                    {{ employee.profile.firstName }}
                  </div>
                </td>
              </tr>
              <tr @click="viewEmployee(employee)">
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Last Name</div>
                </td>
                <td
                  class="w-8/12 border border-outlineGray text-left p-2 bg-background-200 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.profile.lastName }}
                </td>
              </tr>
              <tr @click="viewEmployee(employee)">
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Job Title</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.job.jobTitle }}
                </td>
              </tr>

              <tr @click="viewEmployee(employee)">
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Email address</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.profile.email || "N/A" }}
                </td>
              </tr>
              <!-- <tr
                v-if="showAllEmployee === employee._id"
                @click="viewEmployee(employee)"
              >
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Department</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.department && employee.department.name }}
                </td>
              </tr> -->
              <tr
                v-if="showAllEmployee === employee._id"
                @click="viewEmployee(employee)"
              >
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Branch</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ (employee.branch && employee.branch.name) || "N/A" }}
                </td>
              </tr>
              <!-- <tr
                v-if="showAllEmployee === employee._id"
                @click="viewEmployee(employee)"
              >
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center text-left gap-2">
                    Employment Type
                  </div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.job.employmentType }}
                </td>
              </tr> -->
              <tr
                v-if="showAllEmployee === employee._id"
                @click="viewEmployee(employee)"
              >
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Employee ID</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.job.userName }}
                </td>
              </tr>
              <tr
                v-if="showAllEmployee === employee._id"
                @click="viewEmployee(employee)"
              >
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Gender</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{ employee.profile.gender || "N/A" }}
                </td>
              </tr>
              <tr
                v-if="showAllEmployee === employee._id"
                @click="viewEmployee(employee)"
              >
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Tax ID</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                >
                  {{
                    employee.staffLevel &&
                    employee.staffLevel.paye &&
                    employee.staffLevel.paye.taxId
                  }}
                </td>
              </tr>
              <!-- <tr @click="viewEmployee(employee)">
                <td
                  class="w-4/12 border p-2 bg-lightPrimary text-dark-800 font-bold border-outlineGray"
                >
                  <div class="flex items-center gap-2">Status</div>
                </td>
                <td
                  class="w-8/12 bg-white border border-outlineGray text-left p-2 px-3 group-hover:bg-lightPrimary text-dark-800 font-bold group-hover:border-outlineGray"
                  :class="getStatusColor(employee.profile.status)"
                >
                  {{
                    employee.profile.status === "ACTIVE"
                      ? "ACCEPTED"
                      : employee.profile.status
                  }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <div
            class="w-full bg-dark-100 text-dark-900 border border-outlineGray flex items-center justify-between text-xs px-3"
            @click="showAll(employee._id)"
          >
            <span>{{
              showAllEmployee === employee._id
                ? "Less Information"
                : "More Information"
            }}</span>
            <i
              :class="
                showAllEmployee === employee._id
                  ? 'fas fa-angle-up'
                  : 'fas fa-angle-down'
              "
              class="cursor-pointer text-base text-dark-600"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- //////// Mobile View End //////// -->
  </div>

  <!-- /////////// If there's no employee ////// -->
  <EmptyTable v-if="!isSearch && !allEmployees.length" />

  <!-- /////////// Search Not found ////// -->
  <div
    v-if="isSearch && !allEmployees.length"
    class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
  >
    <div class="flex flex-col text-left col-span-2 md:col-span-1">
      <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
        Oops!
      </h1>
      <div class="text-sm md:text-lg">
        Sorry we could not find a result for your search
      </div>
      <p class="text-xs md:text-sm mt-2">
        Check your spelling or try a different name. Make sure you search by
        <span class="text-secondary font-medium">Name</span>,
        <span class="text-secondary font-medium">Employee ID</span> or
        <span class="text-secondary font-medium">Department</span>
      </p>
    </div>
    <div class="col-span-2 md:col-span-1">
      <img src="@/assets/icons/search_null.svg" alt="" />
    </div>
  </div>

  <!-- //////// Pagination //////// -->
  <easiPagin
    v-if="allEmployees.length"
    :data="allEmployees"
    @page:update="updatePage"
    :currentPage="currentPage"
    @size="updateSize"
    :pageSize="pageSize"
    :showSizes="true"
    class="my-4"
  />
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import EmptyTable from "./emptyTable.vue";

const { formatAmount, getAvatar } = helperFunctions;
const route = useRoute();
const router = useRouter();
const store = useDataStore();
const { query, set } = store;
const { listDepartments } = storeToRefs(store);

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  isSearch: {
    type: Boolean,
    default: false,
  },
  employees: {
    type: Array,
    default: [],
  },
  from: {
    type: String,
    default: "view",
  },
});

const emit = defineEmits(["selected"]);

const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: null,
    // filter: [],
    // sort: [],
  },
});

const startItem = computed(() => {
  return currentPage.value * pageSize.value + 1;
});

const endItem = computed(() => {
  const lastItem = (currentPage.value + 1) * pageSize.value;
  return Math.min(lastItem, allEmployees.value.length);
});

const isSearchFound = ref(true);
const checked = ref(false);
let allChecked = ref(false);

let selectedEmployees = ref([]);
let showAllEmployee = ref("");

const headers = reactive([
  "Employee Id",
  "First Name",
  "Last Name",
  "Job Title",
  "Email Address",
  "Branch",
  "Gender",
  "Tax Id",
  // "Department",
  // "Employment Type",
  // "Net Pay",
  // "Status",
]);

onMounted(() => {
  queryDepartment();
  updateVisibleData();
  visibleData.value = allEmployees.value;
});

//////// Computed ////////
const allEmployees = computed(() => props.employees);

watch(allEmployees, (val) => {
  updateVisibleData();
});

//////// Functions ////////

const queryDepartment = async () => {
  await query({
    endpoint: "ListDepartments",
    payload: payload,
    service: "SETTINGS",
    storeKey: "ListDepartments",
  });
};

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = allEmployees.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};

const updateSize = (sz) => {
  pageSize.value = Number(sz);
  updateVisibleData();
};

const getStatusColor = (status) => {
  const colors = {
    ACTIVE: "text-success",
    INVITED: "text-primary",
    NOT_INVITED: "text-secondary",
    PENDING: "text-fuchsia-900",
  };

  return colors[status];
};

const getStickyClass = (index) => {
  const obj = {
    0: 8,
    1: 32,
    2: 56,
  };
  return [0, 1, 2].includes(index) ? `sticky left-${obj[index]}` : "";
};

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const formatEmploymentType = (type) => {
  return type ? type.split("_").join(" ") : "N/A";
};

const toggleCheckAll = () => {
  allChecked.value = !allChecked.value;

  if (allChecked.value) {
    allEmployees.value.forEach((el) => {
      if (!selectedEmployees.value.includes(el._id)) {
        selectedEmployees.value.push(el._id);
      }
    });
  } else {
    selectedEmployees.value = [];
  }

  emit("selected", selectedEmployees.value);
};

const selectEmployee = async (value) => {
  allChecked.value = false;

  if (selectedEmployees.value.includes(value)) {
    selectedEmployees.value = [
      ...selectedEmployees.value.filter((id) => value !== id),
    ];
  } else {
    selectedEmployees.value.push(value);
  }

  emit("selected", selectedEmployees.value);
};

const viewEmployee = (emp) => {
  //check if user is viewing from archived table
  console.log(emp);
  if (props.from.toLocaleLowerCase() === "archived") {
    store.$patch({
      viewFromArchived: true,
    });
  } else {
    store.$patch({
      viewFromArchived: false,
    });
  }

  route.path.includes("view")
    ? router.push({
        name: "PersonalInfo",
        params: { id: emp._id },
      })
    : router.push({
        name: "EditPersonalInfo",
        params: { id: emp._id },
      });
};

const showAll = (arg) => {
  if (showAllEmployee.value === arg) {
    showAllEmployee.value = "";
  } else {
    showAllEmployee.value = arg;
  }
};

function displayText(arg) {
  return arg ? arg : "N/A";
}

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
