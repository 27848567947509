<template>
  <main>
    <div
      @click.self="clickOutSide"
      style="z-index: 300; position: fixed; top: 0; left: 0; bottom: 0; right: 0; background: rgba(0, 19, 9, 0.597)"
      :class="[center ? 'flex items-center justify-center' : '']"
      class="inset-0 -z-5 w-full overflow-y-auto py-16"
    >
      <div v-if="lite" class="absolute right-0 top-0 mt-3 mx-3 cursor-pointer" @click="close">
        <i class="fas fa-times text-2xl hover:text-white"></i>
      </div>

      <div
        :class="[
          `rounded-${rounded}`,
          lite ? 'bg-none' : 'bg-white',

          size
            ? `md:max-w-${size}`
            : terms
            ? 'max-w-5xl'
            : compliance
            ? 'md:max-w-2xl'
            : payroll
            ? 'md:max-w-3xl'
            : apiDashboard
            ? 'md:max-w-lg'
            : 'md:max-w-lg',
        ]"
        :style="{
          borderRadius: pixelRounded,
        }"
        class="z-20 md:w-full w-11/12 mx-auto pb-4 shadow-xs"
      >
        <div v-if="!lite" class="rounded-lg bg-newAsh p-1 float-right mt-5 mr-4">
          <svg
            v-if="showCloseButton"
            class="cursor-pointer"
            @click="close"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#F2F1F1" />
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="#292A29"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-if="isHeader" class="w-full flex justify-center items-center px-6 pb-2 text-2xl">
          <slot name="header" />
        </div>
        <hr v-if="isBorder && isHeader && !lite" class="mt-2 mb-4 border border-dividerColor text-dividerColor" />

        <main>
          <div class="px-0">
            <slot />
          </div>

          <!-- <slot /> -->
        </main>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onUnmounted } from "@vue/runtime-core";

const props = defineProps({
  isHeader: {
    type: Boolean,
    default: true,
  },
  isBorder: {
    type: Boolean,
    default: true,
  },
  apiDashboard: {
    type: Boolean,
    default: false,
  },
  compliance: {
    type: Boolean,
    default: false,
  },
  payroll: {
    type: Boolean,
    default: false,
  },

  center: {
    type: Boolean,
    default: false,
  },
  lite: {
    type: Boolean,
    default: false,
  },
  terms: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: String,
    default: "3xl",
  },
  color: {
    type: String,
    default: "bg-background-100",
  },
  size: {
    type: String,
    default: "",
  },
  persistence: {
    type: Boolean,
    default: true,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "",
  },
  pixelRounded: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update", "close", "back"]);

function close() {
  emit("close");
}

function back() {
  emit("back");
}

const clickOutSide = () => {
  if (!props.persistence) {
    close();
  }
};
</script>

<style></style>
