<template>
  <div>
    <div
      class="py-2 px-4 mt-5 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
    >
      <div class="flex items-center gap-2">
        <div
          class="h-12 w-12 flex items-center justify-center bg-white rounded-full flex-shrink-0"
        >
          <img :src="imgIcon" alt="" />
        </div>
        <div class="flex flex-col leading-4">
          {{ url && !showUploadComponent ? "Image File" : label }}
        </div>
      </div>

      <span
        :class="[loading ? 'pointer-events-none opacity-50' : '']"
        class="bg-white rounded-full"
      >
        <div
          v-if="url && !showUploadComponent"
          @click="showUploadComponent = true"
          class="p-2 flex-shrink-0 h-8 w-8 flex items-center justify-center cursor-pointer"
        >
          <i class="pi pi-times"></i>
        </div>

        <UploadComponent
          v-else
          :label="'Upload'"
          :loop="true"
          @fileUrl="emit('fileUrl', $event)"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";
import imgIcon from "@/assets/icons/img-icon.svg";

import { ref } from "vue";

defineProps({
  label: String,
  url: {
    type: String,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["fileUrl"]);

const showUploadComponent = ref(false);
</script>

<style lang="scss" scoped></style>
