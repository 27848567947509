<template>
  <div class="border border-dark-300 flex flex-col p-4 rounded-xl mt-4">
    <div class="flex justify-between gap-2">
      <p>{{ label }}</p>
      <div class="cursor-pointer" @click="addTileModal = true">
        <img src="@/assets/icons/plus-circle.svg" alt="" />
      </div>
    </div>
    <hr v-if="tiles.length" class="border border-outlineGray my-2" />
    <div class="flex flex-wrap gap-2">
      <div
        v-for="(tile, tileIndex) in tiles"
        :key="tileIndex"
        class="flex gap-1 items-center mb-2 tile px-2"
      >
        <div>
          {{ tile }}
        </div>
        <div @click="deleteTile(tile)" class="cursor-pointer">
          <img src="@/assets/icons/x-mark.svg" alt="" />
        </div>
      </div>
    </div>
    <easiModal
      v-if="addTileModal"
      @close="addTileModal = false"
      :isHeader="false"
      :isBorder="false"
      :lite="false"
      :persistence="true"
      :showCloseButton="false"
      :compliance="true"
    >
      <div class="p-4">
        <p v-if="!noDesc" class="font-medium text-lg md:text-xl pb-4">
          {{
            type === "service"
              ? "Select all the services you need"
              : " Select residential state of your employees:"
          }}
        </p>
        <div class="flex gap-5 flex-wrap">
          <div
            class="border border-outlineGray rounded-lg flex gap-2 p-3 cursor-pointer"
            :class="toggleAll ? 'bg-gray-100' : ''"
            @click="toggleAll = !toggleAll"
          >
            <img v-if="toggleAll" src="@/assets/icons/check.svg" alt="" />
            <div>Select All</div>
          </div>
          <div
            v-for="(state, stateIndex) in allStates"
            :key="stateIndex"
            class="border border-outlineGray rounded-lg flex gap-2 p-3 cursor-pointer"
            :class="selectedStates.includes(state) ? 'bg-gray-100' : ''"
            :style="{ height: 'fit-content' }"
            @click="selectState(state)"
          >
            <img
              v-if="selectedStates.includes(state)"
              src="@/assets/icons/check.svg"
              alt=""
            />
            <div>{{ state }}</div>
          </div>
          <div
            v-if="type === 'service'"
            :class="toggleTax ? 'border border-outlineGray rounded-lg p-3' : ''"
          >
            <div
              class="border border-outlineGray rounded-lg flex gap-2 p-3 cursor-pointer"
              :class="toggleTax ? 'bg-gray-100' : ''"
              :style="{ width: 'fit-content' }"
              @click="toggleTax = !toggleTax"
            >
              <img v-if="toggleTax" src="@/assets/icons/check.svg" alt="" />
              <div>State Tax Registration</div>
            </div>
            <div v-if="toggleTax" class="flex gap-2 mt-2">
              <div
                class="border border-outlineGray rounded-lg flex gap-2 p-3 cursor-pointer"
                :class="strCompany ? 'bg-gray-100' : ''"
                @click="strCompany = !strCompany"
              >
                <img v-if="strCompany" src="@/assets/icons/check.svg" alt="" />
                <div>STR/ For Company</div>
              </div>
              <div
                class="border border-outlineGray rounded-lg flex gap-2 p-3 cursor-pointer"
                :class="strStaff ? 'bg-gray-100' : ''"
                @click="strStaff = !strStaff"
              >
                <img v-if="strStaff" src="@/assets/icons/check.svg" alt="" />
                <div>STR/ For Staff</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex gap-5 justify-center md:justify-end">
          <div class="w-32 md:w-auto">
            <easiButton
              color="primary"
              variant="outlined"
              class="rounded-full bg-white w-full md:w-52"
              @click="closeModal"
            >
              Close
            </easiButton>
          </div>
          <div class="w-32 md:w-auto">
            <easiButton
              color="primary"
              class="rounded-full bg-white w-full md:w-52"
              @click="addTile"
            >
              Done
            </easiButton>
          </div>
        </div>
      </div>
    </easiModal>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from "vue";

export default {
  name: "AddResidentialState",
  emits: ["done"],
  props: {
    noDesc: {
      type: Boolean,
      default: false,
    },
    propValue: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const allStates = ref(props.propValue);
    const label = ref(props.label);
    const type = ref(props.type);
    const instance = getCurrentInstance();
    const tiles = ref([]);
    const addTileModal = ref(false);
    const toggleAll = ref(false);
    const toggleTax = ref(false);
    const strCompany = ref(false);
    const strStaff = ref(false);
    const selectedStates = ref([]);
    const selectState = (state) => {
      if (selectedStates.value.includes(state)) {
        // remove the state
        selectedStates.value = selectedStates.value.filter(
          (item) => item !== state
        );
        tiles.value = selectedStates.value.filter((item) => item !== state);
      } else {
        selectedStates.value.push(state);
      }
      if (props.type === "service") {
        if (strCompany.value) {
          tiles.value.push("STR/ For Company");
        } else {
          tiles.value = tiles.value.filter(
            (item) => item !== "STR/ For Company"
          );
        }
        if (strStaff.value) {
          tiles.value.push("STR/ For Staff");
        } else {
          tiles.value = tiles.value.filter((item) => item !== "STR/ For Staff");
        }
      }
    };
    const deleteTile = (tile) => {
      tiles.value = tiles.value.filter((item) => item !== tile);
      selectedStates.value = selectedStates.value.filter(
        (item) => item !== tile
      );
      if (props.type === "service") {
        if (tile === "STR/ For Company") {
          strCompany.value = false;
        }
        if (tile === "STR/ For Staff") {
          strStaff.value = false;
        }
      }
    };
    const closeModal = () => {
      // selectedStates.value = [];
      // toggleAll.value = false;
      //   toggleTax.value = false;
      //   strCompany.value = false;
      //   strStaff.value = false;
      addTileModal.value = false;
    };
    const addTile = () => {
      if (selectedStates.value.length > 0) {
        selectedStates.value.forEach((state) => {
          if (!tiles.value.includes(state)) {
            tiles.value.push(state);
          }
        });
        if (props.type === "service") {
          if (strCompany.value) {
            if (!tiles.value.includes("STR/ For Company")) {
              tiles.value.push("STR/ For Company");
            }
          } else {
            tiles.value = tiles.value.filter(
              (item) => item !== "STR/ For Company"
            );
          }
          if (strStaff.value) {
            if (!tiles.value.includes("STR/ For Staff")) {
              tiles.value.push("STR/ For Staff");
            }
          } else {
            tiles.value = tiles.value.filter(
              (item) => item !== "STR/ For Staff"
            );
          }
        }
      } else {
        selectedStates.value = [];
        tiles.value = [];
      }

      instance.emit("done", tiles.value);

      addTileModal.value = false;
    };
    watch(tiles, () => {
      instance.emit("done", tiles.value);
    });
    watch(toggleAll, (newValue, oldValue) => {
      if (newValue) {
        allStates.value.forEach((state) => {
          if (!selectedStates.value.includes(state)) {
            selectedStates.value.push(state);
          }
        });
        if (props.type === "service") {
          toggleTax.value = true;
          strCompany.value = true;
          strStaff.value = true;
          // if (!tiles.value.includes("STR/ For Company")) {
          //   tiles.value.push("STR/ For Company");
          // }
          // if (!tiles.value.includes("STR/ For Staff")) {
          //   tiles.value.push("STR/ For Staff");
          // }
        }
      } else {
        selectedStates.value = [];
        strCompany.value = false;
        strStaff.value = false;
        toggleTax.value = false;
      }
    });
    watch(toggleTax, (newValue) => {
      if (!newValue) {
        strCompany.value = false;
        strStaff.value = false;
        // tiles.value = tiles.value.filter((item) => item !== "STR/ For Company");
        // tiles.value = tiles.value.filter((item) => item !== "STR/ For Staff");
      }
    });

    return {
      tiles,
      deleteTile,
      addTile,
      addTileModal,
      toggleAll,
      toggleTax,
      strStaff,
      strCompany,
      allStates,
      selectState,
      selectedStates,
      closeModal,
      label,
      type,
    };
  },

  methods: {},

  computed: {
    style() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E0E7FF; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: left;  text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: rgba(240, 247, 235, 1);;margin-bottom: 10px;color: #515251; border: 1px solid #E7E8E7; font-size: 12px;";
    },
  },
};
</script>

<style scoped>
/* Add any custom styling here */
.tile {
  border-radius: 6px;
  border: 1px solid var(--new-styles-grays-outline, #e7e8e7);
  background: var(--new-styles-grays-bg, #f2f1f1);

  /* Elevations/Light Mode/Light 1 */
  box-shadow: 0px 4px 8px 0px rgba(170, 170, 170, 0.08);
}
</style>
