<template>
  <div class="cursor-pointer">
    <div @click="show = true" ref="root">
      <slot />
    </div>

    <div
      v-on-click-outside="clickOutside"
      v-if="show"
      style="box-shadow: 0px 20px 48px 0px #aaaaaa4a; z-index: 9999"
      :style="{
        top: `${topStyle}px`,
        left: `${leftStyle}px`,
        width: `${width}px`,
      }"
      v-bind="$attrs"
      class="fixed max-h-36 no-scrollbar overflow-y-auto rounded-2xl flex flex-col bg-white overflow-hidden"
    >
      <div
        v-for="item in options"
        :key="item.title"
        @click="handleClick(item)"
        class="px-3 py-2.5 text-sm hover:bg-highlight border-b border-newAsh last:border-0"
      >
        <span v-if="!$slots.items">{{ item.title }}</span>
        <slot v-else name="items" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useElementBounding } from "@vueuse/core";

import { vOnClickOutside } from "@vueuse/components";
import { computed, ref } from "vue";

const props = defineProps({
  data: [Object, Array, String, Number],
  options: {
    type: Array,
    default: () => [],
  },
  width: {
    type: Number,
    default: 144,
  },

  modify: {
    type: Object,
    default: () => ({
      width: null,
      top: null,
      left: null,
    }),
  },
});

const show = ref(false);

const root = ref(null);
const { top, left } = useElementBounding(root);

const topStyle = computed(() => {
  const { modify } = props;
  return !modify.top ? top.value : top.value + modify.top;
});

const leftStyle = computed(() => {
  const { modify } = props;
  const l = !modify.left ? left.value : left.value + modify.left;
  return l - props.width;
});

function clickOutside() {
  show.value = false;
}

function handleClick(arg) {
  arg.action(props.data);
  show.value = false;
}
</script>

<style></style>
