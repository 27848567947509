<template>
  <main>
    <div class="relative mt-1" v-bind="$attrs">
      <span
        v-if="label"
        class="absolute left-5 py-0 -top-2 px-5 bg-white text-sm text-newGray capitalize"
      >
        {{ label }}
      </span>
      <div
        class="bg-white font-medium text-sm leading-2 focus:outline-none border block appearance-none focus:border-boxBorder"
        id="grid-state"
        @click.self="isSelect = !isSelect"
        :class="[
          `${value ? '  text-dark-700' : '  text-dark-500'} ${
            isSelect
              ? 'border-boxBorder'
              : error && error.length
              ? 'border-error py-3 px-4'
              : 'border-dark-200 py-3 px-4'
          }`,
          shrink ? 'w-12 md:w-full py-1 rounded-2xl' : 'w-full rounded-2xl',
        ]"
      >
        <form v-if="isSelect" action="" class="relative w-11/12 z-10 top-0">
          <easiTextInput
            v-model="searchQuery"
            @keyup="searchFunction"
            autofocus
          ></easiTextInput>
        </form>
        <span
          class="relative w-11/12 capitalize"
          @click.self="isSelect = true"
          v-else
        >
          {{
            optionArray == ""
              ? "Select"
              : formatValue(optionArray)
                  .toString()
                  .replace("[", "")
                  .replace("]", "")
                  .replace(/,(?=[^\s])/g, ", ")
          }}</span
        >

        <ul
          v-show="isSelect"
          class="mt-5 z-10 w-full absolute top-8 right-0 py-1 shadow-md bg-white shadow-2xl h-40 overflow-y-scroll"
        >
          <div
            @click="addAll()"
            class="hover:bg-newLightGreen w-full cursor-pointer flex items-center mb-2 gap-2"
          >
            <input
              :checked="allChecked || allSelected"
              class="mx-4 my-2"
              type="checkbox"
              name=""
              id=""
            />
            <span class="uppercase font-medium text-xs">Select All</span>
          </div>
          <li
            v-for="option in optionData"
            :key="option.value"
            class="cursor-pointer text-sm text-dark capitalize z-20"
            :class="optionArray.includes(option.value) ? ' bg-none ' : ''"
            @click="update(option.value)"
          >
            <div class="flex items-center gap-2">
              <input
                :checked="optionArray.includes(option.value)"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
                :value="option"
              />
              <span class="cursor-pointer"> {{ option.label }}</span>
            </div>
            <hr class="my-2 text-dividerColor" />
          </li>
        </ul>
      </div>
      <div
        v-if="!isSelect"
        @click="isSelect = !isSelect"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-500"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>

    <div class="max-w-100 -mt-3 p-2">
      <div class="flex flex-wrap gap-2 mt-1 mb-0 justify-start items-center">
        <div
          v-for="opt in optionArray"
          :key="opt"
          class="flex items-center flex-nowrap justify-around rounded-full px-2 py-1 text-center bg-newLimeGreen border-newGray border text-xs w-32"
        >
          <img
            class="cursor-pointer"
            v-show="selected !== opt"
            @click="update(opt)"
            src="@/assets/icons/newRemove.svg"
            alt=""
          />
          <img
            class="cursor-pointer"
            v-show="selected === opt"
            @click="update(opt)"
            src="@/assets/icons/removeLight.svg"
            alt=""
          />
          <!-- <i
            @click="update(opt)"
            class="fas fa-minus-circle"
            :class="selected === opt ? 'text-white' : 'text-secondary'"
          ></i> -->
          <span class="cursor-pointer capitalize" @click="toggleView(opt)">
            {{ getLabelFromValue(opt) }}</span
          >
        </div>
      </div>
      <div></div>
    </div>

    <div v-if="error" class="text-xs font-medium text-error -mt-2 mb-2">
      {{ error }}
    </div>
    <div
      @click="isSelect = false"
      :class="!isSelect ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
import NigerianStates from "@/utils/states";

import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const { truncateAmount } = helperFunctions;

const store = useDataStore();
const route = useRoute();

const props = defineProps({
  value: {
    default: [],
  },
  grossAmount: {
    type: [Number, String],
    default: 0,
  },
  usePercent: {
    type: Boolean,
    default: true,
  },
  clearAll: {
    type: Boolean,
    default: false,
  },

  error: {
    type: [String, Boolean],
    default: false,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  validate: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
});
let optionArray = ref([]);

const emit = defineEmits(["update", "args"]);

const optionData = ref([]);
optionData.value = props.options;
const selected = ref("");
const searchQuery = ref("");

const isSelect = ref(false);

const getValidate = computed(() => props.validate);
const checkClear = computed(() => props.checkObj);

const allChecked = ref(false);
const allSelected = ref(false);

const clearArr = computed(() => props.clearAll);

watch(
  clearArr,
  (newVal, oldVal) => {
    console.log(newVal, oldVal, "PROPS.VALUE");
    allChecked.value = false;
    allSelected.value = false;
    optionArray.value = [];
  },
  { deep: true }
);

function getLabelFromValue(value) {
  if (value) {
    const label = props.options.filter((item) => {
      return item.value === value;
    });

    return label[0].label;
  }
}
function addAll() {
  allChecked.value = !allChecked.value;
  allSelected.value = !allSelected.value;
  optionArray.value.splice(0);
  if (allChecked.value) {
    props.options.forEach((el) => {
      optionArray.value.push(el.value);
    });
  }
  optionArray.value = [...new Set(optionArray.value)];
  // optionData.value = optionArray.value;
  emit("update", optionArray.value);
}

watch(
  isSelect,
  (val) => {
    console.log(val);
    if (val === true) {
      optionData.value = props.options;
    } else {
      optionData.value = props.options;
    }
  },
  { deep: true }
);
function searchFunction(e) {
  const name = e.target.value;
  let data;
  if (name.length) {
    if (e.key !== "Backspace") {
      data = props.options.filter((x) => {
        return x.label.toLowerCase().indexOf(name.toLowerCase()) > -1
          ? x.label
          : "";
      });
    } else {
      console.log(searchQuery.value);
      data = props.options.filter((x) => {
        return x.label.toLowerCase().indexOf(searchQuery.value.toLowerCase()) >
          -1
          ? x.label
          : "";
      });
    }

    if (data.length) {
      optionData.value = data;
      return data;
    }
  } else {
    optionData.value = props.options;
  }
}
function formatValue(val) {
  const newVal = val.map((a) => {
    return getLabelFromValue(a);
  });
  return newVal;
}
function toggleView(option) {
  if (option === selected.value) {
    selected.value = "";
    return true;
  }
  selected.value = option;
}
function update(param) {
  console.log("ACTIVATED");
  if (param) {
    if (optionArray.value.includes(param)) {
      // const newArr = [...optionArray.value.filter((opt) => param !== opt)];
      let index = optionArray.value.indexOf(param);
      if (index > -1) {
        optionArray.value.splice(index, 1);
      }
      console.log(param, "Removed From", optionArray);
    } else {
      optionArray.value.push(param);
      nextTick();
    }

    emit("update", optionArray.value);
  }
}
onMounted(() => {
  // console.log(props.value);
  // queryEmployees();
  if (props.value.length > 0) {
    optionArray.value = props.value;
  }
  if (
    optionArray.value.length === props.options.length &&
    props.options.length > 0
  ) {
    allSelected.value = true;
    allChecked.value = true;
  }
  // console.log(props.value, "INITIAl");
});
function updateArgs() {
  console.log(staffLevel);
  //   emit("args", staffLevel);
}
</script>

<style></style>
