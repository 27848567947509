<template>
  <main>
    <div
      @click.self="close"
      style="
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 19, 9, 0.36);
      "
      class="inset-0 w-full flex items-center justify-center overflow-y-auto py-16"
    >
      <div
        class="bg-white w-full max-w-md mx-auto rounded-3xl overflow-y-auto shadow-xs"
      >
        <div
          v-if="showDefaultTop"
          class="py-8 p-4 text-center flex flex-col gap-2 items-center"
        >
          <div
            v-if="!noIcon && !useGif"
            class="p-4 bg-highlight rounded-full my-4 flex items-center justify-center"
          >
            <img :src="icon || logo" alt="" />
          </div>

          <div v-if="useGif && !noIcon" class="w-28">
            <img :src="gif" alt="" />
          </div>
          <h3 class="text-primary font-extrabold text-2xl" v-html="title"></h3>
          <p class="text-dark-800 text-sm">{{ caption }}</p>
        </div>
        <div class="pb-4">
          <slot />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import logo from "@/assets/icons/successicon.svg";
import gif from "@/assets/icons/gif.gif";
import { computed } from "vue";

const props = defineProps({
  showDefaultTop: {
    type: Boolean,
    default: true,
  },
  useGif: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "default",
  },
  title: {
    type: String,
    default: "Success",
  },
  noIcon: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: [Boolean, String],
    default: false,
  },
  caption: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update"]);

function close() {
  emit("close");
  //   window.location.reload();
}
</script>

<style></style>
