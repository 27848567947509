<template>
  <div v-if="!circle" class="linear-activity bg-primary-pressed">
    <div class="indeterminate_loader"></div>
  </div>
  <div v-else class="text-center">
    <div class="lds-ring-custom">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 4px;
}

.indeterminate_loader {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate_loader:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #0f5144;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate_loader:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #0ba485;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.lds-ring-custom {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring-custom div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 4px 0;
  border: 2px solid #11453b;
  border-radius: 50%;
  animation: lds-ring-custom 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #11453b transparent transparent transparent;
}
.lds-ring-custom div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-custom div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-custom div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-custom {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
