<template>
  <main>
    <div class="relative">
      <div
        @click="toggleFilter = true"
        class="inline border border-outlineGray rounded-2xl md:py-3 py-6 md:px-4 px-6 cursor-pointer flex items-center gap-2 bg-white w-fit"
        :class="bonus ? 'rounded-l-none' : ''"
      >
        <img
          v-show="!bonus"
          class="w-6 md:w-4"
          src="@/assets/icons/filter.svg"
          alt=""
        />
        <span class="text-dark-600 text-sm hidden md:flex items-center gap-3"
          >{{ activeFilter || "Filter" }}
          <i v-show="bonus" class="fas fa-angle-right"></i
        ></span>
      </div>
      <div
        v-if="toggleFilter"
        :class="dropdownStyle"
        class="absolute h-auto w-52 right-0 shadow-lg bg-white flex flex-col text-sm py-2"
        style="z-index: 2"
      >
        <div
          v-for="option in filterOptions"
          :key="option.name"
          @click="filterFunc(option)"
        >
          <div
            class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-mint"
          >
            <span>
              <img
                v-if="option.active"
                class="w-4"
                src="../../assets/icons/check3-active.svg"
                alt=""
              />
              <img
                v-else
                class="w-4"
                src="../../assets/icons/check3-null.svg"
                alt=""
              />
            </span>
            <span>{{ option.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="toggleFilter = false"
      :class="!toggleFilter ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  data: () => ({
    dropdownStyle: "top-16",
  }),
  props: {
    bonus: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    let currentYear = new Date().getFullYear();

    const activeFilter = ref("");
    const toggleFilter = ref(false);

    const filterOptions = reactive([
      {
        name: "Date",
        value: "date",
        active: true,
      },
      {
        name: "Employee Management",
        value: "management",
        active: false,
      },
      {
        name: "Payroll",
        value: "payroll",
        active: false,
      },
      {
        name: "Wallet",
        value: "wallet",
        active: false,
      },
      {
        name: "Bonus",
        value: "bonus",
        active: false,
      },
      {
        name: "Deduction",
        value: "deduction",
        active: false,
      },
      // {
      //   name: "Team",
      //   value: "team",
      //   active: false,
      // },
    ]);

    // Functions
    const filterFunc = (arg, remove = false) => {
      toggleActive();

      function toggleActive() {
        //Uncheck all
        filterOptions.forEach((el) => {
          el.active = false;
        });

        //Check the selected
        const index = filterOptions.map((el) => el.value).indexOf(arg.value);
        filterOptions[index].active = !filterOptions[index].active;

        return filterOptions[index].active;
      }
      activeFilter.value = arg.name;

      emit("update", { filter: arg.name, active: remove ? [] : [arg.name] });
    };

    const removeFilter = (arg) => {
      filterFunc(filterOptions[0], true);
      filterOptions.forEach((el) => {
        el.active = false;
      });
      // activeFilter.value = "";
    };

    return {
      toggleFilter,
      activeFilter,
      filterFunc,
      filterOptions,
      removeFilter,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.checkDropdownPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkDropdownPosition);
  },

  methods: {
    checkDropdownPosition() {
      let dropdown = this.$el;
      let windowHeight = window.innerHeight;
      let dropdownRect = dropdown.getBoundingClientRect();
      let dropdownHeight = dropdownRect.height;
      let dropdownTop = dropdownRect.top;
      let dropdownBottom = dropdownRect.bottom;

      // console.log("TOP", dropdownTop);
      // console.log("BOTTOM", dropdownBottom);
      // console.log("Window Hieght", windowHeight);

      if (dropdownTop < 0) {
        this.dropdownStyle = "top-16";
      } else if (dropdownBottom > 420) {
        this.dropdownStyle = "bottom-16";
      } else {
        this.dropdownStyle = "top-16";
      }
    },
  },
};
</script>

<style></style>
