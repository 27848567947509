<template>
  <div class="px-4 sm:px-28 pt-8">
    <p class="font-bold text-black">
      <span class="text-2xl"> API Keys / </span>

      <span> Test Keys </span>
    </p>

    <div class="border border-outlineGray p-8 mt-10">
      <div class="md:w-8/12 flex flex-col gap-4">
        <ApiInput
          v-for="key in data"
          :key="key.label"
          :label="key.label"
          :value="key.value"
        />
      </div>
    </div>
  </div>
  <easiRoundLoader v-if="loading" />
</template>

<script setup>
import ApiInput from "@/components/ApiDashboard/ApiInput.vue";

import { useDataStore } from "@/stores/data.js";
import { ref, computed, onMounted } from "vue";

const store = useDataStore();
const { query } = store;

const loading = ref(false);

const data = computed(() => {
  const { privateKey, publicKey } = store.apiKeys || {};
  return [
    {
      label: "Public Keys",

      value: publicKey,
    },
    {
      label: "Security Keys",

      value: privateKey,
    },
  ];
});

onMounted(() => {
  queryTestKeys();
});

const queryTestKeys = async () => {
  try {
    loading.value = true;

    const res = await query({
      endpoint: "ViewCompanyTestKeys",
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiKeys: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
