<template>
  <main class="lg:grid grid-cols-2 h-screen overflow-auto">
    <div
      class="left overflow-hidden w-full hidden md:flex flex-col pb-10 justify-end items-center text-white"
    >
      <div class="relative mb-16">
        <div
          class="absolute top-5 w-full lg:top-12 left-1/2 transform -translate-x-1/2 text-center"
        >
          <p class="text-primary text-4xl font-medium font-made-mellow">
            More than Payroll
          </p>
          <p class="text-grey text-lg font-bold w-full">
            Grow. Thrive. Prosper. With Eazipay
          </p>
        </div>
        <img
          class="cursor-pointer ml-2"
          src="../../assets/img/backgroundBanner.png"
        />
      </div>
   
      <div class="carousel-container" ref="carousel">
        <div
          class="carousel-track flex gap-10"
          :style="{ transform: `translateX(${translateValue}px)` }"
        >
          <div
            v-for="(slide, index) in slides"
            :key="index"
            ref="slideRefs"
            class="bg-primary-new rounded-lg rounded-tr-3xl px-4 py-6"
            :class="currentIndex === index ? 'block' : 'invisible'"
            :style="`width: ${slideWidth}px;`"
          >
            <div class="slide-content">
              <div class="border-l-4 pl-4 border-secondary">
                <p class="text-mint text-xl font-bold">Did you know?</p>
                <p class="text-newAsh">
                  {{ slide.content }}
                </p>
              </div>
            </div>
            <div class="mt-6 justify-center flex gap-1">
              <div
                v-for="(_, subindex) in slides"
                :key="subindex"
                class="h-2 rounded-full"
                :class="
                  index === subindex
                    ? 'bg-secondary w-5'
                    : 'bg-newLightGray w-2'
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <slot></slot>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from "vue";
const carousel = ref(null);
const translateValue = ref(650);
const currentIndex = ref(0);
const slides = ref([
  {
    header: "Did you know?",
    content:
      "You can register and remit a wide range of compliances (PAYE, Pension, HMO, NSITF, ITF, NHF, WHT) across the 36 states in Nigeria on Eazipay?",
  },
  {
    header: "Did you know?",
    content:
      "Eazipay provides you with payroll cover and business loans of up to 10 Million Naira",
  },
  {
    header: "Did you know?",
    content:
      "Your employee data and company information is 100% safe and secure on Eazipay. No leaks, no breaches.",
  },
]);

const slideRefs = ref([]); // Array to store slide refs

// Define default slide width
let slideWidth = 600;

onMounted(() => {



  // Start auto-scrolling
  startAutoScroll();
});

const startAutoScroll = () => {
  setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % slides.value.length;
    if (currentIndex.value === 0) {
      translateValue.value = 650;
    } else if (currentIndex.value === 1) {
      translateValue.value = 10;
    } else if (currentIndex.value === 2) {
      translateValue.value = -630;
    }
  }, 2500); // Auto-scroll every 1 second
};
</script>

<style scoped>
.left {
  background: url("../../assets/img/newBackground.svg") no-repeat center center;
  background-position: center center;
  background-size: cover;
  /* height: 100vh; */
}
.backgroundBanner {
  background: url("../../assets/img/backgroundBanner.svg") no-repeat center
    center;
  background-position: center center;
  background-size: cover;
  /* height: 100vh; */
}

.right {
  /* height: 100vh; */
  overflow: auto;
  background: white;
}
@media (min-width: 1024px) {
  .right {
    background: url("../../assets/img/signupBackground.png") no-repeat center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.imgSlider {
  height: 446px;
  width: 485px;
}
.contentWidth {
  max-width: 450px;
}

.animation-slides {
  animation: slide 2s linear infinite alternate;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100%));
  }
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease;
}
</style>
