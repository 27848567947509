<template>
  <div class="p-fluid relative" style="max-height: calc(100vh - 10px)">
    <div class="card md:mx-4 h-full" :class="editInProgress ? 'z-30 ' : ''">
      <DataTable
        :value="data"
        :resizableColumns="true"
        columnResizeMode="expand"
        showGridlines
        :paginator="false"
        :rows="20"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        :scrollable="true"
        scrollHeight="600px"
        :loading="!data.length"
        scrollDirection="both"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        class="editable-cells-table p-datatable-sm"
        responsiveLayout="scroll"
        sortMode="multiple"
      >
        <!-- <template #header>
          <div
            class="flex px-2 py-3 bg-indigo-100 text-left font-bold capitalize text-sm tracking-wide text-header logo border-2 border-t-0 rounded-lg text-center"
          >
            <div v-for="col of header" :key="col.header">
              {{ label(col.header) }}
            </div>
          </div>
        </template> -->
        <Column
          v-for="col of header"
          :field="col.field"
          :header="label(col.header)"
          :key="col.field"
          class="text-dark-900"
          style="border: 1px solid #b5bde1"
          :frozen="freeze(col)"
          :style="[
            highlightField(col.field),
            !lite ? 'width: 130px;' : 'width: 130px;',
            ['companyId'].includes(col.field) ? 'display: none' : '',
          ]"
          :sortable="false"
          :headerStyle="[style, reason]"
        >
          <template
            v-if="useSelect.includes(col.field)"
            #editor="{ data, field }"
          >
            <Dropdown
              v-model="data[field]"
              :options="mapData"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Bank"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option.label }}</span>
              </template>
            </Dropdown>
          </template>

          <template
            v-else-if="!useSelect.includes(col.field) && readonly === false"
            #editor="{ data, field }"
          >
            <InputText
              @focus="editInProgress = true"
              v-model="data[field]"
              autofocus
            />
          </template>
          <template v-if="amountValues.includes(col.field)" #body="slotProps">
            <div class="flex items-center gap-2"></div>
            <div>
              {{ formatAmount(slotProps.data[col.field]) }}
            </div>
          </template>

          <template v-else #body="slotProps">
            <div
              :class="[
                networkError(slotProps.data, col.field),
                error(slotProps.data, col.field),
                bankError(slotProps.data, col.field),
              ]"
            >
              {{
                col.field !== "errors"
                  ? slotProps.data[col.field]
                  : slotProps.data[col.field].join(",")
              }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div
      @click="editInProgress = false"
      :class="!editInProgress ? 'pointer-events-none' : ''"
      class="bg-transparent z-50 fixed inset-0"
    ></div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { helperFunctions } from "@/composable/helperFunctions";

export default {
  setup(props) {
    const { formatAmount } = helperFunctions;
    return {
      formatAmount,
    };
  },
  emits: ["data"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    lite: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: [],
    },
    header: {
      type: Array,
      default: [],
    },
    mapData: {
      type: Array,
      default: [],
    },
    frozenFields: {
      type: Array,
      default: [],
    },
    highlight: {
      type: Array,
      default: [],
    },
    useSelect: {
      type: Array,
      default: [],
    },
    mandatory: {
      type: Array,
      default: [],
    },
    amountValues: {
      type: Array,
      default: [],
    },
    errorArray: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      editInProgress: false,

      editingRows: [],
      columns: null,
      products1: null,
      products2: null,
      products3: null,
      statuses: [
        { label: "In Stock", value: "INSTOCK" },
        { label: "Low Stock", value: "LOWSTOCK" },
        { label: "Out of Stock", value: "OUTOFSTOCK" },
      ],
    };
  },

  computed: {
    style() {
      return " padding-top: 0.5rem; padding-bottom: 0.5rem;  line-height: 16px; font-weight: 700; letter-spacing: 0.02em; text-align: left;  text-transform: capitalize; border-width: 2px;color: #231F1F; background: #F0F7EB;border: 1px solid #E7E8E7; font-size: 14px;";
    },

    label() {
      return (str) =>
        str
          ? str
              .split(".")
              .pop()
              .replace(/([A-Z])/g, " $1")
              .toUpperCase()
          : "";
    },
    freeze() {
      return (field) => {
        if (this.frozenFields.includes(field.field)) {
          // return true; unfreeze
          return false;
        }
        return false;
      };
    },
    reason() {
      return (field) => {
        if (field === "reason") {
          return "background: #E4572E; color: #FFFFFF";
        }
      };
    },
    highlightField() {
      return (field) => {
        if (this.highlight.includes(field)) {
          return "background: #F0F7EB; box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.2);";
        }
        return "background: #FCFCFC;";
      };
    },
    // mandatoryField() {
    //   return (field) => {
    //     if (this.mandatory.includes(field)) {
    //       return "border: 1px solid #FFF0EC";
    //     }
    //   };
    // },
  },

  methods: {
    bankError(data, field) {
      if (data.errors) {
        // console.log(data.errors, "RE");

        const bankError = data.errors.join(",").toLowerCase();
        const bool =
          bankError.includes("account number") ||
          bankError.includes("bank") ||
          bankError.includes("account");
        if (
          field === "bankName" ||
          field === "bankCode" ||
          field === "accountNumber"
        ) {
          return [
            {
              "border border-error-table w-full h-full text-error-table p-2":
                bool === true,
            },
          ];
        }
      }
    },
    error(data, field) {
      if (data.errors) {
        const errors = data.errors.join(",");

        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              errors.toLowerCase().includes(field.toLowerCase()),
          },
        ];
      }
    },
    networkError(data, field) {
      if (
        this.errorArray.includes(data.stafflevelid) &&
        this.errorArray.length
      ) {
        // const errors = data.errors.join(",");
        // console.log(this.errorArray);

        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              this.errorArray.includes(data.stafflevelid),
          },
        ];
      } else if (Object.keys(data).includes("reason") && data.reason.length) {
        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              data.reason
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(field.toLowerCase()),
          },
        ];
      }
    },
    madatoryClass(data, field) {
      // console.log("DA", data);
      return [
        {
          "border border-secondary w-full h-full text-secondary":
            data[field].length === 0,
        },
      ];
    },
    onCellEditComplete(event) {
      this.editInProgress = false;

      let { data, newValue, field } = event;
      // console.log(newValue, "NEWS");

      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue && newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
      // console.log(this.data, "this.data");
      // console.log(this.header, "this.header");
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },
  },
};
</script>

<style scoped></style>
