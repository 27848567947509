<template>
  <div>
    <div class="hidden md:flex justify-between px-2 md:px-6 py-4 bigContainer">
      <div class="grid grid-cols-12 w-full">
        <div class="col-span-3 gap-10">
          <p class="text-newGray text -base">Company Name</p>
          <p class="">
            {{ tax.companyTaxInfo ? tax.companyTaxInfo.companyName : "" }}
          </p>
        </div>
        <div class="col-span-6">
          <p class="text-newGray text-base">Services</p>
          <p class="text-sm md:text-base">
            <span
              v-for="(service, serviceId) in tax.taxInformation.services"
              :key="serviceId"
            >
              {{ service.split("_").join(" ") }}

              <span v-if="serviceId < tax.taxInformation.services.length - 1">
                ,
              </span>
            </span>
          </p>
        </div>
        <div class="col-span-2">
          <p class="text-newGray text-base">Date Applied</p>
          <p>{{ formatTimestamp(tax.updatedAt) }}</p>
        </div>
        <div class="col-span-1">
          <p class="text-newGray text-base">No. of Staff</p>
          <p>{{ tax.companyTaxInfo.staffNo }}</p>
        </div>
      </div>
    </div>
    <div
      class="block md:hidden px-4 md:px-6 py-4"
      :class="toggleCollaspe ? 'expanded' : ''"
    >
      <div class="flex justify-between items-center">
        <div>
          <p class="text-newGray text-base">Company Name</p>
          <p> {{ tax.companyTaxInfo ? tax.companyTaxInfo.companyName : "" }}</p>
        </div>
        <div @click="toggleCollaspe = !toggleCollaspe">
          <img
            src="@/assets/icons/chevron-down.svg"
            :class="toggleCollaspe ? 'rotated' : ''"
            alt=""
          />
        </div>
      </div>
      <div v-if="toggleCollaspe" class="">
        <div class="mt-4">
          <p class="text-newGray text-sm">Filed Services</p>
          <p class="text-sm md:text-base">
            <span
              v-for="(service, serviceId) in tax.taxInformation.services"
              :key="serviceId"
            >
              {{ service.split("_").join(" ") }}

              <span v-if="serviceId < tax.taxInformation.services.length - 1">
                ,
              </span>
            </span>
          </p>
        </div>
        <div class="grid grid-cols-2 mt-4">
          <div>
            <p class="text-newGray text-sm">Date Applied</p>
            <p class="text-sm md:text-base">
              {{ formatTimestamp(tax.updatedAt) }}
            </p>
          </div>
          <div>
            <p class="text-newGray text-sm">No. of Staff</p>
            <p class="text-sm md:text-base">{{ tax.companyTaxInfo.staffNo }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  tax: {
    type: Object,
    default: () => ({}),
  },
});
const toggleCollaspe = ref(false);
const formatTimestamp = (inputTimestamp) => {
  // Convert string to Date object
  var dateObject = new Date(inputTimestamp);

  // Format date to the desired format
  var options = { year: "numeric", month: "short", day: "numeric" };
  var formattedDate = dateObject.toLocaleDateString("en-US", options);

  // Return the formatted date
  return formattedDate;
};
</script>

<style scoped>
.rotated {
  transform: rotate(-90deg); /* Equal to rotateZ(45deg) */
}
.expanded {
  border-radius: 8px;
  border: 2px solid var(--new-styles-grays-outline, #e7e8e7);
  background: var(--new-styles-shades-white, #fff);

  /* Elevations/Light Mode/Light 2 */
  box-shadow: 0px 6px 12px 0px rgba(170, 170, 170, 0.11);
}
.bigContainer {
  border-radius: 8px;
  background: var(--new-styles-shades-white, #fff);

  /* Elevations/Light Mode/Light 1 */
  box-shadow: 0px 4px 8px 0px rgba(170, 170, 170, 0.08);
}
</style>
