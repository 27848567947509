<template>
  <div
    v-if="edit"
    :class="type === 'add' ? 'pb-10' : 'py-10'"
    class="md:full w-full mx-auto text-left"
  >
    <div class="mb-6">
      <h2
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-headerText font-medium text-lg md:text-2xl"
        :class="type === 'add' ? ' text-center' : 'text-left'"
      >
        {{ type === "edit" ? " Edit Document" : "Add Document" }}
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>

    <form
      @submit.prevent="
        type === 'add' ? AddUserDocuments(true) : UpdateUserDocument()
      "
      autocomplete="on"
      class="flex flex-col gap-y-4 px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <!-- <label for="title" class="text-dark-800 text-sm font-medium"
        >Document Title
      </label> -->
      <easiTextInput
        placeholder="Title "
        type="text"
        name="title"
        class="mt-1 mb-5"
        v-model="args.documentTitle"
      ></easiTextInput>

      <!-- <label for="link" class="text-dark-800 text-sm font-medium"
        >Paste Document Link Here
      </label> -->

      <easiTextInput
        placeholder="Paste Document Link Here"
        type="text"
        name="link"
        class="mt-1 mb-5"
        v-model="args.documentUrl"
      ></easiTextInput>

      <p
        class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
      >
        Or
      </p>

      <UploadComponent @fileUrl="uploadFile = $event" :clear="removeFile" />

      <div
        v-if="type === 'edit'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          @click="edit = false"
          type="button"
          variant="outlined"
          class="bg-white w-80 md:w-40"
        >
          <span class="text-sm hover:text-white text-dark-800"> Cancel</span>
        </easiButton>
        <easiButton
          :loading="loading.edit"
          type="submit"
          class="w-80 md:w-40"
          :class="loading.edit ? 'w-40 w-80 md:w-40' : 'w-fit'"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>
      <!-- ////// Add Buttons ///// -->
      <div
        v-if="type === 'add'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          :loading="loading.save"
          type="button"
          @click="save"
          color="primary"
          variant="outlined"
          :class="loading.save ? 'pointer-events-none' : ''"
          class="w-80 md:w-52"
        >
          <span class="text-sm"> Save And Add More </span>
        </easiButton>
        <easiButton
          :loading="loading.add"
          type="submit"
          color="primary"
          class="w-80 md:w-52"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>
      <p
        v-if="type === 'edit'"
        @click="deleteWarning = true"
        class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
      >
        Delete Document
      </p>
      <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
        <template v-slot:message>
          <span
            >Employee's document
            {{ type === "edit" ? "updated" : "added" }} successfully</span
          >
        </template>
      </easiSuccess>

      <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
        <template v-slot:message>
          <span>Are you sure you want to delete this document?</span>
        </template>
        <template v-slot:action>
          <div class="flex flex-col items-center gap-4">
            <div class="flex justify-center items-center w-full gap-x-4 mt-6">
              <easiButton
                class="w-40 min-w-full"
                @click="deleteWarning = false"
                variant="outlined"
                >Cancel</easiButton
              >
              <easiButton
                class="w-40 min-w-full"
                :loading="loading.delete"
                @click="deleteUserDocument"
                >Yes</easiButton
              >
            </div>
          </div>
        </template>
      </easiWarn>
    </form>
  </div>

  <ViewDocuments @doc="update($event)" v-else />
</template>

<script setup>
import picIcon from "@/assets/icons/pic.svg";
import ViewDocuments from "@/components/Employee/View/Documents/Documents";
import UploadComponent from "@/components/global/UploadComponent";

import { useRoute } from "vue-router";
import {
  ref,
  reactive,
  nextTick,
  watch,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const { resetObj, uploadFileToServer } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();

const route = useRoute();
const emit = defineEmits("completed", "complete");
const props = defineProps({
  type: {
    type: String,
    default: "edit",
  },
});

const uploadFile = ref(null);
let updateSuccess = ref(false);
const removeFile = ref(false);
const deleteWarning = ref(false);

const employee = computed(() => store.getEmployeeById(route.params.id));
console.log(employee);
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

let args = reactive({
  _id: "",
  companyId: "",
  employeeId: "",
  documentTitle: "",
  documentUrl: "",
});

const loading = ref({
  save: false,

  add: false,
  edit: false,
  delete: false,
});

const edit = ref(false);
const hide = ref(true);
let message = ref("");
let fileName = ref("");
let fileSize = ref("");
let url = ref("");
const argsArray = ref([]);

const employeeId = route.params.id;
const profileArgsFromStore = computed(() => store.profileData);

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}

async function addMore(arg) {
  if (
    props.type === "add" &&
    args.hasOwnProperty("_id") &&
    args._id.length <= 0
  ) {
    delete args._id;
  }
  console.log(args, "ONADD");
  if (uploadFile.value) {
    args.documentUrl = await uploadFileToServer(uploadFile.value);
  }

  const newObj = Object.assign({}, args);
  args.employeeId = employee.value._id;
  args.companyId = employee.value.companyId;
  if (args.documentUrl != null) {
    argsArray.value.push(newObj);

    store.$patch((state) => {
      state.profileArr.push(newObj);
    });

    return args.documentUrl;
  } else {
    toast.error("File upload failed");
    return false;
  }
}

async function save() {
  try {
    let URL = await addMore(args);
    if (URL !== false) {
      await AddUserDocuments(false);
      delete args._id;
      delete args.__typename;
      // addMore(args);
      Object.assign(args, resetObj(args, "args"));
      uploadFile.value = null;
      removeFile.value = true;
      console.log("CLEARED");
    }

    toast.success("Details saved!");
  } catch (e) {
    console.log(e);
  } finally {
    loading.value.save = false;
  }

  // window.location.reload();
}
async function UpdateUserDocument() {
  try {
    delete args["createdAt"];
    delete args["updatedAt"];
    loading.value.edit = true;
    args.employeeId = employee.value._id;
    args.companyId = employee.value.companyId;
    if (uploadFile.value) {
      args.documentUrl = await uploadFileToServer(uploadFile.value);
    }
    if (args.documentUrl != null && args.documentUrl.length > 0) {
      let res = await mutate({
        endpoint: "UpdateUserDocument",
        data: {
          input: args,
        },
        service: "EMP",
      });

      console.log(res);
      if (res.documentTitle.length) {
        await queryEmployees();
        loading.value.edit = false;
        // toast.success("User document Updated Successfuly");
        updateSuccess.value = true;
        Object.assign(args, resetObj(args, "args"));
        setTimeout(() => {
          updateSuccess.value = false;
          edit.value = false;
          // window.location.reload();
        }, 1000);
      }
    }
  } catch (e) {
    console.log(e);
    loading.value.edit = false;
  } finally {
    loading.value.edit = false;
  }
}

async function AddUserDocuments(final) {
  if (final) {
    loading.value[props.type] = true;
  } else {
    loading.value.save = true;
  }
  delete args["_id"];
  delete args.__typename;
  args.employeeId = employee.value._id;
  args.companyId = employee.value.companyId;
  if (final) {
    let URL = await addMore(args);
  }
  if (
    (args.documentUrl != null &&
      args.documentUrl.length > 0 &&
      URL !== false) ||
    (!final && args.documentUrl != null && args.documentUrl.length > 0)
  ) {
    try {
      let res = await mutate({
        endpoint: "AddUserDocuments",
        data: {
          employeeId,
          input: profileArgsFromStore.value,
        },
        service: "EMP",
      });

      if (res[0].documentTitle.length) {
        await queryEmployees();
        loading.value[props.type] = false;
        loading.value.save = false;

        // toast.success("User document Updated Successfuly");
        if (final) {
          updateSuccess.value = true;

          setTimeout(() => {
            updateSuccess.value = false;
            edit.value = false;
            emit("complete", true);
            // window.location.reload();
          }, 1000);
          emit("completed", false);
        }
        store.$patch({
          profileArr: [],
        });
        Object.assign(args, resetObj(args, "args"));
      } else {
        loading.value[props.type] = false;
        loading.value.save = false;
      }
    } catch (e) {
      loading.value[props.type] = false;
      loading.value.save = false;
      console.log(e);
      args = resetObj(args, "args");
      store.$patch({
        profileArr: [],
      });
    }
  }
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function update(event) {
  edit.value = true;
  const arr = Object.keys(event);
  const assign = arr.map((key) => {
    args[key] = event[key];
    return event;
  });
  delete args["__typename"];
  console.log("EDIT", args);
}
// let url = ref("");
function previewFiles(e) {
  const file = e.target.files[0];

  const allowedTypes = [
    "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint",
    "text/plain",
    "application/pdf",
  ];
  if (!allowedTypes.includes(file.type)) {
    message.value = "File type is wrong!!";
    throw new Error(message.value);
  }
  if (file.size > 10000000) {
    message.value = "Too large, max size allowed is 10MB";
    throw new Error(message.value);
  }
  const { name, size } = file;
  fileName.value = name;
  args.documentTitle = name;

  fileSize.value = formatBytes(size);
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    args.documentUrl = e.target.result;
    console.log(args.documentUrl, "K");
  };
  reader.readAsDataURL(file);
  // url = URL.createObjectURL(file);
}
onMounted(async () => {
  props.type === "add" ? (edit.value = true) : (edit.value = false);
  await queryEmployees();
  if (employee.value) {
    args.employeeId = employee.value._id;
    args.companyId = employee.value.companyId;
  }
  store.$patch({
    profileArr: [],
  });
});
// onUnmounted(() => {
//   store.$patch({
//     profileArr: [],
//   });
// });

async function deleteUserDocument() {
  loading.value.delete = true;
  // let URL = await addMore(args);

  // addMore(args);
  const payload = {
    employeeId,
    documentId: args._id,
  };
  try {
    let res = await mutate({
      endpoint: "DeleteUserDocument",
      data: payload,
      service: "EMP",
    });
    if (res.success) {
      // toast.success("Education History Updated Successfuly");
      updateSuccess.value = true;
      deleteWarning.value = false;
      await queryEmployees();

      setTimeout(() => {
        updateSuccess.value = false;
        edit.value = false;
        emit("complete", true);
        // window.location.reload();
      }, 500);
    } else {
      loading.value.delete = false;
    }
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  } finally {
    loading.value.delete = false;
  }
}
</script>

<style></style>
