<template>
  <div class="py-4 flex-auto">
    <div
      :class="[
        contributions && !!contributions.totalAmountPaid
          ? ''
          : 'border border-gray-200 rounded-full',
      ]"
      class="relative h-60 w-60"
    >
      <canvas id="doughnut-chart"></canvas>
      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-bold text-center"
      >
        <p class="font-bold text-xl">
          {{ formatAmount(contributions && contributions.totalAmountPaid) }}
        </p>
        <p class="text-newGray font-thin text-base">Total</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { onMounted, computed, nextTick } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;
const store = useDataStore();
const contributions = computed(() => store.getContribution || null);

onMounted(() => {
  runChart();
});

const runChart = () => {
  nextTick(function () {
    var ctx = document.getElementById("doughnut-chart").getContext("2d");
    // ctx.fillText(data[0].value + "%", width/2 - 20, width/2, 200);
    window.myDoughnut = new Chart(ctx, config.value);
  });
};

const config = computed(() => {
  const {
    totalAmountPaid,
    totalMicropensionAmount,
    totalPAYEAmount,
    totalPensionAmount,
  } = contributions.value || {};

  function getPercentage(amount) {
    return amount ? (amount * 100) / totalAmountPaid : 0;
  }

  const chartData = totalAmountPaid
    ? [
        getPercentage(totalPAYEAmount),
        getPercentage(totalPensionAmount),
        getPercentage(totalMicropensionAmount),
      ]
    : [33, 33, 33];

  const bgColors = totalAmountPaid
    ? ["#0074D9", "#219653", "#FF851B"]
    : ["#DDDEDD", "#DDDEDD", "#DDDEDD"];

  console.log("Chart Data", chartData);

  return {
    type: "doughnut",
    data: {
      labels: ["PAYE", "Pension", "Micro Pension"],
      datasets: [
        {
          data: chartData,
          backgroundColor: bgColors,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };
});
</script>

<style lang="scss" scoped>
/* Add your component-specific styles here */
</style>
