<template>
  <div class="flex flex-col gap-6">
    <div
      class="flex items-center justify-between gap-4 uppercase md:capitalize border border-dark-100 rounded-md p-2 md:p-0 md:rounded-none md:border-none"
    >
      <div class="flex items-center gap-2 md:gap-4">
        <h2 class="md:text-lg">Personal Details</h2>
        <GetStatus
          v-if="
            kycInfo &&
            kycInfo.personalInformation &&
            kycInfo.personalInformation.status
          "
          :status="kycInfo.personalInformation.status"
        />
      </div>

      <div>
        <easiButton
          v-if="
            kycInfo &&
            kycInfo.personalInformation &&
            kycInfo.personalInformation.status &&
            kycInfo.personalInformation.status !== 'verified' &&
            hasData
          "
          @click="() => (hasData = false)"
          variant="outlined"
          size="small"
          >Edit</easiButton
        >

        <div v-else>
          <slot />
        </div>
      </div>
    </div>

    <ViewPersonal v-if="kycInfo && kycInfo.personalInformation && hasData" />
    <EditPersonal @saved="hasData = true" v-else />
  </div>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";

import ViewPersonal from "./View.vue";
import EditPersonal from "./Edit.vue";
import GetStatus from "../GetStatus.vue";

import { computed, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { breakPoints } = helperFunctions;

const store = useDataStore();

const kycInfo = computed(() => store.getKyc && store.getKyc.data);

const hasData = ref(true);
const status = ref("Verified");
</script>

<style></style>
