<template>
  <main class="my-10 w-full">
    <div
      class="text-left w-full bg-white border border-outlineGray rounded-xl py-1 px-2 flex justify-between gap-2 overflow-x-scroll no-scrollbar"
    >
      <!-- <p class="text-xs -z-1">
                <i :class="sub.icon" class="mr-2 text-xs text-blueGray-400"></i>

                {{ sub.name }}
              </p> -->

      <router-link
        @click="moveCenter($event)"
        class="text-sm whitespace-nowrap py-3.5"
        v-for="link in links"
        :key="link.name"
        tag="li"
        :to="
          $route.path.includes('view')
            ? { name: link.routeName, params: { id: $route.params.id } }
            : { name: link.editName, params: { id: $route.params.id } }
        "
        v-slot="{ href, navigate, isActive }"
      >
        <a
          :href="href"
          @click="navigate"
          class="text-sm whitespace-nowrap py-3.5"
          :class="[
            isActive
              ? 'text-offWhite bg-primary rounded-lg px-6'
              : 'text-dark-800 hover:text-primary bg-newGrey rounded-lg px-6 closest',
          ]"
        >
          {{ link.name }}
        </a>
      </router-link>
    </div>
  </main>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";

const id = ref("");
const links = reactive([
  {
    name: "Personal Information",
    routeName: "PersonalInfo",
    routePath: "/employee/view/personal-info",
    editName: "EditPersonalInfo",
  },
  {
    name: "Educational Information",
    routeName: "EducationalInfo",
    routePath: "/employee/view/education-info",
    editName: "EditEducationalInfo",
  },
  {
    name: "Job History",
    routeName: "JobHistory",
    routePath: "/employee/view/job-history",
    editName: "EditJobHistory",
  },
  {
    name: "Documents",
    routeName: "EmployeeDocuments",
    routePath: "/employee/view/documents",
    editName: "EditEmployeeDocuments",
  },
  {
    name: "Benefits",
    routeName: "EmployeeBenefits",
    routePath: "/employee/view/benefits",
    editName: "EditEmployeeBenefits",
  },
  {
    name: "Company Assets",
    routeName: "CompanyAssets",
    routePath: "/employee/view/company-assets",
    editName: "EditCompanyAssets",
  },
  {
    name: "Emergency Contact",
    routeName: "EmergencyContact",
    routePath: "/employee/view/emergency-contact",
    editName: "EditEmergencyContact",
  },
]);

onMounted(() => {
  id.value = useRoute().params.id;
});

const moveCenter = (e) => {
  const element = e.target;

  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });

  e.preventDefault();
};
</script>

<style></style>
