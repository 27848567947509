<template>
  <div>
    <p class="text-newGray text-sm mb-4">{{ label }}</p>
    <div class="flex justify-between gap-6">
      <div
        class="w-full border border-outlineGray rounded-2xl px-4 flex justify-between items-center gap-5"
      >
        <input
          :type="isPassword ? 'password' : 'text'"
          placeholder=""
          disabled
          class="border-none w-full focus:ring-0 appearance-none text-dark-300"
          v-model="input"
        />
        <div @click="isPassword = !isPassword" class="cursor-pointer">
          <img src="@/assets/icons/eye-slash.svg" alt="" />
        </div>
      </div>
      <div
        class="border border-outlineGray cursor-pointer rounded-3xl px-7 flex items-center gap-2"
        @click="copy"
      >
        <img src="@/assets/icons/clipboard-document.svg" alt="" />

        <p class="text-newGray">Copy</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["copy"]);

const watchValue = computed(() => props.value);

const isPassword = ref(true);
const input = ref("");

watch(
  watchValue,
  () => {
    input.value = props.value;
  },
  { immediate: true }
);

function copy() {
  emit("copy", input.value);
  navigator.clipboard.writeText(input.value);
  toast.info("Copied!");
}
</script>

<style lang="scss" scoped></style>
