<template>
  <div class="flex justify-between px-6 py-4">
    <div class="text-newGray text-sm">
     Showing {{ currentPage }}
      of

      {{ totalPages }}
      <!-- <span
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        @click="goToPage(pageNumber)"
        :class="{ active: pageNumber === currentPage }"
      >
      <span class="mx-5">
        {{ pageNumber }}

      </span>
      </span> -->
    </div>

    <div class="flex gap-10 text-primary font-medium text-sm">
      <div
        @click="prevPage"
        v-if="currentPage > 1"
        class="flex items-center gap-2 cursor-pointer"
      >
        <i class="pi pi-angle-left" />
        <div>Back</div>
      </div>
      <div
        @click="nextPage"
        v-if="currentPage < totalPages"
        class="flex items-center gap-2 cursor-pointer"
      >
        <div>Next Page</div>
        <i class="pi pi-angle-right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },
  methods: {
    goToPage(page) {
      this.$emit("page-change", page);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.goToPage(this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.goToPage(this.currentPage + 1);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination span {
  margin-right: 8px;
  cursor: pointer;
}

.pagination span.active {
  font-weight: bold;
}
</style>
