<template>
  <div
    class="flex items-center relative"
    :class="useNewclass ? ' border border-newGray rounded-xl ' : '  '"
  >
    <input
      autocomplete
      :class="
        error
          ? 'focus:border-error border-error'
          : 'focus:border-secondary border-dark-200' +
            (useNewclass ? ' border-none ' : '  border border-r-0 ')
      "
      class="bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none p-4 block appearance-none rounded-tl-2xl rounded-bl-2xl"
      v-model="inputValue"
      v-bind="$attrs"
      @keypress.enter="searchFN"
      @keyup="querySuggestedEmployees"
      @input="(e) => $emit('update:modelValue', e.target.value)"
    />
    <!-- :value="modelValue" -->

    <button
      @click="searchFN"
      style="border: 3px solid transparent"
      class="rounded-tr-2xl rounded-br-2xl px-6 sm:px-10 py-4 bg-newLightGreen focus:bg-success-hover focus:outline-none"
    >
      <img src="../../assets/icons/newSearchIcon.svg" alt="" />
    </button>

    <div
      style="max-height: 500px"
      v-if="inputValue.trim() && isSuggestion"
      class="overflow-y-auto w-full h-auto bg-white shadow-md z-2 mt-1 rounded-md absolute top-14 left-0 overflow-hidden"
    >
      <easiLoader v-if="loading" />

      <div
        v-for="(item, i) in suggestedEmployees.data"
        :key="i"
        @click="selectSuggestion(item)"
        class="w-full last:border-b-0 border-b border-dark-50 p-2 sm:p-4 cursor-pointer hover:bg-background-50 flex gap-3 items-center"
      >
        <div
          v-if="item && item.profile.picture"
          class="border w-9 h-9 rounded-full overflow-hidden"
        >
          <img
            class="w-full h-full object-cover"
            :src="item.profile.picture"
            :alt="item.profile.firstName + ' ' + item.profile.lastName"
          />
        </div>
        <div
          v-else
          class="p-2 h-9 w-9 text-sm bg-secondary text-white font-medium rounded-full"
        >
          {{ getAvatar(item).name }}
        </div>
        <div
          class="text-dark-800 text-md font-medium flex flex-col items-start"
        >
          <span>{{
            `${item && item.profile.firstName} ${item && item.profile.lastName}`
          }}</span>
          <span class="text-sm text-dark-700 font-thin">{{
            item.profile.email
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query } = store;
const { suggestedEmployees, checkLoading } = storeToRefs(store);

const emit = defineEmits(["search", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  useNewclass: {
    type: Boolean,
    default: false,
  },

  suggestion: {
    type: Boolean,
    default: true,
  },

  error: {
    type: [String, Boolean],
    default: null,
  },
});

const isSuggestion = ref(false);
const loading = ref(false);
const input = ref("");
const inputValue = computed(() => props.modelValue);

watch(inputValue, async (val) => {
  if (!props.suggestion) return;
  isSuggestion.value = true;
});

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const getAvatar = (employee) => {
  let name = "",
    color;
  let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
  const empId = employee.job && employee.job.userName;
  const thenum = empId ? Number(empId.replace(/\D/g, "")) : 1;
  const initialOne = String(
    employee &&
      employee.profile &&
      employee.profile.firstName &&
      employee.profile.firstName.charAt(0)
  );
  const initialTwo = String(
    employee &&
      employee.profile &&
      employee.profile.lastName &&
      employee.profile.lastName.charAt(0)
  );
  const num =
    Number(alphabet.indexOf(initialOne.toLowerCase())) +
    Number(alphabet.indexOf(initialTwo.toLowerCase()));
  color = getColor(num);
  // Get color

  employee
    ? (name = `${
        employee &&
        employee.profile &&
        employee.profile.firstName &&
        employee.profile.firstName.charAt(0)
      }${
        employee &&
        employee.profile &&
        employee.profile.lastName &&
        employee.profile.lastName.charAt(0)
      }`)
    : "";

  return {
    color,
    name,
  };
};

const selectSuggestion = async (arg) => {
  const name = arg ? arg.profile.firstName : null;
  emit("update:modelValue", name);
  await searchFN();

  isSuggestion.value = false;
};

const searchFN = () => {
  console.log(inputValue.value);
  emit("search", inputValue.value);
};

const querySuggestedEmployees = async () => {
  if (!props.suggestion) return;
  if (inputValue.value.trim() !== "") {
    const payload = {
      paging: {
        limit: 1000,
        skip: 0,
        search: inputValue.value,
        sort: {
          firstName: "ASC",
        },
      },
      filter: [],
    };
    try {
      loading.value = true;
      await query({
        endpoint: "ListEmployees",
        payload: payload,
        service: "EMP",
        storeKey: "suggestedEmployees",
      })
        .then(() => {
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    store.$patch({
      suggestedEmployees: [],
    });
  }
};
</script>

<style></style>
