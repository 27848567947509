<template>
  <div class="relative overflow-hidden md:rounded-t-3xl pt-1">
    <!-- /// Loading //// -->

    <div
      v-if="loading"
      :style="{ background: loadingMode === 'circle' ? '#ffffff73' : '' }"
      :class="[
        loadingMode === 'circle'
          ? 'bottom-0 right-0 h-full  backdrop-blur-lg z-10 flex pt-28 md:pt-0 md:items-center justify-center'
          : '',
      ]"
      class="w-full absolute left-0 top-0"
    >
      <easiLoader :circle="loadingMode === 'circle'" />
    </div>

    <!-- ///// DESKTOP Table ///// -->
    <DataTable
      id="table-style"
      fixed
      :class="[selectable ? 'selectable' : '']"
      class="hidden md:block"
      :value="data"
      :scrollable="scrollable"
      scrollDirection="both"
      columnResizeMode="fit"
      :scrollHeight="scrollHeight"
      @rowSelect="onRowSelect"
      @rowUnselect="onRowUnselect"
      v-model:selection="selectedData"
      :dataKey="dataKey"
      :showGridlines="showGridlines"
      :stripedRows="stripedRows"
      :sortMode="sortMode"
    >
      <Column
        v-if="selectable"
        :selectionMode="selectionMode"
        headerStyle="width: 3em;"
      ></Column>

      <Column
        v-for="col in headers"
        :key="col.title"
        :bodyStyle="
          `${col.style}; ${col.highlight ? 'background:#F0F7EB80' : ''}` || ''
        "
        :field="col.field"
        :header="col.title"
        :sortable="col.sortable || undefined"
        :frozen="col.frozen || false"
      >
        <template #body="slotProps">
          <div
            :class="[
              onRowClick && !col.preventRowClick ? 'cursor-pointer' : '',
            ]"
            @click="handleRowClick(slotProps.data, col)"
          >
            <div v-if="$slots[col.type]" class="text-sm">
              <slot
                :name="`${col.type}`"
                :data="slotProps.data"
                :index="slotProps.index"
              />
            </div>
            <div v-else class="text-sm">
              {{
                col.format
                  ? col.format(slotProps.data[col.field])
                  : slotProps.data[col.field]
              }}
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- ///////  Mobile Table ////// -->
  <div class="flex md:hidden w-full">
    <div class="w-full flex flex-col gap-3">
      <!-- /// Select All //// -->
      <div v-if="selectable" class="">
        <div class="flex items-center gap-2">
          <input
            class="border-2 h-5 w-5"
            type="checkbox"
            :checked="isAllSelected"
            @change="selectAll"
          />
          <span class="text-newLightgrey">Select All</span>
        </div>
      </div>

      <!-- ///// Table Body ///// -->

      <table
        v-for="(item, index) in data"
        :key="`table-${index}`"
        class="table-auto w-full border border-gray-300 overflow-y-auto"
      >
        <tbody class="font-medium text-sm">
          <tr v-for="(col, i) in headers" :key="`head-${i}`">
            <td
              style="background: #e9efee"
              class="w-4/12 border px-4 py-3 border-outlined"
            >
              <div class="flex items-center gap-2 capitalize">
                <input
                  v-if="selectable && i === 0"
                  type="checkbox"
                  :checked="isSelected(item)"
                  @change="onSelect(item)"
                  name=""
                  id=""
                />
                {{ col.title }}
              </div>
            </td>
            <td
              @click="handleRowClick(item, col)"
              class="w-8/12 border border-outlineGray text-left p-3 group-hover:bg-highlight bg-white"
            >
              <div v-if="$slots[col.type]" class="text-sm">
                <slot :name="`${col.type}`" :data="item" />
              </div>
              <div v-else>
                {{ col.format ? col.format(item[col.field]) : item[col.field] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },

  scrollHeight: {
    type: String,
    default: "30rem",
  },

  showGridlines: {
    type: Boolean,
    default: false,
  },
  stripedRows: {
    type: Boolean,
    default: false,
  },

  scrollable: {
    type: Boolean,
    default: true,
  },

  selected: Array,
  selectable: {
    type: Boolean,
    default: false,
  },

  selectionMode: {
    type: String,
    default: "multiple",
  },
  sortMode: {
    type: String,
    default: undefined, //multiple
  },

  dataKey: {
    type: String,
    default: "_id",
  },

  loading: {
    type: Boolean,
    default: false,
  },

  loadingMode: {
    type: String,
    default: "circle", //line || circle
  },

  onRowSelect: Function,
  onRowUnselect: Function,
  onRowClick: Function,
});

const emit = defineEmits(["update:selected"]);

const selectedData = computed({
  get() {
    return props.selected;
  },
  set(val) {
    emit("update:selected", val);
  },
});
let isAllSelected = ref(false);

const selectAll = () => {
  isAllSelected.value = !isAllSelected.value;

  if (isAllSelected.value) {
    const arr = [];
    props.data.forEach((el) => {
      arr.push(el);
    });

    selectedData.value = arr;
  } else {
    selectedData.value = [];
  }
};

const onSelect = (item) => {
  const { dataKey } = props;
  let arr = [];

  if (isSelected(item)) {
    arr = selectedData.value.filter((el) => el[dataKey] !== item[dataKey]);
    selectedData.value = arr;
  } else {
    selectedData.value.push(item);
  }
};

const isSelected = (item) => {
  const { dataKey } = props;

  return !!selectedData.value.find((el) => el[dataKey] == item[dataKey]);
};

const handleRowClick = (e, col) => {
  if (props.onRowClick && !col.preventRowClick) {
    props.onRowClick(e);
  }
};
</script>

<style lang="scss">
#table-style {
  font-size: 12px;

  overflow: auto;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  th {
    border-right: 1px solid #e7e8e7;
    background: #e9efee;
    color: #515251;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 16px;
    }

    &:last-child {
      border-top-right-radius: 16px;
      border-right: none;
    }
  }

  td {
    border-right: 1px solid #e7e8e7;

    border-bottom: 1px solid #ededed;
    color: #515251;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.selectable {
  tr {
    transition: background-color 0.3s ease-in-out;
    background: #fcfbfb;
  }
}
</style>
