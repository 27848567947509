<template>
  <div class="px-4 md:px-28 py-6">
    <p class="font-bold text-2xl text-black mb-10">Team</p>

    <div class="px-8 py-10 border border-outlineGray">
      <div class="flex items-center justify-between">
        <p class="text-grey text-lg flex items-center gap-1">
          Your team
          <img src="@/assets/icons/apikyc.svg" class="w-2 h-2 mt-1" alt="" />

          {{ team.length }} member
        </p>
        <div
          v-if="userProfile && userProfile.membership == 'owner'"
          class="bg-primary text-white py-3 px-5 rounded-full flex items-center gap-2 font-medium cursor-pointer"
          @click="isOpen = true"
        >
          <img src="@/assets/icons/Whiteplus.svg" alt="" />

          Invite new Developer
        </div>
      </div>
      <div
        v-if="team.length"
        class="border border-outlineGray rounded-3xl p-4 mt-6"
      >
        <div v-for="(dev, i) in team" :key="dev._id">
          <ApiTeamMember @query="queryTeams" :data="dev" />
          <hr class="my-4" v-if="i !== team.length - 1" />
        </div>
      </div>
      <div
        v-if="!team.length && !loading"
        class="text-base text-center py-6 text-semibold"
      >
        You have no team member
      </div>
    </div>

    <ApiAddTeam @query="queryTeams" :isOpen="isOpen" @close="isOpen = false" />

    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import ApiAddTeam from "../../components/ApiDashboard/ApiAddTeam.vue";
import ApiTeamMember from "@/components/ApiDashboard/ApiTeamMember.vue";

import { ref, reactive, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query, mutate } = store;

const userProfile = computed(() => store.apiProfile);

const team = computed(() => {
  const arr = store.getApiTeams || [];
  return arr.map((el) => {
    el.permission = el.permission || [];
    return el;
  });
});
console.log(team.value);

const loading = ref(false);
const isOpen = ref(false);

onMounted(async () => {
  await queryTeams();
});

const handleEditMember = (arg) => {
  member.value = arg;
  editModal.value = true;
};

const queryTeams = async (showLoading = true) => {
  try {
    loading.value = showLoading;

    const res = await query({
      endpoint: "ListTeamMembers",
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiTeams: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
