<template>
  <div>
    <div
      @click="emit('click')"
      class="p-2 border rounded-lg truncate border-outlineGray cursor-pointer text-info-light hover:bg-info-disabled text-sm inline-block"
      @mouseenter="showText = true"
      @mouseleave="showText = false"
    >
      <span v-if="showText" class=""> Edit </span>
      {{ text }}
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["click"]);

const showText = ref(false);
</script>

<style lang="scss" scoped></style>
