<template>
  <main>
    <div class="w-full">
      <table
        v-for="(dat, i) in splitData"
        :key="i"
        class="w-full border border-separate border-goldBorder"
        :class="i > 0 ? 'my-4' : ''"
      >
        <thead class="">
          <tr class="text-header font-medium bg-white text-xs relative">
            <th
              v-for="header in headers"
              :key="header"
              class="border-r border-b border-t border-btnPri text-xs text-left p-2 text-grey"
            >
              {{ header }}
            </th>
          </tr>
        </thead>

        <tbody class="relative">
          <tr
            v-for="(sub, k) in dat"
            :key="k"
            class="hover:bg-easigrey group bg-white cursor-pointer relative"
          >
            <td
              class="border-r border-t border-btnPri font-medium text-left text-dark-900 text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ getDateString(sub.transactionDate).fullDate || "N/A" }}
            </td>
            <td
              class="border-r border-t border-btnPri font-medium text-left text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize left-8"
              :class="
                sub.transactionType.toLowerCase() === 'debit'
                  ? 'text-secondary'
                  : 'text-success'
              "
            >
              {{ sub.transactionType.toLowerCase() }}
            </td>
            <td
              :class="
                sub.transactionType.toLowerCase() === 'debit'
                  ? 'debit'
                  : 'bg-success-plain'
              "
              class="border-r border-t border-btnPri font-medium text-left text-dark-900 text-xs p-3 group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ formatAmount(sub.amount) }}
            </td>
            <td
              class="border-t border-r border-btnPri capitalize font-medium text-left text-dark-900 text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ sub.description }}
            </td>
            <td
              class="border-t border-btnPri font-medium text-left text-dark-900 debit text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300"
            >
              {{ formatAmount(sub.balance) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <easiPagin
      v-if="splitData.length"
      :data="splitData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    /> -->
  </main>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query } = useDataStore();

const { formatAmount, calculatePercentage } = helperFunctions;
const walletReportData = computed(() => store.getWalletReport);
const props = defineProps({
  showFullData: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: [],
  },
});
const propD = ref(props.data);

const headers = ref([
  "DATE",
  "TRANSACTION",
  "AMOUNT",
  "DESCRIPTION",
  "WALLET BALANCE",
]);

const currentPage = ref(0);
const pageSize = ref(1);
let visibleData = ref([]);

function getDateString(utc) {
  const days = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thur: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };

  const months = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  const dateArr = utc.split(" ");
  const dayOfWeek = days[dateArr[0]];
  const month = months[dateArr[1]];
  const dayAndYear = `${dateArr[2]}, ${dateArr[3]}`;
  const time = dateArr[4].slice(0, -3);

  const fullDate = `${dayOfWeek}, ${month} ${dayAndYear}`;

  return {
    fullDate,
    time,
  };
}

const data = ref([
  {
    date: "January 02, 2021",
    transaction: "credit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2023",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2042",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "credit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "credit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "credit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "credit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },

  {
    date: "January 02, 2022",
    transaction: "debit",
    amount: "N11,565,807.67",
    description: "Top up for January, 2021 Payroll",
    balance: "N15,797,000.00",
  },
]);
const splitData = ref([]);
function splitToChunk() {
  const chunkSize = 18;
  let all = [];
  for (
    let i = 0;
    i < walletReportData.value.transactions.length;
    i += chunkSize
  ) {
    const chunk = walletReportData.value.transactions.slice(i, i + chunkSize);
    all.push(chunk);

    // do whatever
  }
  splitData.value = all;
}
splitToChunk();

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = splitData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
updateVisibleData();

watch(propD, (val, newVal) => {
  splitToChunk();
});
</script>

<style>
.debit {
  background: rgba(228, 87, 46, 0.15);
}
</style>
