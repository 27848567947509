<template>
  <div class="bg-blueGray-100 px-2 py-4 sm:px-4 md:px-10 min-h-screen">
    <h4
      class="md:my-3 md:text-sm text-right md:text-left text-xs font-medium text-headerText"
    >
      Settings / {{ getPageName() }}
    </h4>

    <div class="text-left mt-5 font-bold text-3xl">Settings</div>
    <div class="text-left bg-blueGray-100">
      <SettingsMenu />
      <!-- <header-stats /> -->
      <div class="md:mx-auto w-full mt-2">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

import SettingsMenu from "@/components/Settings/SettingsMenu.vue";

const route = useRoute();
const router = useRouter();
const store = useDataStore();
const { query, mutate } = store;

const getPageName = () => {
  const names = {
    Profile: "Profile",
    ManageAccount: "Manage Account",
    BusinessProfile: "Business Profile",
    Team: "Team",
    Security: "Security",
    Refer: "Refer",
    Pricing: "Pricing",
    PayrollSettings: "Payroll Settings",
  };

  return names[route.name];
};

const queryCompanyAdmin = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });
};
const queryCompany = async () => {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
};
const queryReferral = async () => {
  await query({
    endpoint: "FetchReferralCode",
    service: "SETTINGS",
    storeKey: "referralCode",
  });
};
queryCompanyAdmin();
queryCompany();
queryReferral();
</script>
