<template>
  <div class="payroll-calculator-form py-6 px-6 lg:py-12 lg:px-10">
    <div class="text-center mb-8">
      <h3 class="text-xl lg:text-2xl mb-2 font-bold">Payroll Calculator</h3>
      <p>Calculate your payroll either by amount or percentage</p>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="grid gap-4 mb-8">
        <section>
          <RadioButtons
            name="how"
            :options="[
              { label: 'By amount', value: 'currency' },
              { label: 'By Percentage', value: 'percentage' },
            ]"
            v-model="form.allowanceType"
          />
        </section>
        <section>
          <SiteTextInput
            type="currency"
            label="Total Taxable Salary"
            required
            v-model="form.totalTaxableSalary"
          />
        </section>
        <section>
          <div>Allowances</div>
          <div class="grid grid-cols-2 gap-x-4">
            <SiteTextInput
              :type="form.allowanceType"
              label="Basic"
              v-model="form.allowances.basic"
            />
            <SiteTextInput
              :type="form.allowanceType"
              label="Housing"
              v-model="form.allowances.housing"
            />
            <SiteTextInput
              :type="form.allowanceType"
              label="Transport"
              v-model="form.allowances.transport"
            />
            <SiteTextInput
              :type="form.allowanceType"
              label="Others"
              v-model="form.allowances.others"
            />
          </div>
        </section>
        <section>
          <div>Deductions</div>
          <div class="grid gap-2 mb-2">
            <SiteTextInput
              type="currency"
              label="HMO"
              v-model="form.deductions.hmo"
            />
            <div class="flex flex-wrap gap-x-4 gap-y-4">
              <Switch label="PAYE" value="PAYE" v-model="form.benefits" />
              <Switch label="NSITF" value="NSITF" v-model="form.benefits" />
              <Switch label="NHF" value="NHF" v-model="form.benefits" />
              <Switch label="Pension" value="Pension" v-model="form.benefits" />
            </div>
          </div>
        </section>
      </div>

      <SiteButton class="w-full">Run Payroll</SiteButton>

      <div class="mt-2 form-error text-center" v-if="error">
        {{ error }}
      </div>
    </form>
  </div>

  <SiteModal
    v-model="showPopup"
    :disable-close="submitLoading"
    class="popup max-w-lg w-full"
    :class="{ 'result-sent': resultSent === true }"
  >
    <div class="px-8 py-4 popup-body">
      <form v-if="!resultSent" @submit.prevent="sendResult">
        <div class="text-center mb-6">
          <h3 class="text-2xl mb-2 font-bold">Payroll Calculator</h3>
          <p>Kindly enter your email to get your result</p>
        </div>
        <SiteTextInput type="text" label="Name" v-model="name" required />
        <SiteTextInput type="email" label="Email" v-model="email" required />

        <div class="mt-4">
          <SiteButton class="w-full" type="submit" :is-loading="submitLoading">
            Send Result</SiteButton
          >
        </div>
      </form>

      <div v-else class="success-block text-center w-4/5 mx-auto">
        <h3 class="text-2xl mb-4 font-bold">Result Sent!</h3>
        <p>Kindly check your email for your payroll breakdown</p>
      </div>
    </div>
  </SiteModal>
</template>

<script setup>
import { reactive, ref, watch } from "vue";
import RadioButtons from "../../components/RadioButtons.vue";
import SiteButton from "../../components/SiteButton.vue";
import Switch from "../../components/Switch.vue";
import SiteModal from "../../components/SiteModal.vue";
import { useDataStore } from "../../../stores/data";
import SiteTextInput from "../../components/SiteTextInput.vue";

const showPopup = ref(false);
const resultSent = ref(false);
const email = ref("");
const name = ref("");
const submitLoading = ref(false);

const resetPopupState = () => {
  resultSent.value = false;
  email.value = "";
  name.value = "";
};

watch(showPopup, () => {
  if (!showPopup.value) {
    resetPopupState();
  }
});

const form = reactive({
  totalTaxableSalary: "",
  allowanceType: "currency",
  allowances: {
    basic: "",
    housing: "",
    transport: "",
    others: "",
  },
  deductions: {
    hmo: "",
    paye: "",
    nsitf: "",
    nhf: "",
    pension: "",
  },
  benefits: [],
});

const error = ref("");

const store = useDataStore();
const { query } = store;

const validateForm = () => {
  error.value = "";
  let allowances = Object.values(form.allowances);
  let totalAllowance = 0;

  allowances.forEach((allowance) => {
    allowance = Number(allowance);
    if (Number.isNaN(allowance) === false) {
      // allowance is a number, continue
      if (form.allowanceType === "percentage") {
        allowance = (allowance * form.totalTaxableSalary) / 100;
      }
      totalAllowance += allowance;
    }
  });

  if (form.totalTaxableSalary !== totalAllowance) {
    error.value = "Ensure total Allowance amount equal Total Gross";
    return false;
  }
  return true;
};

const prepareInput = () => {
  let allowanceDetails = [];
  Object.entries(form.allowances).forEach(([key, value]) => {
    if (value) {
      let allowance = {
        amount: Number(value),
        name: key,
      };
      allowanceDetails.push(allowance);
    }
  });
  const input = {
    usePercentageForAllowance: form.allowanceType === "percentage",
    grossAmount: Number(form.totalTaxableSalary),
    allowanceDetails: allowanceDetails,
    withholdingTax: false,
    benefits: form.benefits,
  };

  form.benefits.forEach((benefit) => {
    let benefitLowerCase = benefit.toLowerCase();
    input[benefitLowerCase] = {
      amount: 0,
    };
  });

  return input;
};

const handleSubmit = async () => {
  if (!validateForm()) {
    return;
  }
  showPopup.value = true;
};

const sendResult = async () => {
  const input = prepareInput();
  try {
    submitLoading.value = true;
    await query({
      endpoint: "CalculateForStaffLevelV3",
      payload: {
        input: input,
        firstName: name.value,
        email: email.value,
      },
      service: "PAYROLL",
    });
    resultSent.value = true;
    submitLoading.value = false;
  } catch {}
};
</script>

<style lang="scss" scoped>
.payroll-calculator-form {
  box-shadow: 0px 20px 48px rgba(170, 170, 170, 0.29);
  border: 1px solid #e7e8e7;
  box-shadow: 0px 20px 48px rgba(170, 170, 170, 0.29);
  border-radius: 32px;
  background-color: white;
}

.success-block {
  h3 {
    color: #219653;
  }

  p {
    color: #444444;
  }
}

.form-error {
  color: #ef4444;
}
</style>
