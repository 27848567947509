<template>
  <main>
    <div class="relative mt-1" v-bind="$attrs">
      <span
        v-if="label"
        class="pointer-events-none absolute left-5 py-0 -top-3 px-5 bg-white text-xs text-newGray capitalize"
      >
        {{ label }}
      </span>
      <div
        class="p-16 bg-white w-full font-medium text-sm leading-2 focus:outline-none border block appearance-none rounded-2xl focus:border-boxBorder"
        aria-readonly
        id="grid-state"
        @click.self="isSelect = !isSelect"
        :class="[
          `${value ? '  text-dark-700' : '  text-dark-500'} ${
            isSelect ? 'border-boxBorder' : 'border-dark-200'
          }`,
          isSelect ? 'py-0 px-0' : 'py-3 px-4',
        ]"
      >
        <form v-if="isSelect" action="" class="relative w-full z-20 top-0">
          <easiTextInput
            v-model="searchQuery"
            @keyup="searchFunction"
            autofocus
            :forSelect="true"
          ></easiTextInput>
        </form>
        <span class="relative w-full" @click.self="isSelect = true" v-else>{{
          value == ""
            ? "Select"
            : value
                .toString()
                .replace("[", "")
                .replace("]", "")
                .replace(/,(?=[^\s])/g, ", ")
        }}</span>

        <ul
          v-show="isSelect"
          class="mt-10 z-20 w-full h-72 absolute top-6 right-0 py-1 shadow-md bg-white shadow-2xl overflow-y-scroll"
        >
          <li
            v-for="option in optionData"
            :key="option"
            class="cursor-pointer text-sm option-color"
            :class="value == option ? ' bg-boxBorder text-white' : ''"
            @click="update(option)"
          >
            <div class="flex items-center gap-2 p-2">
              <!-- <input
                class="mx-4 my-2"
                @change="update(option)"
                type="radio"
                name=""
                id=""
                :checked="value === option ? true : false"
                :value="option"
                v-model="optionArray"
              /> -->
              <div class="flex items-center gap-4">
                <span
                  class="w-9 h-9 bg-primary inline-flex items-center justify-center rounded-full border border-boxBorder"
                >
                  <span class="text-white text-sm font-medium">{{
                    initials(option)
                  }}</span>
                  <!-- <img
                    alt="..."
                    class="w-full rounded-full contain align-middle"
                    :src="bank.logo"
                  /> -->
                </span>
                <span class=""> {{ option }}</span>
              </div>
            </div>
            <hr class="my-2 text-dividerColor" />
          </li>
        </ul>
      </div>
      <div
        v-if="!isSelect"
        @click="isSelect = !isSelect"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-500"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>

    <div v-if="error" class="text-xs font-medium text-error -mt-2 mb-2">
      {{ error }}
    </div>
    <div
      @click="isSelect = false"
      :class="!isSelect ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed } from "vue";
const props = defineProps({
  value: {
    default: "",
  },

  error: {
    type: [String, Boolean],
    default: false,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  label: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update"]);
let optionArray = reactive([]);

const searchQuery = ref("");
const isSelect = ref(false);
const optionData = ref([]);
optionData.value = props.options;

watch(
  isSelect,
  (val) => {
    console.log(val);
    if (val === true) {
      optionData.value = props.options;
    } else {
      optionData.value = props.options;
    }
  },
  { deep: true }
);
function searchFunction(e) {
  const name = e.target.value;
  let data;
  if (name.length) {
    if (e.key !== "Backspace") {
      data = props.options.filter((x) => {
        return x.toLowerCase().indexOf(name.toLowerCase()) > -1 ? x : "";
      });
    } else {
      data = props.options.filter((x) => {
        return x.toLowerCase().indexOf(searchQuery.value.toLowerCase()) > -1
          ? x
          : "";
      });
    }

    if (data.length) {
      optionData.value = data;
      return data;
    }
  } else {
    optionData.value = props.options;
  }
}

const initials = computed(() => {
  return (name) => {
    const arr = name.split(" ");
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return str;
  };
});

async function update(value) {
  if (optionArray.includes(value)) {
    optionArray = [...optionArray.filter((opt) => value !== opt)];
  } else {
    optionArray = [];
    optionArray.push(value);
    isSelect.value = false;
  }
  await nextTick();

  emit("update", optionArray[0]);
}
</script>

<style scoped>
.option-color {
  color: #000000;
  opacity: 0.8;
}
</style>
