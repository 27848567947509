<template>
  <easiModal
    v-if="isOpen"
    @close="closeModal"
    :isHeader="false"
    :isBorder="false"
    :lite="false"
    :persistence="true"
    :showCloseButton="false"
    apiDashboard
    rounded="3xl"
  >
    <form
      @submit.prevent="sendInvite"
      autocomplete="on"
      class="flex flex-col gap-y-3 pl-8 py-8 pr-2 relative"
    >
      <img
        class="w-7 h-7 cursor-pointer absolute right-10 top-6"
        src="@/assets/icons/x.svg"
        alt=""
        @click="closeModal"
      />

      <p class="font-medium text-xl text-grey mb-3 text-center">
        Invite New Developer
      </p>

      <div v-for="(invite, InviteIndex) in args" :key="InviteIndex">
        <easiTextInput
          v-model="invite.email"
          :disabled="loading"
          placeholder="Email Address"
          label="Email"
          type="email"
          inputmode="email"
          name="email"
          required
          autocom
        ></easiTextInput>

        <ApiInviteProduct
          :class="loading ? 'pointer-events-none' : ''"
          label="Select products user will have access to "
          @done="handleDone($event, InviteIndex)"
          :products="transformedProducts"
        />
        <div
          v-show="args.length > 1"
          :class="loading ? 'pointer-events-none' : ''"
          class="flex justify-center"
        >
          <span
            @click="removeInvite(InviteIndex)"
            class="bg-red-100 cursor-pointer rounded-lg py-1 px-2 inline-block"
          >
            <span class="flex gap-1 items-center text-xs">
              <img
                src="@/assets/icons/api-minus-circle.svg"
                class="h-4 w-4"
                alt=""
              />
              Remove
            </span>
          </span>
        </div>
      </div>

      <hr class="mb-4" />

      <div
        @click="addAnotherInvite"
        class="inline-block mx-auto cursor-pointer text-xs py-2 px-3 rounded-full border border-outlineGray"
      >
        <div class="flex gap-2">
          <img
            src="@/assets/icons/api-plus-circle.svg"
            class="h-4 w-4"
            alt=""
          />

          <p>Add another team member</p>
        </div>
      </div>

      <div
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <div class="w-full md:w-full">
          <easiButton type="submit" :loading="loading" class="w-full">
            Send Invite
          </easiButton>
        </div>
      </div>
    </form>
  </easiModal>

  <easiAlert
    v-if="thanksModal"
    @close="closeModal"
    noIcon
    :showDefaultTop="false"
  >
    <div class="px-6">
      <div class="flex justify-center pt-6">
        <span>
          <img src="@/assets/img/success.gif" class="h-32" alt="" />
        </span>
        <!-- <img class="w-32" :src="success" alt="" /> -->
      </div>
      <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
        Saved!
      </div>
      <p class="text-center w-11/12 mx-auto">
        Your invite has been successful sent.
      </p>
      <main class="w-full mt-6 space-y-4">
        <easiButton @click="closeModal" block>Okay </easiButton>
      </main>
    </div>
  </easiAlert>
</template>

<script setup>
import ApiInviteProduct from "@/components/ApiDashboard/ApiInviteProduct.vue";

import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },

  isEdit: {
    type: Boolean,
    default: false,
  },

  member: Object,
});

const emit = defineEmits(["close", "query"]);

const allProducts = computed(() => store.getAllproducts || []);
const transformedProducts = computed(() => {
  return allProducts.value.map((product) => {
    return {
      label: product.name,
      value: product._id,
    };
  });
});

const loading = ref(false);
const thanksModal = ref(false);

const args = ref([
  {
    email: "",
    products: [],
  },
]);

const validateInput = () => {
  let emailError = args.value.find((el) => !el.email || el.email.trim() == "");
  let productError = args.value.find((el) => !el.products.length);

  if (emailError) {
    toast.error("Email is required");
    throw new Error("Email is required");
  }
  if (productError) {
    toast.error("Please select at least one product");
    throw new Error("Email is required");
  }
};

const handleDone = (products, index) => {
  args.value[index].products = products;
  console.log(args.value);
};

const removeInvite = (index) => {
  // console.log(index);
  // console.log(args.value[index]);
  // console.log(args.value);
  // args.value.pop(index + 1);

  args.value = args.value.filter((el, i) => index !== i);
};

const sendInvite = async () => {
  validateInput();
  const payload = [...args.value];

  console.log(payload);
  loading.value = true;
  try {
    const res = await mutate({
      endpoint: "InviteDeveloper",
      data: {
        input: {
          invites: payload,
        },
      },
      service: "API",
    });

    if (res.success) {
      console.log(args.value);
      emit("close");
      thanksModal.value = true;
      clearFields();
      emit("query", false);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};

const addAnotherInvite = () => {
  args.value.push({
    email: "",
    products: [],
  });
};

const clearFields = () => {
  args.value = [
    {
      email: "",
      products: [],
    },
  ];
};

const closeModal = () => {
  emit("close");
  thanksModal.value = false;
  clearFields();
};
</script>

<style></style>
