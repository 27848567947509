<template>
  <easiModal
    v-if="isOpen"
    @close="closeModal"
    :isHeader="false"
    :isBorder="false"
    :lite="false"
    :showCloseButton="false"
    apiDashboard
    rounded="3xl"
  >
    <form
      @submit.prevent="updateProfile"
      autocomplete="on"
      class="flex flex-col gap-y-3 pl-8 py-8 pr-2"
    >
      <p class="font-medium text-xl text-grey mb-3 text-center">
        Edit Profile Information
      </p>
      <ApiUpload
        :loading="loading"
        label="Upload a profile picture"
        @fileUrl="uploadFile = $event"
      />

      <easiTextInput
        placeholder="First Name"
        label="First Name"
        type="text"
        name="firstName"
        required
        v-model="args.firstName"
        :error="errorRules.firstName"
        :disabled="loading"
      ></easiTextInput>

      <easiTextInput
        placeholder="Last Name"
        label="Last Name"
        type="text"
        name="lastName"
        required
        v-model="args.lastName"
        :error="errorRules.lastName"
        :disabled="loading"
      ></easiTextInput>

      <easiTextInput
        placeholder="Phone Number"
        label="Phone Number"
        type="tel"
        name="phoneNumber"
        v-model="args.phoneNumber"
        :error="errorRules.phoneNumber"
        :disabled="loading"
      ></easiTextInput>

      <easiTextInput
        placeholder="Job Title"
        label="Job title"
        type="text"
        name="title"
        :disabled="loading"
        v-model="args.jobTitle"
        :error="errorRules.jobTitle"
        autocom
      ></easiTextInput>
      <div class="mt-4">
        <easiSelectInput2
          :options="countryOptions"
          :value="args.country"
          @update="args.country = $event"
          :disabled="loading"
          placeholder="Country"
          apiDashboard
        ></easiSelectInput2>
      </div>

      <div
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="closeModal"
          class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 py-3"
          >Cancel</span
        >
        <div class="w-full md:w-full">
          <easiButton type="submit" :loading="loading" class="w-full">
            Save
          </easiButton>
        </div>
      </div>
    </form>
  </easiModal>

  <easiAlert
    v-if="thanksModal"
    @close="thanksModal = false"
    noIcon
    :showDefaultTop="false"
  >
    <div class="px-6">
      <div class="flex justify-center pt-6">
        <span>
          <img src="@/assets/img/success.gif" class="h-32" alt="" />
        </span>
        <!-- <img class="w-32" :src="success" alt="" /> -->
      </div>
      <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
        Saved!
      </div>
      <p class="text-center w-11/12 mx-auto">
        Your new profile information has been successful saved.
      </p>
      <main class="w-full mt-6 space-y-4">
        <easiButton @click="thanksModal = false" block>Okay </easiButton>
      </main>
    </div>
  </easiAlert>
</template>

<script setup>
import ApiUpload from "@/components/ApiDashboard/ApiUpload.vue";
import countryData from "@/utils/countries.json";

import { computed, reactive, ref, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const { uploadFileToServer } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const watchIsOpen = computed(() => props.isOpen);

const emit = defineEmits(["close", "query"]);

const userProfile = computed(() => store.apiProfile);

const loading = ref(false);
const thanksModal = ref(false);

const uploadFile = ref(null);
const args = reactive({
  country: null,
  employeeId: null,
  firstName: null,
  jobTitle: null,
  lastName: null,
  pfp: null,
  phoneNumber: null,
});

const errorRules = reactive({
  country: false,
  firstName: false,
  jobTitle: false,
  lastName: false,
  pfp: false,
  phoneNumber: false,
});

watch(
  watchIsOpen,
  () => {
    assignValues();
  },
  { immediate: true }
);

const countryOptions = computed(() => {
  return countryData
    ? countryData.map((count) => {
        return {
          label: count.name,
          value: count.name,
        };
      })
    : [];
});

function assignValues() {
  if (!userProfile.value) return;
  Object.keys(args).forEach((key) => {
    args[key] = userProfile.value.employee.profile[key];
  });
}

function validate() {
  if (args.firstName.trim() == "") {
    errorRules.firstName = "Please enter first name";
    return false;
  } else if (args.lastName.trim() == "") {
    errorRules.firstName = "Please enter first name";
    return false;
  } else {
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}

const updateProfile = async () => {
  if (!validate()) return;

  const payload = { ...args };
  payload.employeeId = userProfile.value.employee.profile._id;

  delete payload.phoneNumber;
  payload.phone = args.phoneNumber;

  try {
    loading.value = true;

    if (uploadFile.value) {
      payload.pfp = await uploadFileToServer(uploadFile.value);
    }

    const res = await mutate({
      endpoint: "UpdateProfile",
      data: { input: payload },
      service: "API",
    });

    if (res.success) {
      closeModal();
      thanksModal.value = true;

      queryCompanyAdmin();
      emit("query", false);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};

const closeModal = () => {
  emit("close");
};

const queryCompanyAdmin = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });
};
</script>

<style></style>
