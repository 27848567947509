<template>
  <div class="flex items-center gap-2 flex-wrap">
    <button
      v-for="(item, i) in filters"
      :key="`filters-${i}`"
      @click="$emit('close', item)"
      class="flex items-center gap-2 text-xs text-bodyDark bg-newAsh font-medium focus:outline-none border border-outlineGray hover:opacity-70 rounded-lg p-1"
    >
      <span> {{ item }} </span>
      <i class="text-newGray font-bold pi pi-times text-xs"></i>
    </button>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";

const props = defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["close"]);
const visibleData = ref([]);
const watchFilters = computed(() => props.filters);

visibleData.value = props.filters;

watch(watchFilters, () => {
  visibleData.value = props.filters;
});
</script>

<style></style>
