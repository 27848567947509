<template>
  <easiModal
    v-if="isOpen"
    @close="closeModal"
    :isHeader="false"
    :isBorder="false"
    :lite="false"
    :persistence="false"
    :showCloseButton="false"
    apiDashboard
    rounded="3xl"
  >
    <div class="p-8 relative">
      <div class="text-2xl font-medium text-center mb-6">
        {{ isEdit ? "Edit" : "Add" }} {{ title }}
      </div>

      <img
        class="w-7 h-7 cursor-pointer absolute right-10 top-6"
        src="@/assets/icons/x.svg"
        alt=""
        @click="closeModal"
      />
      <easiTextInput
        v-model="input"
        type="text"
        :disabled="loading.save || loading.remove"
        :placeholder="placeholder"
        :error="isError"
      ></easiTextInput>

      <div v-if="isEdit" class="flex flex-col gap-4 mt-8">
        <easiButton
          :loading="loading.save"
          :block="true"
          color="primary"
          class="rounded-full w-full md:w-52"
          @click="handleSubmit()"
        >
          <span class="text-sm"> Save Update </span>
        </easiButton>
        <easiButton
          :loading="loading.remove"
          :block="true"
          variant="outlined"
          class="rounded-full w-full md:w-52"
          @click="handleSubmit(true)"
        >
          <span class="text-sm"> Remove </span>
        </easiButton>
      </div>
      <easiButton
        v-else
        :loading="loading.save"
        :block="true"
        color="primary"
        class="rounded-full w-full md:w-52 mt-8"
        @click="handleSubmit()"
      >
        Add {{ title }}
      </easiButton>
    </div>
  </easiModal>
</template>

<script setup>
import { ref, reactive, computed, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;

const props = defineProps({
  type: {
    type: String,
    default: "webhook",
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  product: Object,
  value: String,
});

const emit = defineEmits(["close", "query"]);

const watchIsOpen = computed(() => props.isOpen);

const placeholder = computed(() => {
  return props.type == "webhook" ? "Webhook URL" : "IP Address";
});
const title = computed(() => {
  return props.type == "webhook" ? "Webhook URL" : "IP Address";
});

const loading = reactive({
  save: false,
  remove: false,
});

const input = ref("");

const payload = reactive({
  callbackURL: null,
  displayName: null,
  ipWhitelistAddress: null,
  logo: null,
  name: null,
  privateKey: null,
  productId: null,
  publicKey: null,
  webhookURL: null,
});

const isError = ref(false);

watch(watchIsOpen, () => {
  const { isOpen, isEdit, value, product } = props;

  //Clone the product object
  const obj = JSON.parse(JSON.stringify(product));
  Object.keys(payload).forEach((key) => {
    payload[key] = obj[key];
  });
  payload.productId = obj._id;

  //Populate the input if isEdit is true
  if (!isOpen && !isEdit) return;
  input.value = value || "";
});

const closeModal = () => {
  input.value = "";
  emit("close");
};

const handleSubmit = async (isRemoveItem = false) => {
  //Validate input field
  if (!input.value || input.value.trim() == "") {
    isError.value = `${title.value} is required`;
    return;
  }
  isError.value = false;

  const { isEdit, type, value } = props;

  if (type == "webhook") {
    payload.webhookURL = !isRemoveItem ? input.value : "";
  } else {
    if (isEdit || isRemoveItem) {
      //Filter out the old ipValue to be edited or removed
      payload.ipWhitelistAddress = payload.ipWhitelistAddress.filter(
        (ip) => ip.toLowerCase() !== value.toLowerCase()
      );
    }

    if (!isRemoveItem) {
      //Add the new ipValue
      payload.ipWhitelistAddress.push(input.value);
    }
  }

  console.log(payload);

  try {
    loading.remove = !!isRemoveItem;
    loading.save = !loading.remove;

    const res = await mutate({
      endpoint: "UpdateProduct",
      data: {
        input: payload,
      },
      service: "API",
    });

    if (res.success) {
      emit("query");
      closeModal();
    }

    toast.success(res.message);
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    loading.save = false;
    loading.remove = false;
  }
};
</script>

<style></style>
