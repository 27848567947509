<template>
  <div class="flex flex-col gap-4">
    <div
      class="md:hidden w-24 h-24 p-2 mx-auto border-2 border-dark-100 rounded-xl flex-shrink-0 overflow-hidden"
    >
      <img
        :src="
          kycInfo &&
          kycInfo.birthInformation &&
          kycInfo.birthInformation.passportPhoto
        "
        class="w-full h-full object-cover rounded-lg border-2 border-dark-100"
        alt=""
      />
    </div>
    <div
      class="bg-newGrey rounded-3xl p-4 flex items-end gap-4 text-xs md:text-sm"
    >
      <div
        class="hidden md:flex w-24 h-24 border-2 border-dark-100 rounded-xl flex-shrink-0 overflow-hidden"
      >
        <img
          :src="
            kycInfo &&
            kycInfo.birthInformation &&
            kycInfo.birthInformation.passportPhoto
          "
          class="w-full h-full object-cover rounded-xl"
          alt=""
        />
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 w-full h-full items-center"
      >
        <easiTextCard
          title="First Name"
          :value="
            companyAdmin &&
            companyAdmin.companyAdmin &&
            companyAdmin.companyAdmin.firstName
          "
        />
        <easiTextCard
          title="Last Name"
          :value="
            companyAdmin &&
            companyAdmin.companyAdmin &&
            companyAdmin.companyAdmin.lastName
          "
        />
      </div>
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 bg-newGrey rounded-2xl md:rounded-3xl p-2 md:p-4 items-start gap-2 md:gap-4 text-xs md:text-sm"
    >
      <easiTextCard
        title="Phone Number"
        :value="
          companyAdmin &&
          companyAdmin.companyAdmin &&
          companyAdmin.companyAdmin.phoneNumber
        "
      />
      <easiTextCard
        title="Email"
        :value="
          companyAdmin &&
          companyAdmin.companyAdmin &&
          companyAdmin.companyAdmin.email
        "
      />
      <div class="md:col-span-2">
        <easiTextCard
          title="Residential Address"
          :value="
            kycInfo &&
            kycInfo.addressInformation &&
            kycInfo.addressInformation.address
          "
        />
      </div>
      <easiTextCard
        title="State of Residence"
        :value="
          kycInfo &&
          kycInfo.addressInformation &&
          kycInfo.addressInformation.state
        "
      />
      <easiTextCard
        title="Country of Residence"
        :value="
          kycInfo &&
          kycInfo.addressInformation &&
          kycInfo.addressInformation.country
        "
      />
      <easiTextCard
        title="Gender"
        :value="
          kycInfo && kycInfo.birthInformation && kycInfo.birthInformation.gender
        "
      />
      <easiTextCard
        title="Date of birth"
        :value="
          kycInfo &&
          kycInfo.birthInformation &&
          kycInfo.birthInformation.dob &&
          formatDateString(kycInfo.birthInformation.dob, 'name')
        "
      />
      <div class="md:col-span-2">
        <easiTextCard
          title="Place of birth"
          :value="
            kycInfo &&
            kycInfo.birthInformation &&
            `${kycInfo.birthInformation.lga}, ${kycInfo.birthInformation.stateOfOrigin}, ${kycInfo.birthInformation.countryOfOrigin}`
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDateString } = helperFunctions;

const store = useDataStore();
const { query } = store;
const kycInfo = computed(
  () =>
    store.getKyc && store.getKyc.data && store.getKyc.data.personalInformation
);
const companyAdmin = computed(() => store.getCompanyAdmin);

const personalData = computed(() => {
  return {
    firstName: "Yogheneochukwu",
    lastName: "Obasanha",
    email: "ochukwo@myeazipay.com",
    phone: "08160178711",
    address: "29 Iyanfoworogi str. JInadu, Lagos state.",
    state: "Lagos",
    country: "Nigeria",
    gender: "Male",
    dateOfBirth: "06/09/91",
    placeOfBirth: "Alimosho, Lagos State, Nigeria.",
  };
});

const queryAdmin = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e.message);
  }
};

onMounted(async () => {
  await queryAdmin();
});
</script>

<style></style>
