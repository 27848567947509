<template>
  <div
    :class="!type ? 'pb-10' : 'py-10'"
    class="md:full w-full md:mx-auto text-left"
  >
    <div class="mb-6">
      <h2
        class="px-2 md:px-12 lg:px-8 xl:px-10 text-center text-headerText font-medium text-lg md:text-3xl"
      >
        {{ !type ? "Edit Account" : "Add Account" }}
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form @submit.prevent="submit" autocomplete="on" class="flex flex-col">
      <div class="w-full col-span-2 px-3 md:px-8 mb-4">
        <!-- <label for="employeeId" class="text-dark-800 text-sm font-medium"
          >Account Name</label
        > -->
        <div class="w-full col-span-2 md:col-span-1">
          <label for="banks" class="text-dark-800 text-sm font-medium"
            >Select Bank
          </label>
          <easiBankSelectInput
            :autoHeight="true"
            @update="bankName = $event"
            :value="bankName"
          >
          </easiBankSelectInput>
        </div>
      </div>
      <div
        class="w-full grid grid-cols-2 gap-x-4 gap-y-2 md:gap-y-5 px-3 md:px-8 lg:px-8 xl:px-8"
      >
        <div class="w-full col-span-2 md:col-span-1">
          <!-- <label for="employeeId" class="text-dark-800 text-sm font-medium"
          >Account Number</label
        > -->
          <easiTextInput
            placeholder="Account Number"
            v-model="args.accountNumber"
            type="text"
            name="employeeId"
            class="mt-1 mb-5"
          ></easiTextInput>
        </div>
        <div class="w-full col-span-2 md:col-span-1">
          <easiTextInput
            placeholder="Account Name"
            v-model="args.accountName"
            type="text"
            name="employeeId"
            class="mt-1 mb-2 capitalize"
          ></easiTextInput>

          <span
            v-if="accountWarning.length > 0 && args.accountName.length > 0"
            class="text-error font-medium text-xs capitalize"
            >{{ accountWarning }}</span
          >
        </div>
      </div>

      <div
        v-if="!type"
        class="w-full mt-7 mb-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="cancelOperation"
          class="w-10/12 md:w-64 cursor-pointer text-center bg-white rounded-full text-primary font-bold border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <div class="w-full md:w-auto">
          <easiButton
            :loading="loading"
            class="ml-7 md:ml-auto w-10/12 mx-auto md:w-64 focus:outline-none text-sm py-3 px-4 bg-primary rounded-full text-white"
          >
            Update
          </easiButton>
        </div>
      </div>

      <div
        v-else
        class="w-full mt-7 mb-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          :loading="saveLoad"
          type="button"
          @click="save"
          color="primary"
          class="rounded-full w-80 md:w-52"
          variant="outlined"
        >
          <span class="text-sm"> Save And Add More </span>
        </easiButton>
        <easiButton
          :loading="loading"
          type="submit"
          color="primary"
          class="rounded-full w-80 md:w-52"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>
    </form>
    <easiLoading v-if="verify" @close="verify = false">
      <template v-slot:heading>
        <h1>Verifying Bank information</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee's bank details updated successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import picIcon from "@/assets/icons/pic.svg";

import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

import nigerianBanks from "@/utils/banks";

import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";

const route = useRoute();
const store = useDataStore();
const { query, mutate } = store;

const { log } = helperFunctions;
const {
  allowance,
  listEmployees,
  listAllowance,
  getAllEmployees,
  getAllBanks,
} = storeToRefs(store);
const toast = useToast();
const props = defineProps({
  user: {
    type: String,
    default: "employee",
  },
  type: {
    type: Boolean,
    default: false,
  },
});
let updateSuccess = ref(false);
const profileArgsFromStore = computed(() => store.profileData);
let employees = reactive([]);

const argsArray = ref([]);
const args = reactive({
  bankName: "",
  bankCode: "",
  accountNumber: "",
  accountName: "",
});

let bankDetails = reactive([]);
const loading = ref(false);
const saveLoad = ref(false);

let bankName = ref("");
let accountWarning = ref("");

const emit = defineEmits(["cancel", "close"]);

function cancelOperation() {
  window.localStorage.removeItem("bankId");
  emit("cancel", false);
}

function close() {
  window.localStorage.removeItem("bankId");
  emit("close");
}

const accNum = computed(() => args.accountNumber);
const allAllowanceData = computed(() => listAllowance);
const allEmployees = computed(() => getAllEmployees);
let verify = ref(false);
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

///// Code for Employee Bank
const employeeId = route.params.id;

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
function getEmployeeById(id) {
  let employee = [];
  if (employeeId) {
    employee =
      getAllEmployees.value &&
      getAllEmployees.value.data.filter((emp) => emp._id === id);
  }
  return employee;
}

queryEmployees();
if (route.params.id) {
  employees = getEmployeeById(route.params.id);
}

///// Code for Admin Bank
const bankId = window.localStorage.getItem("bankId");

async function queryBankAccounts() {
  try {
    await query({
      endpoint: "FetchBankAccounts",
      service: "SETTINGS",
      storeKey: "allBanks",
    });
  } catch (e) {
    console.log(e);
  }
}
// console.log("BAnnks", getAllBanks.value);

function getBankById(id) {
  let bank = [];
  if (bankId) {
    bank = getAllBanks.value.filter((el) => el._id === id);
  }
  return bank;
}

const companyBank = getBankById(bankId);
// console.log("Bank", companyBank[0]);

onMounted(() => {
  assignValues();
  store.$patch({
    profileArr: [],
  });
});

function assignValues() {
  if (!props.type && props.user.toLocaleLowerCase() === "employee") {
    if (employees.length) {
      const payment = employees[0].payment;
      bankName.value = payment.bankName;
      args.accountNumber = payment.accountNumber;
      args.bankCode = payment.bankCode;
      args.bankName = payment.bankName;
    }
  } else {
    if (companyBank.length) {
      const payment = companyBank[0];
      bankName.value = payment.bankName;
      args.accountNumber = payment.accountNumber;
      args.bankCode = payment.bankCode;
      args.bankName = payment.bankName;
      args._id = payment._id;
    }
  }
}

function addMore(arg) {
  const payload = Object.assign({}, args);
  delete payload["accountName"];
  const newObj = Object.assign({}, payload);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });

  // toast.success("Details saved!");
}

async function save() {
  addMore(args);

  await createAdminBank(false);

  // Object.assign(args, resetObj(args, "args"));
}

async function submit() {
  console.log(props.user);
  if (props.user.toLocaleLowerCase() === "employee") {
    updateBank();
  } else {
    await createAdminBank(true);
  }
}

function updateBank() {
  loading.value = true;
  mutate({
    endpoint: "UpdateEmployeePayment",
    service: "EMP",
    data: { id: employeeId, payment: args },
  })
    .then((res) => {
      if (res.success) {
        loading.value = false;
        updateSuccess.value = true;
        setTimeout(() => {
          updateSuccess.value = false;
          window.location.reload();
        }, 500);
        // toast.success("Bank Record Updated Successfully");
      }
    })
    .catch((e) => {
      console.log(e);
      loading.value = false;
    });
}

async function createAdminBank(final) {
  if (final) {
    loading.value = true;
  } else {
    saveLoad.value = true;
  }

  let payload, endpoint;
  if (props.type) {
    if (final) {
      addMore(args);
    }

    //Add New Bank
    endpoint = "CreateBankAccount";
    payload = {
      input: [
        profileArgsFromStore.value[profileArgsFromStore.value.length - 1],
      ],
    };
  } else {
    // Edit Bank
    endpoint = "UpdateBankAccount";
    payload = {
      input: {
        accountNumber: args.accountNumber,
        bankName: args.bankName,
        bankCode: args.bankCode,
        bankAccountId: args._id,
      },
    };
  }

  log(payload, endpoint);
  try {
    let res = await mutate({
      endpoint,
      service: "SETTINGS",
      data: payload,
    });

    if (
      res &&
      res.data &&
      res.data.success &&
      res.data.errors &&
      !res.data.errors.length
    ) {
      await queryBankAccounts();
      if (final) {
        loading.value = false;
        updateSuccess.value = true;
        setTimeout(() => {
          updateSuccess.value = false;
          close();
          // window.location.reload();
        }, 500);
      } else {
        bankName.value = "";
        args.bankName = "";
        args.bankCode = "";
        args.accountNumber = "";
        args.accountName = "";
        saveLoad.value = false;
        toast.success("Details saved");
        saveLoad.value = false;
      }

      store.$patch({
        profileArr: [],
      });
      // toast.success("Bank Record Updated Successfully");
    } else {
      if (props.type) {
        toast.error(res.data.errors[0].message);
      } else {
        toast.error(res.message);
      }
      loading.value = false;
      saveLoad.value = false;
    }
  } catch (e) {
    console.log(e);
    loading.value = false;
    saveLoad.value = false;
  }
}

function verifyAccountName() {
  let endpointData = {
    accountNumber: args.accountNumber,
    bankCode: args.bankCode,
  };
  mutate({
    endpoint: "VerifyAccountDetails",
    data: { input: endpointData },
    service: "EMP",
  })
    .then((res) => {
      console.log(res);
      if (res.success === true) {
        args.accountName = res.data.data.account_name;
        args.accountName = args.accountName.toLowerCase();
        // updateBank();
        verify.value = false;
        if (props.user.toLocaleLowerCase() === "employee") {
          const first = employees[0].profile.firstName.toLowerCase().trim();
          const second = args.accountName.toLowerCase();
          const third = employees[0].profile.lastName.toLowerCase().trim();

          const secondArray = second.split(" ");
          const newArray = secondArray.filter((e) => e);
          const holder = [];
          for (let letter of newArray) {
            if (first.includes(letter) || third.includes(letter)) {
              holder.push("true");
            } else {
              holder.push("false");
            }
          }
          if (!holder.includes("true")) {
            accountWarning.value =
              "Bank name does not tally with employee name";
          }
        }

        toast.success("Account name verified.");
      } else if (!res.success) {
        args.accountNumber = "";
      }
      verify.value = false;
    })
    .catch((e) => {
      console.log(e);
      toast.error(e.message);
      verify.value = false;
    });
}

watch(accNum, (newval, oldval) => {
  if (args.accountNumber.length === 10) {
    verify.value = true;
    // await verifyAccountName();
    verifyAccountName();
  }
});
watch(
  bankName,
  (val) => {
    bankDetails = [];
    if (val && val.length) {
      bankDetails = nigerianBanks.filter((bank) => bank.name === val);
      for (let bank of bankDetails) {
        args.bankName = bank.name;
        args.bankCode = bank.code;
      }
      console.log(args);
    }
  },
  { immediate: true }
);
</script>

<style></style>
