<template>
  <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Document List</span
    >
    <easiButton
      v-if="!fromArchived"
      @click="showAdd = true"
      size="small"
      variant="outlined"
      color="primary"
      class="flex items-center gap-2 mt-2 md:mt-0 border-primary rounded-lg"
    >
      <img class="w-3" src="@/assets/icons/plus.svg" alt="" />
      <span class="text-sm">Add Document</span>
    </easiButton>
  </div>
  <hr class="text-dark-100" />
  <div
    v-if="documents.length"
    style="height: 500px"
    class="p-6 md:p-8 flex flex-col gap-6 overflow-y-auto"
  >
    <div
      v-for="(item, i) in documents"
      :key="i"
      class="flex gap-4 hover:bg-background-50 p-2"
    >
      <div class="w-16 md:w-20">
        <img
          v-if="documentType(item.documentUrl) == 'pdf'"
          src="@/assets/img/pdf.png"
          alt=""
          class="object-contain w-full"
        />
        <img
          v-if="
            documentType(item.documentUrl) == 'jpeg' ||
            documentType(item.documentUrl) == 'jpg' ||
            documentType(item.documentUrl) == 'png' ||
            documentType(item.documentUrl) == 'svg'
          "
          src="@/assets/img/jpeg.png"
          alt=""
          class="object-contain w-full"
        />
        <img
          v-if="
            documentType(item.documentUrl) == 'csv' ||
            documentType(item.documentUrl) == 'xlsx'
          "
          src="@/assets/img/csv.png"
          alt=""
          class="object-contain w-full"
        />
        <img
          v-if="documentType(item.documentUrl) == 'doc'"
          src="@/assets/img/doc.png"
          alt=""
          class="object-contain w-full"
        />
      </div>
      <div class="w-full flex items-start">
        <div class="w-full flex flex-col gap-2">
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Document Title</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.documentTitle
            }}</span>
          </div>
          <div
            v-if="item.documentUrl"
            class="flex flex-col w-full sm:w-10/12 md:6/12"
          >
            <span class="text-sm text-dark-500">Document Link</span>
            <a
              target="_blank"
              :href="item.documentUrl"
              class="text-sm italic text-primary-hover break-all"
              >{{ item.documentUrl }}</a
            >
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Date Added</span>
            <span class="text-base text-dark-800 font-medium">{{
              processDate(item.createdAt) || "N/A"
            }}</span>
          </div>
        </div>
        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateId(item)"
          size="small"
          variant="outlined"
          class="py-1"
        >
          <span class="flex items-center text-base gap-3">
            Edit
            <img class="w-4 mr-3" src="@/assets/icons/edit2.svg" alt="" />
          </span>
        </easiButton>
      </div>
    </div>
  </div>

  <div v-else class="my-8">
    <easiEmptyState text="No Document Added" />
  </div>

  <easiModal :isBorder="false" v-if="showAdd" @close="showAdd = false">
    <DocumentAdd
      @completed="showAdd = false"
      @complete="showAdd = false"
      type="add"
    />
  </easiModal>
</template>

<script setup>
import DocumentAdd from "@/components/Employee/Edit/Documents/Documents.vue";
import { reactive, ref, computed } from "@vue/reactivity";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

const { processDate } = helperFunctions;

const store = useDataStore();
const route = useRoute();

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const emit = defineEmits(["doc"]);

const showAdd = ref(false);
const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

const documents = computed(() => employee && employee.value.userDocuments);

const documentType = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

function updateId(obj) {
  emit("doc", obj);
}
</script>

<style></style>
