<template>
  <div class="w-full">
    <div class="mb-4" @click="startRecording">
      <div
        style="background-color: #f3f3f3"
        class="mt-1 pt-3 pb-2 px-2 w-80 rounded-xl border border-dashed border-grey cursor-pointer"
      >
        <div>
          <div class="text-xs sm:text-sm flex items-center justify-between">
            <span v-if="uploadFile">recorded-video.webm</span>
            <span v-else>Record Video</span>
            <img src="@/assets/icons/videoIcon.svg" alt="" />
          </div>
        </div>

        <FileLoader v-if="uploadFile" class="inline-flex w-full" />
      </div>
    </div>

    <easiModal
      :lite="true"
      :isBorder="false"
      :compliance="true"
      v-show="isRecording"
      @close="stopRecording"
    >
      <div
        style="height: calc(100vh - 250px)"
        class="w-full flex flex-col items-center h-auto overflow-y-scoll"
      >
        <video ref="video" autoplay></video>
        <!-- <button @click="startRecording" v-if="!isRecording">Start Recording</button> -->
        <div @click="stopRecording" class="flex justify-center mt-3">
          <div
            class="w-16 h-16 flex justify-center items-center rounded-full border-2 border-white"
          >
            <button
              class="focus:outline-none w-8 h-8 bg-error rounded-lg"
              v-if="isRecording"
            ></button>
          </div>
        </div>
      </div>
    </easiModal>
    <!-- <button @click="downloadRecording" v-if="recordedChunks.length">
      Download Recording
    </button> -->
  </div>
</template>

<script>
import FileLoader from "@/components/global/FileLoader.vue";

export default {
  emits: ["video-recorded"],
  components: { FileLoader },
  data() {
    return {
      mediaStream: null,
      mediaRecorder: null,
      uploadFile: null,
      recordedChunks: [],
      isRecording: false,
    };
  },

  methods: {
    async startRecording() {
      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          //   video: true,
          video: {
            width: { min: 1024, ideal: 1280, max: 1920 },
            height: { min: 576, ideal: 720, max: 1080 },
            facingMode: "user",
          },
          audio: true,
        });
        this.$refs.video.srcObject = this.mediaStream;

        this.mediaRecorder = new MediaRecorder(this.mediaStream);
        this.mediaRecorder.ondataavailable = this.onDataAvailable.bind(this);
        this.mediaRecorder.onstop = this.onRecordingStopped.bind(this); // Add this line

        await this.mediaRecorder.start();

        this.isRecording = true;
      } catch (error) {
        console.error("Error starting recording:", error);
      }
    },

    async stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        await this.mediaStream.getTracks().forEach((track) => track.stop());
        this.isRecording = false;
      }
    },

    downloadRecording() {
      const blob = new Blob(this.recordedChunks, { type: "video/webm" });
      console.log(blob, "myblob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "recorded-video.webm";
      a.click();
      window.URL.revokeObjectURL(url);
    },

    onDataAvailable(event) {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    },

    onRecordingStopped() {
      const blob = new Blob(this.recordedChunks, { type: "video/webm" });
      console.log(blob, "myemit");
      const myFile = new File([blob], "recorded-video.webm", {
        type: blob.type,
      });
      this.uploadFile = myFile;
      this.$emit("video-recorded", myFile);
      // Handle any post-recording logic here
      // The recordedChunks array should be populated and ready for use
    },
  },
};
</script>
