<template>
  <main>
    <div v-if="customized">
      <slot
        :data="{
          fileSize,
          fileName,
          file: uploadFile,
          clearFiles,
          onInput: () => {
            $refs.input.click();
          },
        }"
      />
    </div>
    <div v-else-if="classic">
      <div
        class="flex items-center justify-between gap-2 rounded-2xl border border-dashed border-dark-100 bg-newGrey3 px-4 py-2.5"
      >
        <div class="flex items-center gap-2">
          <div
            class="h-10 w-10 flex-shrink-0 rounded-full border-outline bg-white p-2"
          >
            <img
              :src="getFileType(null, true)"
              class="h-full w-full object-contain"
              alt=""
            />
          </div>
          <div class="grid grid-cols-1 gap-1 text-sm leading-4 text-dark-700">
            <span> {{ label }}</span>
            <span v-if="uploadFile" class="text-xs text-dark-600">{{
              fileSize
            }}</span>
          </div>
        </div>
        <div
          :class="[!uploadFile ? '' : 'h-9 w-9 items-center justify-center']"
          class="cursor-pointer flex flex-shrink-0 items-center gap-1 rounded-full bg-white px-4 py-2 text-sm text-primary"
          @click="!uploadFile ? $refs.input.click() : clearFiles()"
        >
          <span v-if="!uploadFile">Upload</span>
          <img
            v-if="!uploadFile"
            src="@/assets/icons/upload-icon2.svg"
            alt=""
          />
          <i v-else class="pi pi-times text-sm text-dark-800"></i>
        </div>
      </div>
      <div v-if="error || isError" class="mt-1 text-xs text-error">
        {{ isError ? message : error }}
      </div>
    </div>
    <div v-else>
      <div
        v-if="profile && !pill && !proPic && !dotted && !loop"
        class="flex flex-col gap-y-3"
      >
        <div class="flex items-center gap-x-4">
          <div
            :style="{
              backgroundImage: ' url(' + url + ')',
            }"
            class="w-14 h-16 border-2 bg-cover bg-no-repeat border-secondary rounded-md"
          >
            <!-- <img v-if="url" class="w-full bg-contain h-full" :src="url" alt="" /> -->
          </div>
          <div class="flex flex-col gap-3">
            <span class="text-dark-800 font-medium text-sm md:text-base"
              >Upload Picture
              <span class="text-xs mx-1 font-normal"
                >(JPG, PNG Only) (2mb Maximum)</span
              ></span
            >

            <div class="flex gap-x-4">
              <div
                @click="$refs.input.click()"
                :class="fullWidth ? 'w-9/12 bg-white text-center' : 'w-24'"
                class="rounded-full border py-3 px-3 border-primary cursor-pointer flex items-center justify-center gap-x-3"
              >
                <!-- <i class="fas fa-upload text-gray mr-4"></i> -->
                <img src="@/assets/icons/newUpload.svg" alt="" />

                <span class="text-primary text-sm font-bold text-left">
                  Upload</span
                >
              </div>
              <div
                v-if="url"
                @click="clearFiles"
                class="w-24 rounded-md border py-1 px-3 border-primary cursor-pointer flex items-center justify-center"
              >
                <i class="fas fa-times text-gray mr-4"></i>
                <span class="w-full text-dark-40 text-xs text-left">
                  Remove</span
                >
              </div>

              <input
                v-show="hide === false"
                ref="input"
                accept="image/png, image/gif, image/jpeg"
                @change="previewFiles($event)"
                type="file"
                class="custom-file-input"
              />
            </div>
            <div
              v-show="message.length > 0 && fileName.length === 0"
              class="flex justify-start gap-4 items-start"
            >
              <p class="text-xs font-medium text-error">{{ message }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="tiny ? 'bg-highlight p-4  rounded-lg' : ''"
        class="flex flex-col w-full"
        v-if="pill && !proPic && !profile && !dotted && !loop"
      >
        <div class="flex gap-x-4 items-center w-full">
          <span :class="tiny ? 'text-xs font-medium' : 'text-xs'" class="">{{
            info
          }}</span>

          <div
            @click="$refs.input.click()"
            :class="tiny ? 'bg-white' : ''"
            class="w-auto flex-none text-center rounded-full border border-primary bg-newLimeGreen py-2 px-2 cursor-pointer"
          >
            <span
              :class="tiny ? 'text-xs' : 'text-xs'"
              class="w-auto md:hidden text-primary text-center font-bold"
            >
              {{ label }}</span
            >
            <span
              :class="tiny ? 'text-xs' : 'text-sm'"
              class="w-auto md:block hidden text-primary text-center font-bold"
              >{{ label }}
            </span>
          </div>

          <input
            v-show="hide === false"
            ref="input"
            accept="application/msword, application/vnd.ms-excel, application/csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
            @change="previewFiles($event)"
            type="file"
            class="custom-file-input"
          />
        </div>
        <div
          v-show="fileName.length > 0"
          class="flex justify-center gap-1 items-center self-center mt-2"
        >
          <div class="flex-none w-6 justify-center self-center">
            <img :src="icon" alt="" />
          </div>

          <p class="text-xs flex-none text-dark-900 w-4/12 break-all">
            {{ fileName }}

            <span class="text-xs flex-none text-gray ml-2">
              {{ fileSize }}
            </span>
          </p>
          <div
            v-if="url"
            @click="clearFiles"
            class="flex-none w-24 rounded-none border py-0.5 px-3 border-none cursor-pointer"
          >
            <span class="w-full text-dark-40 text-xs text-left">
              <i class="fas fa-times text-gray mr-4"></i>Remove</span
            >
          </div>
        </div>

        <div v-show="message.length > 0 && fileName.length === 0" class="flex justify-center gap-4 items-center">
          <p class="text-xs font-medium text-error">{{ message }}</p>
        </div>
      </div>
      <div
        v-if="!pill && !profile && !proPic && !dotted && !loop"
        class="flex justify-center items-center gap-4 mb-8 w-full"
      >
        <div class="flex flex-col justify-center items-center gap-y-3">
          <div class="flex flex-col gap-1">
            <span
              :class="hideType ? 'hidden' : ''"
              class="md:text-base text-sm text-headerText font-normal text-center mx-1"
              >(JPG, PNG and PDF Only) (2mb Maximum)</span
            >
          </div>

          <div class="flex gap-x-4 items-center">
            <div
              @click="$refs.input.click()"
              class="w-56 mt-2 text-center rounded-full border border-primary py-2 px-4 cursor-pointer"
            >
              <span
                class="w-full flex items-center gap-3 justify-center text-primary text-sm text-center font-medium"
              >
                <img class="w-6" src="@/assets/icons/upload-icon.svg" alt="" />

                <span>Choose File</span>
              </span>
            </div>
            <div
              v-if="url"
              @click="clearFiles"
              class="w-24 rounded-none border py-0.5 px-3 border-none cursor-pointer"
            >
              <span class="w-full text-dark-40 text-xs text-left">
                <i class="fas fa-times text-gray mr-4"></i>Remove</span
              >
            </div>
            <input
              v-show="hide === false"
              ref="input"
              accept="application/msword, application/vnd.ms-excel, application/csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
              @change="previewFiles($event)"
              type="file"
              class="custom-file-input"
            />
          </div>
          <div
            v-show="fileName.length > 0"
            class="flex justify-center gap-4 items-center mt-2"
          >
            <div class="w-6 justify-center self-center">
              <img :src="icon" alt="" />
            </div>

            <p class="text-xs text-dark-900">{{ fileName }}</p>
            <span class="text-xs text-gray">{{ fileSize }}</span>
          </div>

          <div
            v-show="message.length > 0 && fileName.length === 0"
            class="flex justify-center gap-4 items-center"
          >
            <p class="text-xs font-medium text-error">{{ message }}</p>
          </div>
        </div>
      </div>

      <div v-if="proPic && !pill && !profile && !dotted && !loop">
        <div
          @click="$refs.input.click()"
          class="cursor-pointer border rounded-lg md:rounded-lg p-4 md:px-6 flex items-center text-white gap-2 md:gap-4"
        >
          <img class="w-4" src="@/assets/icons/upload-white.svg" alt="" />
          <span class="hidden md:inline font-medium text-base text-white"
            >Upload</span
          >
        </div>

        <input
          v-show="hide === false"
          ref="input"
          accept="application/msword, application/vnd.ms-excel, application/csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
          @change="previewFiles($event)"
          type="file"
          class="custom-file-input"
        />
      </div>

      <div v-if="dotted && !profile && !pill && !proPic && !loop">
        <span class="text-xs leading-none mb-2 text-dark-200">{{ label }}</span>
        <div
          @click="$refs.input.click()"
          style="background-color: #f3f3f3"
          class="mt-1 py-3 px-2 rounded-xl border border-dashed border-grey cursor-pointer"
        >
          <div v-if="!$slots.dotted">
            <div
              v-if="
                (uploadFile != null && !message.length) ||
                (imageLink && imageLink.length)
              "
              class="flex items-center gap-3"
            >
              <span class="truncate text-dark-800">{{
                fileName ? fileName : imageLink
              }}</span>
              <div
                @click="clearFiles"
                class="rounded-full w-4 h-4 border border-error cursor-pointer flex items-center justify-center gap-x-3 rembg"
              >
                <img src="@/assets/icons/remove-file.svg" alt="" />
                <!-- <i class="fas fa-times text-error mr-4"></i> -->
              </div>
            </div>
            <div
              v-else-if="!uploadFile || !fileName || fileName.length == 0"
              class="gap-x-4 gap-y-1 flex flex-wrap items-center justify-center"
            >
              <img src="@/assets/icons/grey-upload.svg" alt="" />
              <span class="text-dark-800">Choose File</span>
            </div>
          </div>
          <div v-else>
            <slot name="dotted" />
          </div>
          <div
            v-if="
              (uploadFile != null && !message.length) ||
              (imageLink && imageLink.length)
            "
          >
            <FileLoader class="inline-flex w-full" />
          </div>
        </div>

        <input
          v-show="hide === false"
          ref="input"
          accept="application/msword, application/vnd.ms-excel, application/csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,
            text/plain, application/pdf, image/*"
          @change="previewFiles($event)"
          type="file"
          :capture="capture"
          class="custom-file-input"
        />
      </div>

      <div
        v-if="loop && !dotted && !profile && !pill && !proPic"
        class="flex gap-x-4"
      >
        <div
          @click="$refs.input.click()"
          v-if="(!url && !imageLink) || (status && status.length)"
          class="rounded-full md:h-auto w-8 h-8 md:w-auto border-dashed md:border-solid py-2 md:px-3 md:border-primary border-info-light upbg cursor-pointer flex items-center justify-center gap-x-3"
          :class="showBorder ? 'border' : 'border-none'"
        >
          <!-- <i class="fas fa-upload text-gray mr-4"></i> -->
          <div v-if="status.length" class="flex items-center gap-x-4">
            <img
              v-show="status === 'verified'"
              src="@/assets/icons/upload-verified.svg"
              alt=""
            />
            <img
              v-show="status === 'pending'"
              src="@/assets/icons/pending-upload.svg"
              alt=""
            />
          </div>

          <img
            v-if="!status.length"
            class="hidden md:block"
            src="@/assets/icons/newUpload.svg"
            alt=""
          />
          <span
            v-if="!status.length"
            class="hidden md:block text-primary text-sm font-medium text-left"
          >
            {{ loopTitle }}</span
          >

          <img
            v-if="!status.length"
            class="md:hidden"
            src="@/assets/icons/mobile-upload.svg"
            alt=""
          />
        </div>
        <div
          v-else
          @click="clearFiles"
          class="rounded-full md:w-32 md:h-auto h-8 w-8 border py-2 md:px-3 border-error cursor-pointer flex items-center justify-center gap-x-3 rembg"
        >
          <span
            class="hidden md:block w-full text-error text-sm font-medium text-center"
          >
            Remove</span
          >
          <img src="@/assets/icons/remove-file.svg" alt="" />
          <!-- <i class="fas fa-times text-error mr-4"></i> -->
        </div>
      </div>
    </div>
    <input
      v-show="hide === false"
      ref="input"
      accept="application/msword, application/vnd.ms-excel, application/csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
      @change="previewFiles($event)"
      type="file"
      class="custom-file-input"
    />
  </main>
</template>

<script setup>
// import * as XLSX from "xlsx/xlsx.mjs";
// import greyIcon from "@/assets/icons/grey-upload.svg"

import axios from "axios";
import { AUTH_CONTEXT } from "@/api/api";

import picIcon from "@/assets/icons/pic.svg";
import pdf from "@/assets/icons/pdf-file.svg";
import imageIcon from "@/assets/icons/image.svg";
import msword from "@/assets/icons/microsoft-word.svg";
import ViewDocuments from "@/components/Employee/View/Documents/Documents";

import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed, onMounted, onUnmounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import FileLoader from "@/components/global/FileLoader.vue";

const { resetObj } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();

const route = useRoute();

const props = defineProps({
  profile: {
    type: Boolean,
    default: false,
  },
  customized: {
    type: Boolean,
    default: false,
  },

  classic: {
    type: Boolean,
    default: false,
  },

  clear: {
    type: Boolean,
    default: false,
  },
  hideType: {
    type: Boolean,
    default: false,
  },

  loopTitle: {
    type: String,
    default: "Upload",
  },

  loop: {
    type: Boolean,
    default: false,
  },
  dotted: {
    type: Boolean,
    default: false,
  },
  dottedNew: {
    type: Boolean,
    default: false,
  },
  listInfo: {
    type: Array,
    default: [],
  },
  infoHeader: {
    type: String,
    default: "",
  },
  showBorder: {
    type: Boolean,
    default: true,
  },
  status: {
    type: String,
    default: "",
  },
  capture: {
    type: String,
    default: undefined,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  pill: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
  proPic: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  allowedTypes: {
    default: ["jpg", "pdf"],
  },
  info: {
    type: String,
    default: "",
  },
  imageLink: {
    type: [String, Array],
  },

  error: {
    type: [Boolean, String],
    default: false,
  },
});

const emit = defineEmits(["fileUrl"]);

let updateSuccess = ref(false);
const uploadFile = ref(null);

let args = reactive({
  _id: "",
  companyId: "",
  employeeId: "",
  documentTitle: "",
  documentUrl: "",
});

const loading = ref({
  add: false,
  edit: false,
});

const icon = ref(null);
const hide = ref(true);
let message = ref("");
let isError = ref(false);
let fileName = ref("");
let fileSize = ref("");
let url = ref("");
const argsArray = ref([]);
const input = ref(null);
defineExpose({ input });

const imgLink = computed(() => props.imageLink);

watch(
  imgLink,
  (newVal, oldVal) => {
    url.value = props.imageLink;
  },
  { deep: true }
);

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function getFileType(typeofFile, useProp = false) {
  const ext = useProp ? props.allowedTypes[0] : typeofFile.split("/").pop();

  if (ext === "png" || ext === "jpg" || ext === "jpeg") {
    return imageIcon;
  } else if (ext === "msword") {
    return msword;
  } else if (ext === "pdf") {
    return pdf;
  }
}

const clearFiles = () => {
  input.value.value = null;
  url.value = "";
  uploadFile.value = null;
  fileName.value = "";
  fileSize.value = "";
  emit("fileUrl", uploadFile.value);
};

const propClear = computed(() => props.clear);

watch(propClear, (oldval, newVal) => {
  if (propClear.value) {
    console.log(propClear, "CLEARED");
    clearFiles();
  }
});
// let url = ref("");
function previewFiles(e) {
  //   console.log(e.target.files[0], "FILE TARGET");
  isError.value = false;
  uploadFile.value = null;
  message.value = "";
  const file = e.target.files[0];
  uploadFile.value = file;
  console.log(file.type, "TYPEOFFILE");

  const allowedTypes = [
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
    "text/plain",
    "application/pdf",
    "image/png",
    "image/gif",
    "image/webp",
    "image/jpeg",
    "application/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  if (!allowedTypes.includes(file.type)) {
    message.value = "File type is wrong!!";
    isError.value = true;
    throw new Error(message.value);
  }
  if (file.size > 10000000) {
    toast.error("Too large, max size allowed is 10MB");
    message.value = "Too large, max size allowed is 10MB";
    isError.value = true;
    throw new Error(message.value);
  }

  icon.value = getFileType(file.type);
  const { name, size } = file;
  fileName.value = name;
  args.documentTitle = name;

  fileSize.value = formatBytes(size);
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    // args.documentUrl = e.target.result;
  };
  reader.readAsDataURL(file);
  emit("fileUrl", uploadFile.value);

  // url = URL.createObjectURL(file);
  // uploadToServer();
}
onMounted(() => {
  fileName.value = "";
  fileSize.value = "";
  uploadFile.value = null;
  url.value = props.imageLink;
});

const hoveredType = ref("");
function setHoveredType(type) {
  hoveredType.value = type;
}
function clearHoveredType() {
  hoveredType.value = null;
}
function isHovered(type) {
  return hoveredType.value === type;
}
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* or clip */
  max-width: 30ch; /* or any other value you prefer */
}

.dots {
  border: 1px dotted #d4d4d4;
}
.upbg {
  background: transparent;
}

.rembg {
  background: transparent;
}
@media (max-width: 768px) {
  .upbg {
    background: rgba(47, 128, 237, 0.12);
  }

  .rembg {
    border-radius: 16px;
    border: 0.674px solid #e7e8e7;
    background: rgba(33, 150, 83, 0.06);
  }
}
</style>
