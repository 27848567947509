<template>
  <div class="px-4 sm:px-28 pt-8">
    <div
      class="flex gap-2 mb-10 font-medium cursor-pointer"
      @click="$router.push('/developers/dashboard/api-keys/product')"
    >
      <img src="@/assets/icons/arrow-small-left.svg" alt="" />
      Back to Product
    </div>

    <div v-if="apiProduct" class="border border-outlineGray p-2 sm:p-8 mt-10">
      <div class="flex flex-wrap gap-4 sm:gap-0 items-center justify-between">
        <div class="flex gap-2 items-center">
          <p class="text-2xl font-bold text-dark-800">{{ apiProduct.name }}</p>
          <div
            class="rounded-md px-3 py-1"
            :class="
              apiProduct.privateKey
                ? 'bg-lighterGreen text-primary'
                : 'bg-lightSecondary text-secondary'
            "
          >
            {{ apiProduct.privateKey ? "Live" : "Inactive" }}
          </div>
        </div>
        <div
          v-if="userProfile && userProfile.permission.includes('EDIT_PRODUCT')"
          class="flex gap-6"
        >
          <span
            @click="editProduct"
            class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-7 py-3"
            >Edit</span
          >
          <easiButton
            v-if="apiProduct.privateKey"
            type="submit"
            :loading="genereateKeyLoading"
            class="w-full"
            @click="reGenerateKeys"
          >
            Regenerate Live Api Keys
          </easiButton>

          <easiButton
            v-else
            type="submit"
            :loading="genereateKeyLoading"
            class="w-full"
            @click="generateKeys"
          >
            Generate Live Api Keys
          </easiButton>
        </div>
      </div>
      <p class="mt-6 text-sm text-grey">
        We prioritise your account security. Secret keys can only be viewed and
        downloaded once. Replace old keys with new ones immediately for your
        account's safety
      </p>
      <hr class="my-6" />
      <div class="p-2 sm:p-4">
        <div class="bg-newAsh cursor-pointer p-4 rounded-2xl">
          <div class="grid grid-cols-1 sm:grid-cols-2">
            <div class="flex gap-4">
              <img
                :src="apiProduct.logo || dummyImage"
                class="rounded-lg w-14 h-14 object-cover flex-shrink-0"
                alt=""
              />
              <div class="flex flex-col justify-between">
                <p class="font-medium text-lg text-dark-800">
                  {{ apiProduct.displayName }}
                </p>
                <div class="flex gap-2">
                  <p class="text-dark-800 text-sm">Created on</p>
                  <p class="text-dark-200 text-sm">
                    {{ formatDate(apiProduct.createdAt) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex sm:flex-col gap-4 sm:gap-0 sm:justify-between">
              <p class="font-medium text-lg text-dark-800">Account Type</p>
              <p class="text-dark-800 text-lg">{{ accountType }}</p>
            </div>
          </div>
        </div>
        <hr class="my-6" />
        <!-- COLLABORATORS //// -->
        <div>
          <p class="text-newGray text-lg font-bold mb-4">Collaborators</p>
          <div v-if="collaborators.length" class="flex flex-wrap gap-6">
            <div
              v-for="(dev, i) in collaborators"
              :key="i"
              class="border border-outlineGray rounded-lg p-2 flex gap-2"
            >
              <div
                class="w-6 h-6 flex items-center justify-center text-xs rounded-full bg-mint text-primary"
              >
                <img
                  v-if="dev.pfp"
                  class="w-full h-full object-cover"
                  :src="dev.pfp"
                  :alt="dev.name"
                />
                <div v-else class="p-1">
                  {{ getInitials(dev.name.trim() || "Dev") }}
                </div>
              </div>
              <p>{{ dev.name.trim() || "Dev" }}</p>
            </div>
          </div>
        </div>
        <hr class="my-6" />
        <!-- Webhook and IP -->
        <div class="xl:grid grid-cols-12 gap-5">
          <div class="col-span-3 flex flex-col gap-2 break-all">
            <div class="flex gap-2 mb-3">
              <p class="font-bold text-lg">Webhook</p>
              <div
                v-if="
                  userProfile && userProfile.permission.includes('EDIT_PRODUCT')
                "
                @click="addWebhookIPHandler('webhook')"
                class="bg-mint flex-shrink-0 p-2 rounded-2xl px-3 cursor-pointer"
              >
                <img class="w-3 h-3" src="@/assets/icons/ipAdd.svg" alt="" />
              </div>
            </div>

            <HoverText
              v-if="apiProduct.webhookURL"
              @click="editWebhookIPHandler('webhook', apiProduct.webhookURL)"
              :text="apiProduct.webhookURL"
            />
          </div>
          <div class="col-span-9 flex flex-col gap-2">
            <div class="flex gap-2 mb-3">
              <p class="font-bold text-lg">IP Whitelist Address</p>
              <div
                v-if="
                  userProfile && userProfile.permission.includes('EDIT_PRODUCT')
                "
                @click="addWebhookIPHandler('ip')"
                class="bg-mint flex-shrink-0 p-2 rounded-2xl px-3 cursor-pointer"
              >
                <img class="w-3 h-3" src="@/assets/icons/ipAdd.svg" alt="" />
              </div>
            </div>
            <div class="flex gap-x-4 gap-y-2 flex-wrap">
              <HoverText
                v-for="(address, addressIndex) in apiProduct.ipWhitelistAddress"
                :key="addressIndex"
                :text="address"
                @click="editWebhookIPHandler('ip', address)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="apiProduct" class="border border-outlineGray p-2 sm:p-8 mt-10">
      <div class="flex justify-between">
        <p class="text-lg font-bold text-dark-800">Transactions</p>
        <p
          class="text-lg font-bold text-dark-800 cursor-pointer"
          @click="$router.push('/developers/dashboard/product/viewAll')"
        >
          View All
        </p>
      </div>
      <hr class="mt-6 mb-2" />

      <ApiDashboardTable />
    </div>
    <div
      v-if="!apiProduct && !loading"
      class="border border-outlineGray p-8 mt-10 text-center text-semibold"
    >
      No Product found
    </div>

    <AddEditProduct
      :setStep="step"
      :setTitle="title"
      isEdit
      :isOpen="isOpen"
      @toggleModal="
        isOpen = $event;
        queryProductByID();
      "
      :data="apiProduct"
      @query="queryProductByID"
    />

    <WebHookAndIPModal
      :isOpen="addWebhookIPModal"
      :type="webhookIPType"
      :product="apiProduct"
      @close="addWebhookIPModal = false"
      @query="queryProductByID"
    />

    <WebHookAndIPModal
      isEdit
      :isOpen="editWebhookIPModal"
      :type="webhookIPType"
      :product="apiProduct"
      :value="webhookIPValue"
      @close="editWebhookIPModal = false"
      @query="queryProductByID"
    />
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import dummyImage from "@/assets/img/greenLogo.png";

import ApiDashboardTable from "@/components/ApiDashboard/ApiDashboardTable.vue";
import AddEditProduct from "@/components/ApiDashboard/AddEditProduct.vue";
import HoverText from "@/components/ApiDashboard/HoverText.vue";
import WebHookAndIPModal from "../../../components/ApiDashboard/WebHookAndIPModal.vue";

import { ref, reactive, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDate } = helperFunctions;
const toast = useToast();
const route = useRoute();
const store = useDataStore();
const { query, mutate } = store;

const addWebhookIPModal = ref(false);
const editWebhookIPModal = ref(false);
const webhookIPType = ref("webhook");
const webhookIPValue = ref(null);

const isOpen = ref(false);
const loading = ref(false);
const genereateKeyLoading = ref(false);

const step = ref(null);
const title = ref(null);
const apiProduct = ref(null);
const apiCalls = ref([]);
const collaborators = ref([]);

const companyData = computed(() => store.getCompanyAdmin);
const userProfile = computed(() => store.apiProfile);

const accountType = computed(() => {
  const admin = companyData.value && companyData.value.companyAdmin;
  const { registrationType } = admin || {};
  return registrationType == "COMPANY" ? "BUSINESS" : "INDIVIDUAL";
});

onMounted(async () => {
  await queryProductByID();
});

const getInitials = (name) => {
  if (name && name.length) {
    const arr = name.split(" ");
    const first = arr[0] || "";
    const second = arr[1] || "";
    let str = `${first && first[0]}${second && second[0]}`;
    return str;
  } else {
    return "N/A";
  }
};

const addWebhookIPHandler = (type) => {
  webhookIPType.value = type;
  addWebhookIPModal.value = true;
};

const editWebhookIPHandler = (type, value) => {
  webhookIPType.value = type;
  webhookIPValue.value = value;
  editWebhookIPModal.value = true;
};

const editProduct = () => {
  step.value = 1;
  title.value = null;
  isOpen.value = true;
};

const reGenerateKeys = async () => {
  const otpSent = await sendOTP();
  if (!otpSent) return;
  title.value = "Regenerate New API";
  step.value = 4;
  isOpen.value = true;
};

const generateKeys = async () => {
  const otpSent = await sendOTP();
  if (!otpSent) return;
  step.value = 2;
  isOpen.value = true;
};

const sendOTP = async () => {
  const { companyAdmin } = companyData.value || {};
  if (!companyAdmin) return;

  const payload = {
    channel: "EMAIL",
    value: companyAdmin.email,
    type: "GENERATE_API_KEY",
  };
  try {
    genereateKeyLoading.value = true;
    const res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      localStorage.setItem("API_OTP_REF", res.data.reference);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    toast.error(error.message);
    return false;
  } finally {
    genereateKeyLoading.value = false;
  }
};

const queryProductByID = async () => {
  const { id } = route.params;

  try {
    loading.value = true;

    const res = await query({
      endpoint: "GetACompanyProduct",
      payload: {
        input: {
          productId: id,
        },
      },
      service: "API",
    });

    if (res.success) {
      const { calls, developers, product } = res.data;
      apiProduct.value = product;
      apiCalls.value = calls;
      collaborators.value = developers;
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
