<template>
  <div class="cursor-pointer" @click="emit('change', !value)">
    <img v-if="value" src="@/assets/icons/check-active-n.svg" alt="" />
    <img v-else src="@/assets/icons/check-null-n.svg" alt="" />
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["change"]);
</script>

<style></style>
