<template>
  <main v-if="startTour">
    <div
      style="
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      "
      class="inset-0 w-full overflow-y-auto py-16 relative"
    >
      <div
        v-if="showPromo"
        style="border-radius: 56px; z-index: 400"
        class="md:w-full max-w-2xl p-10 mx-auto pb-4 shadow-xs bg-white text-center"
      >
        <p class="text-dark-800 font-bold text-2xl">
          Which of these promo best suit your needs?
        </p>
        <p class="text-promoGrey mb-6 mt-1">Kindly select a maximum of 3</p>

        <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div
            v-for="(promo, promoIndex) in promos"
            :key="promoIndex"
            class="px-2 md:px-4 relative py-2 md:py-3 cursor-pointer flex items-center justify-center rounded-2xl bg-newAsh text-grey"
            :class="
              selectedPromos.includes(promo)
                ? 'bg-newLightGreen border border-primary'
                : 'border border-newAsh'
            "
            @click="toggleValueInArray(promo)"
          >
            <img
              v-if="selectedPromos.includes(promo)"
              class="w-3 h-3 cursor-pointer absolute right-1 top-1 md:right-3 md:top-3"
              src="@/assets/img/activePromoBadge.svg"
              alt=""
            />
            {{ promo }}
          </div>
        </div>
        <div class="flex justify-center items-center gap-4 my-10">
          <easiButton variant="outlined" class="bg-white" @click="handleSkip">
            <span class="text-sm text-dark-800">
              <span class="text-sm px-4"> Skip </span>
            </span>
          </easiButton>
          <easiButton class="px-6" @click="handleSubmit" 
          
          :disabled="selectedPromos.length<3"
          :loading="isLoading">
            <span class="text-sm px-14"> Submit </span>
          </easiButton>
        </div>
      </div>
      <div
        v-else
        :class="[
          level === 0 ? 'max-w-xl' : 'max-w-lg',
          level == 1 ? 'mt-60' : '',
          level == 2 ? ' -mt-10 md:-mt-5' : '',
        ]"
        class="md:w-full w-11/12 mx-auto pb-4 shadow-xs bg-primary addTourWave rounded-3xl rounded-br-none"
      >
        <div v-if="level === 0" class="float-right mt-4 mx-4">
          <i
            @click="close"
            class="far fa-times-circle text-mint cursor-pointer text-lg"
          />
        </div>

        <main>
          <div class="p-4 md:p-6 lg:p-8 lg:pb-6 text-white text-left">
            <div class="items-center cursor-pointer flex gap-2 md:gap-4 mb-8">
              <span
                class="w-12 h-12 text-sm inline-flex items-center justify-center rounded-full"
              >
                <img
                  alt="..."
                  class="w-full rounded-full align-middle border-none shadow-lg"
                  :src="image"
                />
              </span>
              <div class="flex flex-col text-left">
                <span class="font-bold capitalize">Toyin From Eazipay</span>
                <span class="text-xs font-medium -mt-1">Customer Service</span>
              </div>
            </div>

            <!-- ///// Start Tour //// -->
            <div v-if="level === 0" class="flex flex-col gap-4 md:gap-6">
              <h3 class="text-2xl md:text-3xl font-bold no-wrap">
                Welcome To Eazipay,
                {{
                  companyData
                    ? companyData.companyAdmin &&
                      companyData.companyAdmin.firstName
                    : ""
                }}
                👋
              </h3>
              <p class="leading-7 text-base justify">
                Take a tour of the key features, or have a look around yourself,
                it’s up to you. You can take this tour from the... menu later if
                you don’t want to right now.
              </p>
            </div>

            <!-- /// Fund Wallet //// -->
            <div v-if="level === 1" class="flex flex-col text-lg">
              <p>To fund your wallet, click this button.</p>
            </div>

            <!-- /// Services //// -->
            <div v-if="level === 2" class="flex flex-col text-lg">
              <p>
                Select any of these to add employee, run payroll, apply for
                quick loan and manage your book keeping.
              </p>
            </div>

            <!-- /// Services //// -->
            <div v-if="level === 3" class="flex flex-col text-lg">
              <p>
                Navigate through all the fuctions in this side bar. This include
                creating recipt, invoice, loans, payroll, compliance, employee
                management and your business and personal setings.
              </p>
            </div>

            <!-- /// Services //// -->
            <div v-if="level === 4" class="flex flex-col text-lg">
              <p>
                Check your inflow and outflow expenses by clicking on these drop
                downs. You can also customise your information
              </p>
            </div>

            <!-- /////// Action /////// -->
            <div class="flex justify-between items-center mt-8">
              <span v-if="level > 0">{{ level }} / 4</span>
              <div class="flex gap-4 items-center">
                <easiButton @click="close" variant="outlined" color="mint">
                  <span class="text-mint text-xs">Skip</span></easiButton
                >
                <easiButton @click="nextFN" class="text-primary" color="mint">{{
                  buttonText
                }}</easiButton>
              </div>
            </div>
          </div>

          <!-- <slot /> -->
        </main>
      </div>
      <div
        style="
          z-index: -1;
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #032821;
          opacity: 0.6;
        "
      ></div>
    </div>
  </main>
</template>

<script setup>
import image from "@/assets/img/toyin.png";
import { ref, onUnmounted } from "vue";
import { computed, watch } from "@vue/runtime-core";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();

const store = useDataStore();
const { mutate } = useDataStore();

const level = ref(0);
const companyData = computed(() => store.getCompanyAdmin);

const showPromo = ref(true);
const promos = ref([
  "loans",
  "Discount on business tools",
  "Free compliance remittance ",
  "Grants",
  "Business advisory",
  "Tax relief",
  "Cash back",
  "Salary cover",
  "Rent cover",
  "Electricity cover",
]);
const isLoading = ref(false);
const handleSkip = () => {
  showPromo.value = false;
};
const handleSubmit = async () => {
  try {
    if (selectedPromos.value.length < 3) {
     return toast.error("Please select at least three (3)");
    }
    const { company } = companyData.value;

    isLoading.value = true;
    const res = await mutate({
      endpoint: "SavePromoNeeds",
      data: {
        input: {
          needs: selectedPromos.value,
          companyId: company._id,
        },
      },
      service: "AUTH",
    });
    if (res.success) {
      showPromo.value = false;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    isLoading.value = false;
  }
};
const selectedPromos = ref([]);
function toggleValueInArray(value) {
  const index = selectedPromos.value.indexOf(value); // Check if value is present in array

  if (index === -1) {
    // If value is not present, push it into the array
    selectedPromos.value.push(value);
  } else {
    // If value is present, remove it from the array
    selectedPromos.value.splice(index, 1);
  }

}

const startTour = ref(false);

const setTourValue = () => {
  const tour = localStorage.getItem("tour");
  startTour.value = tour ? JSON.parse(tour) : true;
};
setTourValue();

function close() {
  localStorage.setItem("tour", "false");
  store.$patch({
    tourLevel: 0,
  });

  setTourValue();
}

const buttonText = computed(() => {
  if (level.value === 0) {
    return "Start Tour";
  } else if (level.value < 1 || level.value <= 3) {
    return "Next";
  } else if (level.value == 4) {
    return "Done";
  }
});

const nextFN = () => {
  if (level.value !== 4) {
    level.value++;
  } else {
    level.value = 0;
    close();
  }

  store.$patch({
    tourLevel: level.value,
  });
};

onUnmounted(() => {
  store.$patch({
    tourLevel: 0,
  });
});
const emit = defineEmits(["update", "close"]);
</script>

<style scoped>
.addTourWave {
  background-image: url("../../assets/icons/tour-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
