<template>
  <easiModal
    rounded="2xl"
    :isBorder="false"
    v-if="showModal"
    @close="closeModal"
  >
    <div class="px-6 md:px-8">
      <div
        v-if="formType === 'both'"
        class="flex items-center w-full mx-auto border border-outlineGray rounded-xl tracking-wide justify-center md:mb-- mb-0 gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
      >
        <div
          @click="accountType = 'business'"
          :class="
            accountType === 'business'
              ? 'bg-primary shadow-2xl text-white'
              : 'bg-newGrey'
          "
          class="w-full cursor-pointer text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
        >
          Business Account
        </div>
        <div
          @click="accountType = 'personal'"
          :class="
            accountType === 'personal'
              ? 'bg-primary shadow-2xl text-white'
              : 'bg-newGrey'
          "
          class="w-full cursor-pointer text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
        >
          Personal Account
        </div>
      </div>

      <div class="my-6">
        <h2
          class="text-secondary text-center font-bold text-2xl sm:text-3xl capitalize"
        >
          New {{ accountType }} Accounts
        </h2>
        <div
          class="text-sm md:text-md text-center mx-auto font-medium text-grey"
        >
          {{
            accountType === "business"
              ? "Please input your business details to get started"
              : "Please input your details to get started"
          }}
        </div>
      </div>
      <form
        @submit.prevent="submit"
        autocomplete="on"
        class="my-5 flex flex-col w-full text-left"
      >
        <easiTextInput
          placeholder="First Name"
          label="First Name"
          v-model="args.firstName"
          type="text"
          name="firstName"
          class=""
          :error="errorRules.firstName"
        />

        <easiTextInput
          placeholder="Last Name"
          label="Last Name"
          v-model="args.lastName"
          type="text"
          name="lastName"
          class=""
          :error="errorRules.lastName"
        />

        <!-- <label
          v-if="accountType === 'business'"
          for="companyName"
          class="text-dark-800 text-sm font-medium"
          >Company Name</label
        > -->
        <easiTextInput
          v-if="accountType === 'business'"
          label="Company Name"
          placeholder="Company Name"
          v-model="args.companyName"
          type="company"
          name="text"
          class=""
          :error="errorRules.companyName"
        />
        <easiTextInput
          label="Email"
          placeholder="Email (Optional)"
          v-model="args.email"
          type="email"
          name="email"
          class=""
          :error="errorRules.email"
        />

        <easiTextInput
          label="Phone Number"
          placeholder="Phone Number (Optional)"
          v-model="args.phoneNumber"
          type="tel"
          name="phoneNumber"
          class=""
          :error="errorRules.phoneNumber"
        />

        <easiSelectInput
          v-if="accountType == 'business'"
          class="mt-5"
          @update="args.companySize = $event"
          :value="args.companySize"
          label="Company Size"
          :options="['1-20', '21-50', '51-100', '100 Above']"
          :error="errorRules.companySize"
        />

        <easiButton block :loading="loading" color="primary" class="mt-4">
          Create Account
        </easiButton>
      </form>
    </div>
  </easiModal>

  <easiSuccess v-if="addSuccess" @close="addSuccess = false">
    <template v-slot:message>
      <span>Account added successfully</span>
    </template>
  </easiSuccess>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { ref, reactive, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";

const { processNumber, log } = helperFunctions;
const { mutate, query } = useDataStore();
const toast = useToast();
const router = useRouter();

const props = defineProps({
  formType: {
    type: String,
    default: "both",
  },
});

const accountType = ref("business");
const showModal = ref(true);
const addSuccess = ref(false);
const loading = ref(false);

const args = reactive({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  companySize: "",
});

//// error rules for input fields ////
const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phoneNumber: false,
  companyName: false,
  companySize: false,
});

onMounted(() => {
  if (props.formType !== "both") {
    accountType.value = props.formType;
  }
});

function validateForm() {
  const number = processNumber(args.phoneNumber);

  if (args.firstName.trim() === "") {
    errorRules.firstName = "Please fill in your first name";
    return false;
  } else if (args.lastName.trim() === "") {
    errorRules.lastName = "Please fill in your last name";
    return false;
  } else if (args.email.trim() === "") {
    errorRules.email = "Please fill in your email address";
    return false;
  } else if (args.phoneNumber.trim() === "") {
    errorRules.phoneNumber = "Please fill in your phone number";
    return false;
  } else if (
    isNaN(args.phoneNumber.trim()) ||
    processNumber(args.phoneNumber).length > 13
  ) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
    return false;
  } else if (args.companyName.trim() === "" && accountType === "business") {
    errorRules.companyName = "Please fill in your company";
    return false;
  } else if (args.companySize.trim() === "" && accountType === "business") {
    errorRules.companySize = "Please select a company size";
    return false;
  } else {
    //Reset Error
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}

const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
}

const submit = () => {
  loading.value = true;
  args.registrationType =
    accountType.value === "business" ? "COMPANY" : "PERSONAL";

  console.log(args);

  try {
    mutate({
      endpoint: "CreateCompany",
      service: "SETTINGS",
      data: { input: args },
    })
      .then((res) => {
        if (res.success) {
          loading.value = false;
          addSuccess.value = true;
          queryCompany();

          setTimeout(() => {
            addSuccess.value = false;

            closeModal();
          }, 500);
        } else {
          loading.value = false;
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log(e);
        loading.value = false;
        toast.error(e.message);
      });
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

const queryCompany = async () => {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
};

const closeModal = () => {
  showModal.value = false;
  close();
};
</script>

<style></style>
