<template>
  <nav class="site-nav py-5 md:py-6">
    <div class="l-container flex items-center justify-between">
      <RouterLink to="/" class="nav-logo inline-block mr-12">
        <Logo />
      </RouterLink>
      <div class="hidden lg:flex">
        <ul class="nav-links items-center gap-6">
          <li>
            <router-link class="nav-link" to="/about-us">About us</router-link>
          </li>
          <li>
            <MegaMenu :menuItems="ProductMenuItems">
              <template #default="slotProps">
                <a @click="slotProps.toggleMenu()" class="nav-link cursor-pointer flex gap-1.5">
                  Product
                  <img src="./icons/dropDown.svg" />

                  <!-- <i
                    :class="[
                      slotProps.showMenu ? 'pi-angle-down' : 'pi-angle-right',
                    ]"
                    class="pi text-xs"
                  ></i> -->
                </a>
              </template>
            </MegaMenu>
          </li>

          <li>
            <button @click="emit('openModal')" class="bg-none focus:outline-none nav-link">Set your payroll</button>
          </li>

          <!-- <li class="relative" @mouseover="showDiv">
            <RouterLink
              class="nav-link flex gap-1.5"
              to="/developers"
              @mouseenter="showDiv"
              @mouseleave="hideDiv"
              @focusout="hideDiv"
              >Developers
              <img src="./icons/dropDown.svg" />
            </RouterLink>
            <div
              @click="hideDiv"
              :class="!isHovered ? 'pointer-events-none' : ''"
              class="bg-transparent fixed inset-0"
              style="z-index: 51"
            ></div>
            <div
              v-show="isHovered"
              @mouseenter="showDiv"
              @mouseleave="hideDiv"
              @focusout="hideDiv"
              tabindex="0"
              style="z-index: 52"
              class="absolute over z-10 bg-white top-7 flex flex-col gap-2 rounded-xl p-3 text-base font-normal w-fit"
            >
              <div class="bg-white rounded-xl ">
                <div
                  v-for="(menu, i) in DeveloperMenuItems"
                  :key="i"
                  @mouseover="hoveringItem = menu.title"
                  @mouseout="hoveringItem = null"
                  @click="
                    $router.push(menu.routes);
                    showMenu = false;
                  "
                  :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
                  class="flex items-center cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
                >
                  <div
                    :class="[
                      activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg',
                    ]"
                    class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
                  >
                    <img
                      :src="
                        hoveringItem === menu.title || activeLink == menu.routes
                          ? menu.iconActive
                          : menu.icon
                      "
                      alt=""
                    />
                  </div>

                  <div class="flex flex-col">
                    <h3 class="text-base font-semibold text-dark-900">
                      {{ menu.title }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>

      <div class="hidden lg:flex items-center">
        <div class="nav-btns ml-auto">
          <RouterLink to="/login" class="login-btn">Sign in</RouterLink>
          <RouterLink to="/register" class="register-btn">Sign up</RouterLink>
          <!-- <RouterLink to="/register" class="register-btn btn-shadow"
            >Register</RouterLink
          > -->
        </div>
      </div>
      <div class="lg:hidden flex flex-1 justify-end">
        <button class="hamburger" @click="openMobileNav">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>

    <div
      class="mobile-nav-overlay fixed left-0 top-0 right-0 bottom-0"
      :class="{ show: show === true }"
      @click.self="closeMobileNav"
    ></div>
    <div class="mobile-nav-menu" :class="{ open: show === true }">
      <div class="px-4 pb-12">
        <div class="flex pt-10 md:py-6 items-center justify-between mb-10">
          <Logo />
          <div>
            <button class="mobile-menu-close-btn p-2 hover:opacity-60" @click="closeMobileNav">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div
          :style="{
            backgroundImage: ' url(' + background + ')',
          }"
          style="
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            scrollbar-width: none;
          "
          class="pt-4 pb-56 h-screen overflow-auto"
        >
          <p class="font-medium text-sm text-grey mb-4">PRODUCTS</p>
          <div class="bg-white rounded-xl mb-7">
            <div
              v-for="(menu, i) in ProductMenuItems"
              :key="i"
              @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null"
              @click="
                $router.push(menu.routes);
                showMenu = false;
              "
              :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
            >
              <div
                :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
              >
                <img
                  :src="hoveringItem === menu.title || activeLink == menu.routes ? menu.iconActive : menu.icon"
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
                <p class="text-sm text-dark-600 line-clamp-2">
                  {{ menu.description }}
                </p>
              </div>
            </div>
          </div>
          <SiteButton color="primary w-full" shadow to="#"> Download Eazipay mobile app </SiteButton>
          <hr class="my-5" />

          <p class="font-medium text-sm text-grey mb-4">EMPLOYEES</p>
          <div class="bg-white rounded-xl mb-7">
            <div
              v-for="(menu, i) in EmployeeMenuItems"
              :key="i"
              @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null"
              @click="
                $router.push(menu.routes);
                showMenu = false;
              "
              :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
            >
              <div
                :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
              >
                <img
                  :src="hoveringItem === menu.title || activeLink == menu.routes ? menu.iconActive : menu.icon"
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
                <p class="text-sm text-dark-600 line-clamp-2">
                  {{ menu.description }}
                </p>
              </div>
            </div>
          </div>
          <SiteButton color=" primary w-full" shadow to="#"> Download WorknProsper mobile app </SiteButton>
          <hr class="my-8" />
          <p class="font-medium text-sm text-grey mb-4">Developers</p>
          <div class="bg-white rounded-xl mb-7">
            <div
              v-for="(menu, i) in DeveloperMenuItems"
              :key="i"
              @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null"
              @click="
                $router.push(menu.routes);
                showMenu = false;
              "
              :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex items-center cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
            >
              <div
                :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
              >
                <img
                  :src="hoveringItem === menu.title || activeLink == menu.routes ? menu.iconActive : menu.icon"
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
              </div>
            </div>
          </div>

          <hr class="my-8" />

          <SiteButton color="primary w-full" shadow to="/login"> Sign in </SiteButton>

          <!-- <ul class="grid gap-4">
            <li>
              <RouterLink class="nav-link" to="/">Home</RouterLink>
            </li>
            <li>
              <RouterLink class="nav-link" to="/individual"
                >Individual</RouterLink
              >
            </li>
            <li>
              <RouterLink class="nav-link" to="/business">Business</RouterLink>
            </li>
         
            <li class="mt-6">
              <RouterLink class="nav-link" to="/login">Login</RouterLink>
            </li>
            <li class="mt-10">
              <RouterLink class="btn-shadow w-full" to="/register"
                >Register</RouterLink
              >
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import MegaMenu from "./MegaMenu.vue";

import individualIcon from "./assets/individual.svg";
import individualActive from "./assets/individual-active.svg";
import hrIcon from "./assets/hr.svg";
import hrActive from "./assets/hr-active.svg";
import businessIcon from "./assets/business.svg";
import businessActive from "./assets/business-active.svg";
import payeIcon from "./assets/paye.svg";
import payeActive from "./assets/paye-active.svg";
import assessmentIcon from "./assets/assessment.svg";
import assessmentActive from "./assets/assessment-active.svg";
import deviceIcon from "./assets/device.svg";
import wnpIcon from "./assets/wnp.svg";
import wnpActive from "./assets/wnpActive.svg";
import deviceActive from "./assets/device-active.svg";
import overviewIcon from "./assets/overview.svg";
import overviewActive from "./assets/overview-active.svg";
import apiDocumentation from "./assets/apiDocumentation.svg";
import apiDocumentationActive from "./assets/apiDocumentationActive.svg";
import background from "./assets/mobileBackground.png";
import SiteButton from "./SiteButton.vue";

import Logo from "./Logo.vue";
import CloseIcon from "./icons/Close.vue";
const hoveringItem = ref(null);

import { computed, ref } from "vue";

const show = ref(false);
const isHovered = ref(false);
const closeMobileNav = () => (show.value = false);
const openMobileNav = () => (show.value = true);
const emit = defineEmits(["openModal"]);
const showDiv = () => {
  isHovered.value = true;
};

const hideDiv = () => {
  isHovered.value = false;
};

const ProductMenuItems = computed(() => {
  return [
    {
      title: "Individuals",
      description:
        "Add your service providers, personal assistants, chauffeurs, chefs, and more to your Eazipay and pay all in one click!",
      routes: "/individual",
      routeName: null,
      icon: individualIcon,
      iconActive: individualActive,
    },
    {
      title: "Business",
      description: `Add your employee the simple way you know and run their payroll.All in seconds!`,
      routes: "/business",
      routeName: null,
      icon: businessIcon,
      iconActive: businessActive,
    },
    {
      title: "HR Store",
      description:
        "The all-inclusive platform to buy or rent company assets and employee perks, curated to ensure the happiness of your staff",
      routes: "/hr-stores",
      routeName: null,
      icon: hrIcon,
      iconActive: hrActive,
    },

    {
      title: "Annual PAYE Filing",
      description: `Beat the deadline and save N500,000 in tax penalties.`,
      routes: "/paye",
      routeName: null,
      icon: payeIcon,
      iconActive: payeActive,
    },
    {
      title: "Assessment",
      description: `Use our world-class online exam management tool with AI-enabled proctoring tool for all your recruitment needs and career interests or job role tests`,
      routes: "/assess-employee",
      routeName: null,
      icon: assessmentIcon,
      iconActive: assessmentActive,
    },
    {
      title: "Device Management",
      description: `Configure and secure employees’ gadgets and devices from one dashboard`,
      routes: "/manage-employee",
      routeName: null,
      icon: deviceIcon,
      iconActive: deviceActive,
    },
    {
      title: "WorknProsper",
      description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
      routes: "/wnpLanding",
      routeName: null,
      icon: wnpIcon,
      iconActive: wnpActive,
    },
  ];
});

const EmployeeMenuItems = computed(() => {
  return [
    {
      title: "WorknProsper",
      description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
      routes: "/wnpLanding",
      routeName: null,
      icon: wnpIcon,
      iconActive: wnpActive,
    },
  ];
});
const DeveloperMenuItems = computed(() => {
  return [
    {
      title: "Overview",
      routes: "/developers",
      routeName: null,
      icon: overviewIcon,
      iconActive: overviewActive,
    },
    {
      title: "API Documentation",
      description: ``,
      routes: "/developers/documentation",
      routeName: null,
      icon: apiDocumentation,
      iconActive: apiDocumentationActive,
    },
    {
      title: "API Status",
      description: ``,
      routes: "/#",
      routeName: null,
      icon: apiDocumentation,
      iconActive: apiDocumentationActive,
    },
  ];
});
</script>

<style lang="scss" scoped>
.over {
  box-shadow: 0px 20px 48px 0px #aaaaaa4a;
}
.hamburger {
  display: grid;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    height: 2px;
    width: 20px;
    background-color: #14181f;
    border-radius: 2px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 35px;
}

.nav-link {
  font-weight: 500;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #ea4e4b;
  }

  &.router-link-active {
    color: #ea4e4b;
  }
}

.nav-btns {
  display: flex;
  align-items: center;
  gap: 20px;

  a {
    width: 108px;
    border-radius: 99px;
    padding: 0px;
    height: 40px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .login-btn {
    // border: 1px solid #11453b;
    background: rgba(17, 69, 59, 0.1);
    color: #11453b;
    font-weight: 500;
  }
  .register-btn {
    // border: 1px solid #11453b;
    background: #11453b;
    color: white;
  }
}

.mobile-nav-menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 20;
  width: 95vw;
  transform: translateX(100%);
  transition: 0.24s ease;
  visibility: hidden;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }

  a {
    color: #515251;
    font-size: 16px;
    font-weight: bold;
  }

  a.btn-shadow {
    color: white;
  }
}

.mobile-nav-overlay {
  background: rgb(17, 69, 59, 0.5);
  opacity: 0;
  visibility: hidden;
  opacity: 0.25s ease;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
