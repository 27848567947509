<template>
  <div
    class="bg-outline relative flex border border-outlineGray flex-col p-4 rounded-xl bg-newAsh my-4 pb-3"
  >
    <OnClickOutside @trigger="handleDone">
      <div
        v-if="showProductModal"
        class="bg-white shadow-xl rounded-2xl p-4 w-full absolute left-0 top-10 z-20"
      >
        <div class="space-y-2 overflow-auto max-h-44 mt-5">
          <div
            class="border border-outline text-bodyNormal rounded-lg flex gap-2 p-3 cursor-pointer"
            :class="isSelectAll ? 'bg-background border-2 ' : ''"
            @click="handleSelectAll"
          >
            <img v-if="isSelectAll" src="@/assets/icons/check.svg" alt="" />
            <div>Select All</div>
          </div>

          <div
            v-for="product in allProducts"
            :key="product.value"
            class="border border-outline text-bodyNormal rounded-lg flex gap-2 p-3 cursor-pointer"
            :class="
              checkIfProductIsSelected(product) ? 'bg-background border-2 ' : ''
            "
            :style="{ height: 'fit-content' }"
            @click="selectProduct(product)"
          >
            <img
              v-if="checkIfProductIsSelected(product)"
              src="@/assets/icons/check.svg"
              alt=""
            />
            <div>{{ product.label }}</div>
          </div>
        </div>

        <div class="inset-x-0 flex gap-5 justify-end">
          <div class="w-5/12 mt-6">
            <easiButton class="rounded-full w-full" @click="handleDone">
              Done
            </easiButton>
          </div>
        </div>
      </div>
    </OnClickOutside>

    <div class="flex justify-between gap-2">
      <p>{{ label }}</p>
      <div @click="showProductModal = true" class="cursor-pointer">
        <img src="@/assets/icons/plus-circle.svg" alt="" />
      </div>
    </div>
    <div
      class="flex flex-wrap gap-2"
      :class="
        selectedProducts.length ? 'mt-2 pt-2 border-t border-outlineGray' : ''
      "
    >
      <div
        v-for="(item, i) in selectedProducts"
        :key="i"
        class="flex gap-1 items-center mb-2 bg-white rounded-md px-2"
      >
        <div>
          {{ item.label }}
        </div>
        <div @click="removeSelectedProduct(item)" class="cursor-pointer">
          <img src="@/assets/icons/x-mark.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { OnClickOutside } from "@vueuse/components";
import { is } from "@babel/types";

const props = defineProps({
  products: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["done"]);

const watchIsOpen = computed(() => props.isOpen);

const allProducts = ref([]);
const selectedProducts = ref([]);

const showProductModal = ref(false);
const isSelectAll = computed(() => {
  return selectedProducts.value.length === allProducts.value.length;
});

watch(
  watchIsOpen,
  () => {
    const { products, selected } = props;

    allProducts.value = [...products];
    selectedProducts.value = selected;

    console.log(allProducts.value);
  },
  { immediate: true }
);

const handleSelectAll = () => {
  selectedProducts.value = isSelectAll.value ? [] : props.products;
};

const selectProduct = (product) => {
  if (!product) return;

  if (checkIfProductIsSelected(product)) {
    removeSelectedProduct(product);
  } else {
    selectedProducts.value.push(product);
  }
};

const removeSelectedProduct = (product) => {
  selectedProducts.value = selectedProducts.value.filter(
    (el) => el.value !== product.value
  );
  const transformSelected = selectedProducts.value.map((el) => el.value);
  emit("done", transformSelected);
};

const checkIfProductIsSelected = (product) => {
  return selectedProducts.value.find((el) => el.value == product.value);
};

const handleDone = () => {
  if (!showProductModal.value) return;
  showProductModal.value = false;

  const transformSelected = selectedProducts.value.map((el) => el.value);
  emit("done", transformSelected);
};
</script>

<style scoped>
/* Add any custom styling here */
ion-searchbar.custom {
  --background: white;
  --color: #616e7e;
  --placeholder-color: #616e7e;
  --icon-color: #616e7e;
  border: 1px solid #616e7e;
  padding: 0px;
  border-radius: 13px;
  height: 45px;
}
ion-backdrop {
  opacity: 0.4;
  background: black;
  z-index: 20;
}
</style>
