<template>
  <div class="flex flex-col gap-4">
    <!-- ///// Header ///// -->
    <div class="flex items-center justify-between">
      <div
        @click="decrementYear"
        class="cursor-pointer bg-outlineGray flex items-center justify-center px-5 py-3 rounded-lg"
      >
        <i class="text-xs pi pi-chevron-left text-dark-600" />
      </div>

      <!-- //// Year ///// -->
      <h3 class="font-semibold text-xl">{{ args.year }}</h3>

      <div
        @click="incrementYear"
        class="cursor-pointer bg-outlineGray flex items-center justify-center px-5 py-3 rounded-lg"
      >
        <i class="text-xs pi pi-chevron-right text-dark-600" />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <div
        :class="[
          args.month === index + 1
            ? 'bg-newLightGreen text-primary border-primary'
            : '',
        ]"
        @click="args.month = index + 1"
        class="px-4 py-2.5 rounded-lg border text-sm cursor-pointer flex items-center justify-between"
        v-for="(mon, index) in months"
        :key="`${mon}-${index}`"
      >
        {{ mon }}
        <img
          v-if="args.month === index + 1"
          src="@/assets/icons/check-circle.svg"
          alt=""
        />
      </div>
    </div>
    <easiButton @click="handleSelect" :disabled="!args.month" block
      >Select</easiButton
    >
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
  date: String | Date,
});
const emit = defineEmits(["select"]);

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const args = ref({
  year: new Date().getFullYear(),
  month: null,
});

const handleSelect = () => {
  const d = new Date(`${args.value.year}-${args.value.month}-01`);
  emit("select", d);
};

const incrementYear = () => {
  const currentYear = new Date().getFullYear();
  if (args.value.year == currentYear) return;
  args.value.year += 1;
};
const decrementYear = () => {
  args.value.year -= 1;
};

onMounted(() => {
  if (props.date) {
    const d = new Date(props.date);
    args.value.year = d.getFullYear();
    args.value.month = d.getMonth() + 1;
  }
});
</script>

<style></style>
