<template>
  <main>
    <div
      class="border border-outlineGray rounded-xl py-3 px-4 cursor-pointer flex items-center gap-2 bg-white w-auto"
      @click="showModal = true"
    >
      <span class="font-medium text-sm"> Filter </span>
      <img class="w-6 ml-auto md:w-4" src="@/assets/icons/funnel.svg" alt="" />
    </div>
    <easiModal
      v-if="showModal"
      @close="showModal = false"
      :isHeader="false"
      :isBorder="false"
      :lite="false"
      :persistence="true"
      :showCloseButton="false"
      :compliance="false"
    >
      <div class="p-8">
        <div class="flex justify-between w-1/2 ml-auto">
          <div class="text-xl font-medium pt-1">Filter</div>
          <img
            class="w-7 h-7 cursor-pointer"
            src="@/assets/icons/x.svg"
            alt=""
            @click="showModal = false"
          />
        </div>
        <div class="mt-8">
          <div class="grid grid-cols-10 items-center">
            <label class="col-span-3" for="startDate">From</label>
            <input
              v-model="customFilter.startDate"
              class="col-span-7 focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-2 px-4 block appearance-none rounded-2xl my-1"
              type="month"
              name="received"
              id="received"
            />
          </div>
          <div class="grid mt-4 grid-cols-10 items-center">
            <label class="col-span-3" for="startDate">To</label>
            <input
              v-model="customFilter.endDate"
              class="col-span-7 focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-2 px-4 block appearance-none rounded-2xl my-1"
              type="month"
              name="received"
              id="received"
            />
          </div>
          <p
            v-if="customFilter.error"
            class="text-secondary text-sm text-right"
          >
            Both dates are required
          </p>
        </div>
        <hr class="my-4" />
        <p class="text-base mb-2">Sort by</p>
        <div class="flex flex-col gap-2">
          <div class="bg-newAsh border border-outlineGray rounded-xl p-2">
            <div class="flex gap-1">
              <div
                v-for="(product, productIndex) in productToggle"
                :key="productIndex"
                class="py-2 font-medium text-sm rounded-lg uppercase cursor-pointer w-full text-center"
                :class="
                  activeProductToggle === product
                    ? 'bg-white text-black'
                    : ' text-newGray'
                "
                @click="activeProductToggle = product"
              >
                {{ product }}
              </div>
            </div>
          </div>
          <div class="bg-newAsh border border-outlineGray rounded-xl p-2">
            <div class="flex gap-1">
              <div
                v-for="(product, productIndex) in transactionToggle"
                :key="productIndex"
                class="py-2 font-medium text-sm rounded-lg uppercase cursor-pointer w-full text-center"
                :class="
                  activeTransactionToggle === product
                    ? 'bg-white text-black'
                    : ' text-newGray'
                "
                @click="activeTransactionToggle = product"
              >
                {{ product }}
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <select-component
          label="Service Type"
          :list="services"
          :reset="reset"
          :selectedProps="selectedServices"
          @change="handleChange"
        />
        <hr class="my-4" />
        <select-component
          label="Products"
          :list="products"
          :reset="reset"
          :selectedProps="selectedProducts"
          @change="handleChange1"
        />
        <hr class="my-4" />

        <div class="mt-6 flex gap-5 justify-center md:justify-end">
          <div class="w-32 md:w-auto">
            <easiButton
              color="primary"
              variant="outlined"
              class="rounded-full bg-white w-full md:w-52"
              @click="handleReset"
            >
              Reset
            </easiButton>
          </div>
          <div class="w-32 md:w-auto">
            <easiButton
              color="primary"
              class="rounded-full bg-white w-full md:w-52"
              @click="handleFilter"
            >
              Filter
            </easiButton>
          </div>
        </div>
      </div>
    </easiModal>
  </main>
</template>

<script>
import { ref, getCurrentInstance, reactive, watch } from "vue";
import SelectComponent from "./SelectComponent.vue";
export default {
  components: { SelectComponent },
  name: "AddResidentialState",
  emits: ["update", "removeFilter"],
  props: {
    propValue: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    companyProducts:{
      type:Array,
      default:["Eazipay Business", "WorknProsper", "EaziStores"]
    }
  },
  setup(props, { emit }) {
    const reset = ref(false);
    const services = ref(["Pension", "PAYE", "Micro Pension"]);
    const selectedServices = ref([]);
    const selectedProducts = ref([]);
    const products = ref(props.companyProducts);
    const label = ref("labe here");
    const showModal = ref(false);
    const customFilter = reactive({
      startDate: null,
      endDate: null,
      error: false,
    });
    const instance = getCurrentInstance();
    const productToggle = ref(["default", "a-z", "z-a"]);
    const activeProductToggle = ref("default");
    const filters = ref([]);
    const transactionToggle = ref([
      "default",
      "highest-lowest",
      "lowest-highest",
    ]);
    const activeTransactionToggle = ref("default");
    const handleChange = (e) => {
      selectedServices.value = e;
    };
    const handleChange1 = (e) => {
      selectedProducts.value = e;
    };
    const handleReset = () => {
      activeProductToggle.value = "default";
      activeTransactionToggle.value = "default";
      customFilter.startDate = null;
      customFilter.endDate = null;
      customFilter.error = false;
      emit("update", {
        active: [],
      });
      reset.value = !reset.value;
    };
    const handleFilter = () => {
      if (
        (customFilter.startDate && !customFilter.endDate) ||
        (!customFilter.startDate && customFilter.endDate)
      )
        return (customFilter.error = true);
     const tobeemitted = [];
      filters.value = [];

      if (customFilter.startDate && customFilter.endDate) {
        tobeemitted.push({
          date: `${customFilter.startDate} - ${customFilter.endDate}`,
        });
        filters.value.push(
          `${customFilter.startDate} - ${customFilter.endDate}`
        );
      }
      if (activeProductToggle.value !== "default") {
        tobeemitted.push({ alphabetic: activeProductToggle.value });
        filters.value.push(activeProductToggle.value);
      }
      if (activeTransactionToggle.value !== "default") {
        tobeemitted.push({ amountPaid: activeTransactionToggle.value });
        filters.value.push(activeTransactionToggle.value);
      }
      if (selectedServices.value.length) {
        selectedServices.value.forEach((service) => {
          tobeemitted.push({ service: service });
          filters.value.push(service);
        });
      }
      if (selectedProducts.value.length) {
        selectedProducts.value.forEach((product) => {
          tobeemitted.push({ product: product });
          filters.value.push(product);
        });
      }
      emit("update", {
        active: tobeemitted,
      });
      showModal.value = false;
    };
    const removeFilter = (arg) => {
      // remove toggle options
      // if the arg is a date
      
      if (arg.split("-").length === 4) {
        customFilter.startDate = null;
        customFilter.endDate = null;
      }
      // if the arg is a toggle option
      if (productToggle.value.includes(arg)) {
        activeProductToggle.value = "default";
      }
      if (transactionToggle.value.includes(arg)) {
        activeTransactionToggle.value = "default";
      }
      // if the arg is from the select options
      const filteredOptions = filters.value.filter((item) => item !== arg);
      filters.value = filteredOptions;
      selectedServices.value = selectedServices.value.filter(
        (item) => item !== arg
      );
      selectedProducts.value = selectedProducts.value.filter(
        (item) => item !== arg
      );
      handleFilter()
      // emit("update", {
      //   active: filters.value,
      //   remove: true,
      // });
    };
    return {
      reset,
      handleReset,
      removeFilter,
      handleFilter,
      handleChange,
      handleChange1,
      services,
      products,
      label,
      showModal,
      customFilter,
      productToggle,
      activeProductToggle,
      transactionToggle,
      activeTransactionToggle,
      selectedProducts,
      selectedServices,
      filters,
    };
  },

  computed: {
    style() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E0E7FF; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: left;  text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: rgba(240, 247, 235, 1);;margin-bottom: 10px;color: #515251; border: 1px solid #E7E8E7; font-size: 12px;";
    },
  },
};
</script>

<style></style>
