<template>
  <div class="px-4 md:px-28 pt-8">
    <p class="font-bold text-black">
      <span class="text-2xl"> Settings / </span>

      <span>Password </span>
    </p>

    <form
      @submit.prevent="submit"
      ref="form"
      autocomplete="on"
      class="border border-outlineGray p-8 mt-10"
    >
      <div class="grid grid-cols-2 gap-4">
        <easiTextInput
          placeholder="Current Password"
          v-model="args.currentPassword"
          type="text"
          name="newPassword"
          :disabled="loading"
          :error="errorRules.currentPassword"
          :maxlength="6"
        />
        <easiTextInput
          placeholder="New Password "
          v-model="args.newPassword"
          type="text"
          name="firstName"
          :disabled="loading"
          :error="errorRules.newPassword"
          :maxlength="6"
        />
      </div>
      <easiTextInput
        placeholder="Confirm Password "
        v-model="args.confirmPassword"
        type="text"
        name="confirmPassword"
        :disabled="loading"
        :error="errorRules.confirmPassword"
        :maxlength="6"
      />
      <div class="w-full my-3 flex place-items-center gap-5 items-center mt-8">
        <div class="w-5/12 flex justify-end ml-auto gap-6">
          <span
            @click="clearFields"
            class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 py-3"
            >Cancel</span
          >
          <div class="w-full md:w-full">
            <easiButton type="submit" :loading="loading" class="w-full">
              Save changes
            </easiButton>
          </div>
        </div>
      </div>
    </form>
    <easiAlert
      v-if="thanksModal"
      @close="thanksModal = false"
      noIcon
      :showDefaultTop="false"
    >
      <div class="px-6">
        <div class="flex justify-center pt-6">
          <span>
            <img src="@/assets/img/success.gif" class="h-32" alt="" />
          </span>
          <!-- <img class="w-32" :src="success" alt="" /> -->
        </div>
        <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
          Success!
        </div>
        <p class="text-center w-11/12 mx-auto">
          Your new Passcode has been successful changed.
        </p>
        <main class="w-full mt-6 space-y-4">
          <easiButton @click="thanksModal = false" block>Okay </easiButton>
        </main>
      </div>
    </easiAlert>
  </div>
</template>

<script setup>
import { logout } from "@/api/api";
import { reactive, ref, computed } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const { mutate } = store;

const companyData = computed(() => store.getCompanyAdmin);

const thanksModal = ref(false);
const loading = ref(false);

let args = reactive({
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
});

const errorRules = reactive({
  currentPassword: false,
  newPassword: false,
  confirmPassword: false,
});

const validate = () => {
  if (!args.currentPassword.trim()) {
    errorRules.currentPassword = "Current password is required";
    return false;
  } else if (!args.newPassword.trim()) {
    errorRules.newPassword = "New password is required";
    return false;
  } else if (!args.confirmPassword.trim()) {
    errorRules.confirmPassword = "Confirm password is required";
    return false;
  } else if (args.confirmPassword.trim() !== args.newPassword.trim()) {
    errorRules.confirmPassword = "Password doesn't match";
    return false;
  } else {
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });
    return true;
  }
};

const clearFields = () => {
  args.currentPassword = "";
  args.newPassword = "";
  args.confirmPassword = "";
};

const submit = async () => {
  if (!validate()) return;

  const { companyAdmin } = companyData.value || {};
  const payload = { ...args };
  delete payload.confirmPassword;

  try {
    loading.value = true;
    const res = await mutate({
      endpoint: "UpdatePassword",
      data: {
        input: payload,
      },
      service: "API",
    });

    if (res.success) {
      thanksModal.value = true;
      clearFields();
      setTimeout(() => {
        logout("logout");
      }, 3000);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
