<template>
  <div v-if="totalRecords" class="flex items-center justify-between">
    <div
      :class="[leftAlign ? 'flex-row-reverse' : '']"
      class="flex items-center gap-2 text-base"
    >
      <div for="rowsPerPage" class="flex gap-x-4 md:items-center">
        <select
          id="rowsPerPage"
          class="px-1 pr-8 md:py-2 py-3 rounded-xl focus:outline-none bg-transparent focus:border-outlineGray border border-outlineGray text-center text-sm font-medium uppercase"
          :value="rowsPerPage"
          @change="handleChange"
        >
          <option
            v-for="s in [
              '15',
              '20',
              '30',
              '40',
              '50',
              '60',
              '70',
              '80',
              '90',
              '100',
            ]"
            :key="s"
            :value="s"
          >
            {{ s }}
          </option>
        </select>
      </div>

      <div class="hidden md:block text-left md:my-4 text-dark-500">
        Showing
        {{ range.from || 1 }} -
        {{ range.to }}
        of {{ totalRecords }}
      </div>
    </div>

    <div v-if="!infoOnly" class="flex items-center justify-center">
      <div class="flex gap-2 items-center">
        <div
          @click="jumpToFirstPage"
          v-if="showPreviousLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-white text-dark-400 rounded-xl p-2 text-base"
        >
          <i class="pi pi-angle-double-left" />
        </div>
        <div
          v-if="showPreviousLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-white text-dark-400 rounded-xl p-2 text-base"
          @click="updatePage(page - 1)"
        >
          <i class="pi pi-angle-left" />
        </div>
      </div>

      <ul v-if="totalPages > 1" class="flex items-center">
        <li
          @click="updatePage(1)"
          :class="[
            page === 1
              ? ' disabled cursor-not-allowed bg-primary text-white rounded-xl h-8 w-8'
              : 'text-dark-800 h-8 w-8',
            'flex items-center justify-center',
          ]"
        >
          <a class="pagination-link" :disabled="page === 1">1</a>
        </li>
        <li v-if="page > 2">
          <span class="pagination-link disabled">...</span>
        </li>
        <li
          v-for="pageNumber in visiblePages"
          :key="pageNumber"
          @click="updatePage(pageNumber)"
          :class="[
            page === pageNumber
              ? ' disabled cursor-not-allowed bg-primary text-white rounded-xl h-8 w-8'
              : 'text-dark-800 h-8 w-8',
            'flex items-center justify-center',
          ]"
        >
          <a class="pagination-link">{{ pageNumber }}</a>
        </li>
        <li v-if="page < totalPages - 2">
          <span class="pagination-link disabled">...</span>
        </li>
        <li
          @click="updatePage(totalPages)"
          :class="[
            page === totalPages
              ? ' disabled cursor-not-allowed bg-primary text-white rounded-xl h-8 w-8'
              : 'text-dark-800 h-8 w-8',
            'flex items-center justify-center',
          ]"
        >
          <a class="pagination-link" :disabled="page === totalPages">{{
            totalPages
          }}</a>
        </li>
      </ul>

      <div class="flex gap-2 items-center">
        <div
          v-if="showNextLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-primary bg-opacity-20 text-primary rounded-xl p-2 text-base"
          @click="updatePage(page + 1)"
        >
          <i class="pi pi-angle-right" />
        </div>

        <div
          @click="jumpToLastPage"
          v-if="showNextLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-primary bg-opacity-20 text-primary rounded-xl p-2 text-base"
        >
          <i class="pi pi-angle-double-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  leftAlign: {
    type: Boolean,
    default: false,
  },
  infoOnly: {
    type: Boolean,
    default: false,
  },

  totalRecords: Number,
  page: Number,
  rowsPerPage: {
    type: Number,
    default: 20,
  },
});

const emit = defineEmits(["update:page", "update:rowsPerPage"]);

const totalPages = computed(() =>
  Math.ceil(props.totalRecords / props.rowsPerPage)
);

const range = computed(() => {
  let _from = (props.page - 1) * props.rowsPerPage;
  let _to = _from + props.rowsPerPage;
  let { totalRecords } = props;
  if (totalRecords && _to > totalRecords) {
    _to = totalRecords;
  }
  return {
    from: _from,
    to: _to,
  };
});

const startItem = computed(() => {
  return props.page * props.rowsPerPage + 1;
});

const endItem = computed(() => {
  const lastItem = (props.page + 1) * props.rowsPerPage;
  return Math.min(lastItem, props.totalRecords);
});

const updatePage = (pageNumber) => {
  emit("update:page", pageNumber);
};

const handleChange = (e) => {
  emit("update:rowsPerPage", +e.target.value);
  emit("update:page", props.page);
};

const visiblePages = computed(() => {
  const startPage = Math.max(2, props.page);
  const endPage = Math.min(startPage + 2, totalPages.value - 1);
  return Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
});

const showPreviousLink = () => {
  if (!props.page) return false;
  return props.page > 1;
};
const showNextLink = () => {
  if (!props.totalRecords) return false;
  return range.value.to < props.totalRecords;
};

const jumpToLastPage = () => {
  updatePage(totalPages.value);
};
const jumpToFirstPage = () => {
  updatePage(1);
};
</script>

<style scoped>
.pagination-link {
  cursor: pointer;
  padding: 0.5rem;
}

.disabled {
  cursor: not-allowed;
}

.active {
  background-color: #f0f0f0;
}
</style>
