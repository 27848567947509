<template>
  <main class="">
    <div class="flex flex-wrap my-4 md:my-10">
      <div class="text-left text-dark-800 px-4">
        <h2 class="text-base mb-2 font-bold md:text-2xl capitalize">Welcome {{ getAdminName }}</h2>

        <p class="text-sm">Pay and manage your employee in minutes</p>
      </div>
    </div>

    <div class="flex flex-wrap my-8 md:my-10">
      <div class="w-full lg:w-9/12 mb-12 lg:mb-0 px-2 md:px-4 flex flex-col gap-4">
        <!-- //////// Wallet ///////// -->
        <div class="addWave bg-primary md:p-6 py-9 px-3 rounded-2xl flex justify-between items-center">
          <div class="text-left">
            <span class="font-medium text-white">Wallet Balance</span>
            <div class="flex items-center pt-2 gap-x-3">
              <span v-show="hideBalance" class="font-bold text-2xl sm:text-3xl text-white"
                ><span v-for="len in formatAmount(balance.balance).length" :key="len">*</span></span
              >
              <span v-show="!hideBalance" class="font-bold text-2xl sm:text-3xl text-white">{{
                balance.balance ? formatAmount(balance.balance) : formatAmount(0)
              }}</span>
              <img
                class="-mt-3 cursor-pointer"
                @click="hideBalance = !hideBalance"
                v-show="!hideBalance"
                src="@/assets/icons/hide-icon.svg"
                alt=""
              />
              <img
                class="-mt-3 cursor-pointer"
                @click="hideBalance = !hideBalance"
                v-show="hideBalance"
                src="@/assets/icons/eye-icon.svg"
                alt=""
              />
            </div>
          </div>
          <span
            :style="`${getTourLevel === 1 ? 'z-index:400' : ''}`"
            :class="getTourLevel === 1 ? 'pointer-events-none' : ''"
            class="relative"
          >
            <easiButton @click="$router.push('/admin/wallet/fund-wallet')" :color="'mint'" class="rounded-full">
              <span class="text-sm text-primary sm:text-sm">Fund Wallet</span>
            </easiButton>
            <img
              v-if="getTourLevel === 1"
              class="w-10 transform rotate-45 absolute -top-16 -right-8"
              src="@/assets/img/pointer.png"
              alt=""
            />
          </span>
        </div>

        <!-- /////////  Services ////// -->
        <div
          :style="`${getTourLevel === 2 ? 'z-index:400' : ''}`"
          class="grid grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-6 mt-6 text-grey"
          :class="getTourLevel === 2 ? 'pointer-events-none' : ''"
        >
          <div
            v-for="(service, i) in services"
            :key="i"
            @click="$router.push(service.routeName ? { name: service.routeName } : { path: service.routePath })"
            :class="`${service.color} hover:${service.color}-hover`"
            class="rounded-xl sm:rounded-2xl cursor-pointer w-full px-2 py-2 sm:px-4 sm:py-4 shadow-xl text-sm lg:px-4 mx-auto flex flex-col gap-2 md:gap-4 items-center lg:justify-center"
          >
            <div class="p-2 md:p-3 rounded-lg flex flex-col md:flex-row items-center justify-center">
              <img class="w-9 md:w-auto" :src="service.icon" :alt="service.title" />
            </div>
            <span class="font-medium capitalize text-xs sm:text-sm text-left md:text-sm">{{ service.title }}</span>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-2 sm:px-4 text-dark-700">
        <div v-if="filterAutomations && filterAutomations.length">
          <div
            v-for="pay in filterAutomations"
            :key="pay._id"
            class="w-full flex flex-row lg:flex-col lg:w-10/12 mx-auto border-none shadow-lg rounded-2xl overflow-hidden bg-white"
          >
            <div class="hidden lg:block text-primary text-center p-3 bg-mint font-bold text-base shadow-md">
              Next Payroll
            </div>

            <div
              class="w-full bg-white border-r lg:border-b lg:border-r-0 border-outlineGray flex flex-col gap-2 items-center justify-center px-4 sm:px-6 lg:px-4 py-6"
            >
              <h6 class="font-medium text-sm lg:hidden">Next Payroll</h6>
              <div class="flex items-center justify-center gap-2">
                <img src="@/assets/icons/calendar.svg" alt="" />
                <span class="text-sm">{{ getDateFromDay(pay.payrollDate).dateString }}</span>
              </div>
              <h4 class="text-primary font-bold text-sm md:text-lg">
                {{ getDateFromDay(pay.payrollDate).formattedDate }}
              </h4>
            </div>

            <div
              class="w-full bg-white flex flex-col gap-2 items-start lg:items-start justify-center px-4 sm:px-6 lg:px-4 py-4"
            >
              <h4>Total Employee</h4>

              <h3 class="text-dark-800 text-3xl text-left font-bold">
                {{ stats && stats.employees }}
              </h3>
              <div class="w-full flex flex-col">
                <div :class="stats && stats.employees ? '' : 'border border-dark-400 p-1'" class="w-full flex">
                  <div
                    v-if="stats && stats.male"
                    :style="`width: ${stats && stats.malePercent}%`"
                    class="p-1 bg-primary"
                  ></div>
                  <div
                    v-if="stats && stats.female"
                    :style="`width: ${stats && stats.femalePercent}%`"
                    class="p-1 bg-secondary"
                  ></div>
                </div>
                <div class="w-full flex justify-between text-left font-bold text-xs">
                  <div class="flex flex-col">
                    <span>Male</span>
                    <span>{{ stats && stats.male }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span>Female</span>
                    <span>{{ stats && stats.female }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            class="w-full flex flex-row lg:flex-col lg:w-10/12 mx-auto border-none shadow-lg rounded-2xl overflow-hidden bg-white"
          >
            <div class="hidden lg:block text-primary text-center p-3 bg-mint font-bold text-base shadow-md">
              Next Payroll
            </div>

            <div
              class="w-full bg-white border-r lg:border-b lg:border-r-0 border-outlineGray flex flex-col gap-2 items-center justify-center px-4 sm:px-6 lg:px-4 py-6"
            >
              <h6 class="font-medium lg:hidden">Next Payroll</h6>
              <div class="flex items-center justify-center gap-2">
                <img src="@/assets/icons/calendar.svg" alt="" />
                <span class="text-sm">{{ getLastDayOfMonth().dateString }}</span>
              </div>
              <h4 class="text-primary font-bold text-sm md:text-lg">
                {{ getLastDayOfMonth().formattedDate }}
              </h4>
            </div>

            <div
              class="w-full bg-white border-l lg:border-t lg:border-l-0 border-secondary flex flex-col gap-2 items-start lg:items-center justify-center px-4 sm:px-6 lg:px-4 py-6"
            >
              <h4>Total Employee</h4>

              <h3 class="text-dark-800 text-3xl text-left font-bold">
                {{ stats && stats.employees }}
              </h3>
              <div class="w-full flex flex-col">
                <div :class="stats && stats.employees ? '' : 'border border-dark-400 p-1'" class="w-full flex">
                  <div
                    v-if="stats && stats.male"
                    :style="`width: ${stats && stats.malePercent}%`"
                    class="p-1 bg-primary"
                  ></div>
                  <div
                    v-if="stats && stats.female"
                    :style="`width: ${stats && stats.femalePercent}%`"
                    class="p-1 bg-secondary"
                  ></div>
                </div>
                <div class="w-full flex justify-between text-left font-bold text-xs">
                  <div class="flex flex-col">
                    <span>Male</span>
                    <span>{{ stats && stats.male }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span>Female</span>
                    <span>{{ stats && stats.female }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full px-2 md:px-4 my-4 md:my-10 p-4 grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto">
      <div
        v-for="(item, i) in options"
        :key="item.title"
        :style="item.color"
        v-show="item.show"
        class="relative overflow-hidden p-4 md:py-7 md:pl-9 md:pr-5 rounded-3xl border-2 border-white"
      >
        <div class="relative w-6/12 gap-4 flex flex-col z-10">
          <h2 :class="item.btnText ? 'text-xl' : 'text-base md:text-3xl'" class="font-bold" :style="item.titleStyle">
            {{ item.title }}
          </h2>

          <h3 v-if="i !== 1" class="md:text-4xl text-xl -mt-4" :style="item.textStyle">
            {{ item.text }}
          </h3>

          <img v-else class="w-64 -mt-4 -ml-2" :src="item.textStyle" alt="" />

          <div
            v-if="item.banner"
            class="w-36 object-cover absolute top-9 md:top-4 md:left-90 left-40 md:-ml-6 pointer-events-none"
          >
            <img :src="item.banner" alt="" />
          </div>

          <div
            @click="item.action"
            :style="item.btnStyle"
            :class="item.btnText ? 'w-36 md:w-40 py-2' : 'w-36 md:w-28 py-3 font-bold'"
            class="cursor-pointer px-4 hover:opacity-90 justify-center rounded-full text-xs md:text-sm flex items-center gap-2 shadow-md"
          >
            <span>{{ item.btnText ? item.btnText : "Get now" }}</span>
            <i v-if="item.btnText" class="pi pi-arrow-right text-xs md:text-sm"></i>
          </div>
        </div>

        <img
          :src="item.image"
          alt=""
          class="h-full w-64 md:w-auto md:h-52 object-cover absolute top-0 bottom-0 -right-1 pointer-events-none"
        />
      </div>
    </div>

    <div class="flex flex-wrap">
      <div
        :style="`${getTourLevel === 4 ? 'z-index:400' : ''}`"
        :class="getTourLevel === 4 ? 'pointer-events-none' : ''"
        class="w-full mb-12 xl:mb-0 px-2 md:px-4 relative"
      >
        <card-line-chart />
        <span :style="`${getTourLevel === 4 ? 'z-index:400' : ''}`" class="">
          <img
            v-if="getTourLevel === 4"
            class="w-10 transform rotate-45 absolute -top-20 right-20 md:right-40"
            src="@/assets/img/pointer.png"
            alt=""
          />
          <img
            v-if="getTourLevel === 4"
            class="w-10 transform rotate-45 absolute -top-20 right-6 md:right-56"
            src="@/assets/img/pointer.png"
            alt=""
          />
        </span>
      </div>
      <!-- <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div> -->
    </div>
    <!-- <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-page-visits />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div>
    </div> -->
    <div class="flex flex-wrap mt-6">
      <div class="w-full mb-12 xl:mb-0 px-2 md:px-4">
        <recent-activities />
      </div>
    </div>

    <Tour />
    <easiRoundLoader v-if="loading" />
  </main>
</template>
<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { getToken, onMessage } from "firebase/messaging";
import { useRouter } from "vue-router";

import CardLineChart from "@/components/Cards/CardLineChart.vue";
import CardBarChart from "@/components/Cards/CardBarChart.vue";
import CardPageVisits from "@/components/Cards/CardPageVisits.vue";
import CardSocialTraffic from "@/components/Cards/CardSocialTraffic.vue";
import Tour from "@/components/Dashboard/Tour.vue";
import RecentActivities from "@/components/Dashboard/RecentActivities.vue";

import employeeIcon from "@/assets/icons/d-employee.svg";
import complianceIcon from "@/assets/icons/d-compliance.svg";
import salaryIcon from "@/assets/icons/d-salary.svg";
import loanIcon from "@/assets/icons/d-loan.svg";

import taxImg from "@/assets/img/taxn.png";
import apiImg from "@/assets/img/api-n.png";
import tierZero from "@/assets/img/tier-zero.png";
import tierOne from "@/assets/img/tier-one.png";
import tierTwo from "@/assets/img/tier-two.png";
import tierThree from "@/assets/img/tier-three.png";
import tierBanner from "@/assets/img/tier-banner.png";
import tierBannerOne from "@/assets/img/tier-banner1.png";

import textsvg from "@/assets/icons/transfer-limit.svg";

export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    CardBarChart,
    CardPageVisits,
    CardSocialTraffic,
    RecentActivities,
    Tour,
  },

  data: {
    loading: false,
  },

  setup() {
    const toast = useToast();
    const store = useDataStore();
    const router = useRouter();
    const { query, mutate } = store;
    const { getAllEmployees, getTourLevel, checkLoading } = storeToRefs(store);
    const { formatAmount, calculatePercentage, log } = helperFunctions;
    const automations = computed(() => store.getPayrollAutomation);
    const companyData = computed(() => store.getCompanyAdmin);
    const companyDetails = computed(() => store.getCompanyDetails);
    const earliestAutomation = ref([]);

    const options = computed(() => {
      return [
        {
          title: "Get your Eazipay",
          show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "0" ? true : false,
          text: "Wallet details",
          action: () => {
            router.push("/settings/profile");
          },
          image: tierZero,

          color: `background: linear-gradient(180deg, #11453B 0%, #2AAB92 100%);`,
          titleStyle: "color: #FFFFFF;",
          textStyle: "color: #D9EBCD; font-weight: 900",
          btnStyle:
            "background: #D9EBCD; box-shadow: 0px 3.6872172355651855px 7.374434471130371px 0px #90A284 inset; color: #11453B",
        },
        {
          title: "Increase your",
          show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "1" ? true : false,
          text: "Transfer Limit",
          action: () => {
            router.push("/settings/profile");
          },
          image: tierOne,
          color: `background: linear-gradient(180deg, #EA4E4B 0%, #F4A6A5 100%);`,
          titleStyle: "color: #FFFFFF;",
          textStyle: textsvg,
          btnStyle:
            "background: #EA4E4B; box-shadow: 0px 2.9968349933624268px 5.9936699867248535px 0px #D62724 inset;color:#ffffff",
        },
        {
          title: "Increase your",
          show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "2" ? true : false,
          text: "Transfer Limit to",
          amount: 5000000,
          banner: tierBanner,
          action: () => {
            router.push("/settings/profile");
          },
          image: tierTwo,
          color: `background: linear-gradient(270deg, rgba(247, 250, 249, 0) 14.95%, #F6F9F8 88.23%);`,
          titleStyle: "color: #11453B;",
          textStyle: "color: #11453B;font-weight: 900",
          btnStyle:
            "background: #11453B; box-shadow: 0px 3.0055980682373047px 6.011196136474609px 0px #063A30 inset; color: #ffffff",
        },
        {
          title: "Increase your",
          show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "3" ? true : false,
          text: "Transfer Limit to",
          amount: 10000000,
          banner: tierBannerOne,
          action: () => {
            router.push("/settings/profile");
          },
          image: tierThree,
          color: `background: linear-gradient(268.86deg, rgba(151, 155, 164, 0) -7.69%, #999DA5 29.3%);`,
          titleStyle: "color: #ffffff;",
          textStyle: "color: #ffffff;font-weight: 900",
          btnStyle: "background: #ffffff; color: #11453B",
        },
        //external
        {
          title: "File your annual taxes with ease.",
          action: () => {
            router.push("/admin/tax-filing");
          },
          image: taxImg,
          color: `background: linear-gradient(90deg, #11453B 0%, rgba(17, 69, 59, 0.30) 100%);`,
          show: true,
          titleStyle: "color: #ffffff;",
          textStyle: "color: #ffffff;font-weight: 900",
          btnStyle: "background: #ffffff; color: #11453B",
          btnText: "Get started now",
        },
        {
          title: "Generate secure API keys for PAYE and Pension",
          action: () => {
            router.push("/developers/dashboard/overview");
          },
          image: apiImg,
          color: `background: linear-gradient(180deg, #EA4E4B 0%, #F5D5D2 109.56%);`,
          show: false,
          titleStyle: "color: #ffffff;",
          textStyle: "color: #ffffff;font-weight: 900",
          btnStyle: "background: #ffffff; color: #11453B",
          btnText: "Get started now",
        },
        //external
      ];
    });

    const stats = computed(() => {
      const employees = getAllEmployees.value && getAllEmployees.value.data && getAllEmployees.value.data.length;
      const male =
        getAllEmployees.value &&
        getAllEmployees.value.data &&
        getAllEmployees.value.data.filter((el) => el.profile.gender === "MALE");
      const female =
        getAllEmployees.value &&
        getAllEmployees.value.data &&
        getAllEmployees.value.data.filter((el) => el.profile.gender === "FEMALE");

      const obj = {
        employees,
        male: male && male.length,
        female: female && female.length,
        malePercent: (male && male.length * 100) / employees,
        femalePercent: (female && female.length * 100) / employees,
      };

      // log(obj);

      return obj;
    });

    const balance = computed(() => store.getWalletBalance);
    const hideBalance = ref(true);

    // const options = computed(() => {
    //   return [
    //     {
    //       title: "File your annual taxes with ease.",
    //       action: () => {
    //         router.push("/admin/tax-filing");
    //       },
    //       image: taxImg,
    //       color: `background: linear-gradient(90deg, #11453B 0%, rgba(17, 69, 59, 0.30) 100%);`,
    //     },
    //     {
    //       title: "Generate secure API keys for PAYE and Pension",
    //       action: () => {
    //         router.push("/developers/dashboard/overview");
    //       },
    //       image: apiImg,
    //       color: `background: linear-gradient(180deg, #EA4E4B 0%, #F5D5D2 109.56%);`,
    //     },
    //   ];
    // });

    const filterAutomations = computed(() => {
      if (automations.value && automations.value.length) {
        const minDate = Math.min(...automations.value.map(({ payrollDate }) => payrollDate));

        let result = automations.value.filter((aut) => aut.payrollDate === minDate && aut.paused !== true);
        return result;
      }
    });

    // }
    async function queryAutomations() {
      // loading.value = true;

      try {
        await query({
          endpoint: "ListPayrollAutomations",
          service: "PAYROLL",
          storeKey: "payrollAutomations",
        });
        // filterAutomations();
        //
      } catch (e) {
        console.log(e);
        //
      }
    }
    queryAutomations();

    const services = [
      {
        title: "Add Employee",
        icon: employeeIcon,
        routeName: "AddEmployee",
        color: "bg-white",
      },
      {
        title: "Pay Compliance",
        icon: complianceIcon,
        routeName: "RegisterCompliance",
        color: "bg-white",
      },
      {
        title: "Pay Salary",
        icon: salaryIcon,
        routeName: "PaySalary",
        color: "bg-white",
      },
      {
        title: "Quick Loans",
        icon: loanIcon,
        // routeName: "LoanApplication",
        routePath: "/loan/application/view",
        color: "bg-white",
      },
    ];

    function getLastDayOfMonth() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth();
      let d = new Date(Number(year), Number(month) + 1, 0);
      const day = d.toString().split(" ")[2];
      // console.log(getDateFromDay(day), "KI");

      return getDateFromDay(Number(day));
    }

    function getDateFromDay(d) {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      let currentDay = new Date().getDate();

      let payrollMonth;
      if (Number(currentDay) > Number(d)) {
        payrollMonth = Number(currentMonth) + 1;
      } else {
        payrollMonth = Number(currentMonth);
      }

      let date = new Date(currentYear, payrollMonth, d);
      const dayInt = date.getDay();
      const dateString = days[dayInt];
      const fullDate = date.toLocaleDateString().split("/");

      const formattedDate = `${fullDate[1]}/${fullDate[0]}/${fullDate[2]}`;
      console.log(formattedDate, dateString);
      return {
        formattedDate,
        dateString,
      };
    }

    const lastDay = computed(() => {
      let month = new Date().getMonth();
      let year = new Date().getFullYear();

      let lastDay = new Date(year, month + 1, 0).getDate();
      return lastDay;
    });

    return {
      query,
      mutate,
      toast,
      store,
      hideBalance,
      formatAmount,
      balance,
      automations,
      // getDateFromDay,
      getLastDayOfMonth,
      filterAutomations,
      stats,
      toast,
      services,
      log,
      getTourLevel,
      automations,
      lastDay,
      getDateFromDay,
      options,
    };
  },
  mounted() {
    onMessage(this.$messaging, (payload) => {
      const { title, body } = payload.notification;

      if (Notification.permission === "granted") {
        this.showNotification(title, body);
      } else {
        console.log("Push notification received, but permission not granted.");
      }
    });
  },

  computed: {
    ...mapWritableState(useDataStore, ["getCompanyDetails", "getCompanyAdmin"]),
    companyData: (store) => store.getCompanyDetails,
    // companyData: (store) => store.getCompanyAdmin,
    getAdminName() {
      let name = "";
      // console.log("Company Admin Details...");
      // this.log(this.companyData);

      name = this.companyData && this.companyData.company && this.companyData.company.companyName;

      // console.log("Name", name);

      return name;
    },
  },
  methods: {
    requestPermission() {
      console.log("Requesting permission...");
      window.Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          this.getFirebaseToken();
        } else {
          console.log("Notification permission not granted.");
        }
      });
    },

    async getFirebaseToken() {
      const isNotification = window.localStorage.getItem("isNotification");
      if (!isNotification) {
        const vapidKey = `BFf-Zh5n9Ti6f8ZVQZSXPBKFavz7urJfqMS9wsYjin6UaWMgYu02b_MIXEcXpLR2U6NjMeypq2msQJdX3I4Wj1s`;

        getToken(this.$messaging, { vapidKey })
          .then((currentToken) => {
            if (currentToken) {
              this.enableNotification(currentToken);
              window.localStorage.setItem("isNotification", "enabled");
            } else {
              this.requestPermission();
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      } else {
        console.log("Notification already enabled");
      }
    },

    async enableNotification(token) {
      try {
        let res = await this.mutate({
          endpoint: "EnableNotifications",
          data: { token },
          service: "AUTH",
        });
        if (res.success) {
          this.toast.success(res.message);
        } else {
          this.toast.error(res.message);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async showNotification(title, body) {
      if ("Notification" in window) {
        const options = {
          body,
          icon: "/path/to/notification-icon.png", // Replace with the path to your notification icon
        };

        // Display the notification
        new Notification(title, options);
      }
    },

    async queryCompany() {
      this.loading = true;
      try {
        let res = await this.query({
          endpoint: "FetchCompaniesByAdminId",
          service: "SETTINGS",
          storeKey: "companyDetails",
        });
        this.loading = false;
        console.log(res, "Company Details");
      } catch (err) {
        this.loading = false;
      }
      // this.log(this.companyDetails);
    },
    async queryCompanyAdmin() {
      try {
        this.loading = true;
        await this.query({
          endpoint: "FetchCompanyAdmin",
          service: "SETTINGS",
          storeKey: "companyAdmin",
        });
        this.loading = false;
        console.log("Company Details");
        // this.log(this.companyDetails);
      } catch (err) {
        this.loading = false;
      }
    },

    async queryWalletBalance() {
      try {
        const id = "";
        this.loading = true;
        await this.query({
          endpoint: "ViewWalletBalance",
          payload: {
            input: {
              companyId: id,
            },
          },
          service: "PAYROLL",
          storeKey: "walletBalance",
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    async queryAllowance() {
      await this.query({
        endpoint: "ListAllowance",
        payload: {
          paging: {
            limit: 1000,
            skip: 0,
            search: null,
          },
        },
        service: "PAYROLL",
        storeKey: "allowance",
      });
    },

    async queryEmployees() {
      await this.query({
        endpoint: "ListEmployees",
        payload: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
            sort: { firstName: "ASC" },
          },
          filter: [],
        },
        service: "EMP",
        storeKey: "listEmployees",
      });
    },

    async queryArchived() {
      await this.query({
        endpoint: "ListEmployees",
        payload: {
          archived: true,
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
            sort: { firstName: "ASC" },
          },
          filter: [],
        },
        service: "EMP",
        storeKey: "listArchivedEmployees",
      });
    },

    async queryBonusOrDed() {
      try {
        await this.query({
          endpoint: "ListBonusAndDeduction",
          payload: {
            input: {
              paging: {
                limit: 1000,
                skip: 0,
                search: "",
              },
              type: "Bonus",
            },
          },
          service: "PAYROLL",
          storeKey: "listBonusAndDeduction",
        });
      } catch (err) {
        console.log(err);
      }
    },

    async queryBonus() {
      try {
        await this.query({
          endpoint: "ListBonusAndDeduction",
          payload: {
            input: {
              paging: {
                limit: 1000,
                skip: 0,
                search: "",
              },
              type: "Bonus",
            },
          },
          service: "PAYROLL",
          storeKey: "bonuses",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async queryDeduction() {
      try {
        await this.query({
          endpoint: "ListBonusAndDeduction",
          payload: {
            input: {
              paging: {
                limit: 1000,
                skip: 0,
                search: "",
              },
              type: "Deduction",
            },
          },
          service: "PAYROLL",
          storeKey: "deductions",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async queryAutomation() {
      try {
        await this.query({
          endpoint: "ListPayrollAutomations",
          service: "PAYROLL",
          storeKey: "payrollAutomations",
        });
      } catch (err) {
        console.log(err);
      }
    },

    async queryLoan() {
      try {
        await this.query({
          endpoint: "ListLoans",
          payload: {
            paging: {
              limit: 1000,
              skip: 0,
              search: "",
            },
            filter: {
              type: null,
              status: null,
            },
            sort: {
              createdAt: -1,
            },
          },
          service: "LOAN",
          storeKey: "loans",
        });
        console.log(allLoans.value);
      } catch (err) {
        console.log(err);
      }
    },
  },
  async created() {
    await this.queryCompany();
    await this.queryWalletBalance();
    await this.queryEmployees();
    await this.queryArchived();

    await this.queryCompanyAdmin();

    this.queryAllowance();
    this.queryBonusOrDed();
    this.queryBonus();
    this.queryDeduction();
    this.queryAutomation();
    this.getFirebaseToken();

    // this.queryLoan();
  },
};
</script>

<style scoped>
.addWave {
  background-image: url("../../assets/img/wav.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
