<template>
  <div class="switch-group">
    <div class="switch-group-label">{{ label }}</div>
    <label class="switch">
      <input
        type="checkbox"
        class="switch-checkbox"
        :checked="checked"
        @change="handleChange"
        :value="value"
        v-bind="$attrs"
      />
      <div class="switch-label">
        <div class="switch-inner">
          <div class="switch-inner-half -yes"></div>
          <div class="switch-inner-half -no"></div>
        </div>
        <div class="switch-switch shadow"></div>
      </div>
    </label>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  label: String,
  value: String,
  modelValue: [String, Array],
});

const toggleItem = (item, checked) => {
  if (checked === undefined) {
    checked = props.modelValue.includes(item) ? false : true;
  }

  if (checked) {
    return props.modelValue.concat(item);
  } else {
    return props.modelValue.filter((each) => each !== item);
  }
};

const emit = defineEmits(["update:modelValue"]);

const checked = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.value);
  } else {
    return !!props.modelValue;
  }
});

const handleChange = (e) => {
  let { value, checked } = e.target;
  let newModelValue;
  if (Array.isArray(props.modelValue)) {
    newModelValue = toggleItem(value, checked);
  } else {
    newModelValue = checked;
  }
  emit("update:modelValue", newModelValue);
};
</script>

<style lang="scss" scoped>
.switch-group {
  @apply flex items-center;
  border-radius: 16px;
  gap: 8px;
  padding: 6px 10px;
}

.switch-group-label {
  font-size: 14px;
  font-weight: 500;
  color: #515251;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: block;
  height: 100%;
  border-radius: 99px;
  width: 50px;
  height: 26px;
  position: relative;
  background-color: #f2f1f1;
  cursor: pointer;
  overflow: hidden;
  transition: 0.15s ease-in-out;
}

.switch-inner {
  display: flex;
  height: 100%;
  width: 200%;
  transition: 0.15s ease-in-out;
  transform: translateX(-50%);
}

.switch-inner-half {
  display: inline-flex;
  align-items: center;
  width: 50%;
  height: 100%;
  color: #515251;
  padding: 0 10px;
  font-size: 12px;
  text-transform: uppercase;

  &.-no {
    justify-content: flex-end;
  }

  &.-yes {
    color: #fff;
    background-color: #219653;
    justify-content: flex-start;
  }
}

.switch-switch {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 99px;
  transition: 0.15s ease-in-out;
}

.switch-checkbox:checked ~ .switch-label .switch-switch {
  left: calc(100% - 20px - 4px);
  background: #fff;
  border-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.switch-checkbox:checked ~ .switch-label .switch-inner {
  transform: translateX(0);
}

.switch-checkbox:checked ~ .switch-label {
  border-color: #11453b;
}
</style>
