<template>
  <main class="w-full">
    <div class="w-full">
      <table
        v-for="(dat, i) in visibleData"
        :key="i"
        class="w-full hidden md:table mb-0 border border-separate border-goldBorder"
      >
        <thead class="shadow-2xl">
          <tr
            class="text-header font-medium shadow-xl bg-white text-xs relative"
          >
            <th
              v-for="header in headers"
              :key="header"
              class="border border-btnPri shadow-xl text-xs text-left p-2 text-grey"
            >
              {{ header }}
            </th>
          </tr>
        </thead>

        <tbody class="md:relative">
          <tr
            v-for="(sub, k) in dat"
            :key="k"
            class="hover:bg-easigrey group bg-white cursor-pointer relative"
          >
            <td
              class="border-t border-r border-btnPri font-medium text-left text-dark-900 text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ getDateString(sub.transactionDate).fullDate || "N/A" }}
            </td>
            <td
              :class="
                sub.transactionType.toLowerCase() === 'debit'
                  ? ' border-t border-r border-btnPri font-medium text-left text-secondary text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize left-8'
                  : ' border-t border-r border-btnPri font-medium text-left text-success text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize left-8'
              "
            >
              {{ sub.transactionType.toLowerCase() }}
            </td>
            <td
              :class="
                sub.transactionType === 'DEBIT'
                  ? 'debit'
                  : 'bg-success-plain border-l border-success'
              "
              class="border-t border-r border-btnPri font-medium text-left text-dark-900 text-xs p-3 group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ formatAmount(sub.amount) }}
            </td>
            <td
              class="border-t border-r border-btnPri capitalize font-medium text-left text-dark-900 text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ sub.description }}
            </td>
            <td
              class="border-t border-r border-btnPri font-medium text-left text-dark-900 debit text-xs p-3 bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky left-8"
            >
              {{ formatAmount(sub.walletBalance) }}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-for="(dat, i) in visibleData"
        :key="i"
        class="w-full md:hidden table mb-0"
      >
        <tbody class="md:relative">
          <tr
            v-for="(sub, k) in dat"
            :key="k"
            class="hover:bg-easigrey group bg-white cursor-pointer w-full relative my-4"
          >
            <td
              :class="
                sub.transactionType.toLowerCase() === 'debit'
                  ? 'border border-secondary font-medium   text-xs  bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize'
                  : 'border border-success font-medium  text-xs  bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300 sticky capitalize'
              "
              class="flex flex-col my-3 font-medium text-left text-dark-900 text-xs bg-white group-hover:bg-indigo-100 group-hover:border-indigo-300"
            >
              <div
                :class="
                  sub.transactionType.toLowerCase() === 'debit'
                    ? 'border-b border-secondary'
                    : 'border-b border-success'
                "
                class="text-center text-dark-800 font-medium py-2"
              >
                {{ getDateString(sub.transactionDate).fullDate }}
              </div>
              <div class="grid grid-cols-2 justify-between">
                <div
                  :class="
                    sub.transactionType.toLowerCase() === 'debit'
                      ? ' border-secondary'
                      : ' border-success'
                  "
                  class="w-full text-wrapper col-span-1 text-dark-900 font-medium py-1 px-3"
                >
                  {{ sub.description }}
                </div>
                <div
                  :class="
                    sub.transactionType.toLowerCase() === 'debit'
                      ? 'debit'
                      : 'bg-success-plain border-l border-success'
                  "
                  class="w-full text-dark-900 font-black py-1 px-3 flex flex-row items-center"
                >
                  {{ formatAmount(sub.amount) }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <easiPagin
      v-if="splitData.length"
      :data="splitData"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    />

    <easiModal v-if="selectDuration" @close="selectDuration = false">
      <template v-slot:header>
        <h1 class="text-dark-800 font-medium">Select Duration</h1>
      </template>
      <form
        @submit.prevent="queryWalletReport"
        class="flex flex-col items-center gap-9 w-full mt-3 px-5"
      >
        <span
          class="time-span-color px-3 py-2 text-sm rounded-md w-44 text-center text-grey font-bold"
          >From</span
        >

        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          @input="assign($event, 'start')"
          v-model="args.startDate"
        />

        <span
          class="time-span-color px-3 py-2 text-sm rounded-md w-44 text-center text-grey font-bold"
          >To</span
        >

        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          type="date"
          name="start"
          id="start"
          required
          @input="assign($event, 'end')"
          :min="args.startDate"
          v-model="args.endDate"
        />

        <div class="w-full">
          <hr
            class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor"
          />

          <easiButton :block="true" class="w-full">Generate Report</easiButton>
        </div>
      </form>
    </easiModal>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Generating Report</h1>
      </template>

      <!-- <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template> -->
    </easiLoading>
  </main>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query } = useDataStore();
const emit = defineEmits(["date"]);

const { formatAmount, calculatePercentage } = helperFunctions;
const companyData = computed(() => store.getCompanyDetails);
const adminData = computed(() =>
  store.getCompanyAdmin ? store.getCompanyAdmin : {}
);
const walletReportData = computed(() => store.getWalletReport);

const props = defineProps({
  showFullData: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: [],
  },
});
const propD = ref(props.data);

const headers = ref([
  "DATE",
  "TRANSACTION",
  "AMOUNT",
  "DESCRIPTION",
  "WALLET BALANCE",
]);

const currentPage = ref(0);
const pageSize = ref(1);
const selectDuration = ref(true);
const loadingProgress = ref(false);

let visibleData = ref([]);
const args = ref({
  companyId: "",
  startDate: "",
  endDate: "",
  download: false,
  email: "",
});

async function queryCompany() {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  }
}
const fetchCompanyAdminDetails = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  }

  // log(adminDetails);
};

function assign(e, tm) {
  let obj = { startDate: "", endDate: "" };
  if (tm === "start") {
    obj.startDate = args.value.startDate;
    obj.endDate = args.value.endDate;
    emit("date", obj);
  } else if (tm === "end") {
    obj.startDate = args.value.startDate;
    obj.endDate = args.value.endDate;
    emit("date", obj);
  }
}

onMounted(async () => {
  await fetchCompanyAdminDetails();
  await queryCompany();
});

function getDateString(utc) {
  const days = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };

  const months = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  const dateArr = utc.split(" ");
  const dayOfWeek = days[dateArr[0]];
  const month = months[dateArr[1]];
  const dayAndYear = `${dateArr[2]}, ${dateArr[3]}`;
  const time = dateArr[4].slice(0, -3);

  const fullDate = `${dayOfWeek}, ${month} ${dayAndYear}`;

  return {
    fullDate,
    time,
  };
}

async function queryWalletReport() {
  if (companyData.value) {
    args.value.companyId = companyData.value._id;
    args.value.email =
      adminData.value && adminData.value.companyAdmin
        ? adminData.value.companyAdmin.email
        : companyData.value.companyAdmin.email;
  } else {
    return;
  }
  loadingProgress.value = true;
  try {
    await query({
      endpoint: "ViewWalletReport",
      service: "PAYROLL",
      storeKey: "walletReport",
      payload: { input: args.value },
    });
    console.log(walletReportData.value, "WR");
    splitToChunk();
    updateVisibleData();
    loadingProgress.value = false;
    selectDuration.value = false;
  } catch (e) {
    loadingProgress.value = false;
    console.log(e);
  }
}

const splitData = ref([]);
function splitToChunk() {
  const chunkSize = 18;
  let all = [];
  if (
    walletReportData.value.transactions &&
    walletReportData.value.transactions.length
  ) {
    for (
      let i = 0;
      i < walletReportData.value.transactions.length;
      i += chunkSize
    ) {
      const chunk = walletReportData.value.transactions.slice(i, i + chunkSize);
      all.push(chunk);

      // do whatever
    }
  }

  splitData.value = all;
  console.log(splitData.value, "ALL CHUNKS");
}
splitToChunk();

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = splitData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
// updateVisibleData();
// onMounted(()=> {
//    splitToChunk();
//   console.log("cje");
//   updateVisibleData();
// })
watch(propD, (val, newVal) => {
  splitToChunk();
  console.log("cje");
  updateVisibleData();
  console.log(visibleData.value);
});
</script>

<style>
.debit {
  background: rgba(228, 87, 46, 0.15);
}
.time-span-color {
  background: #f2f1f1;
}
.text-wrapper {
  white-space: normal; /* Override the white-space property */
  word-wrap: break-word;
}
</style>
