<template>
  <router-view />
</template>

<!-- <script setup>
import { useRoute } from "vue-router";

const route = useRoute();
alert(JSON.stringify(route.query.token));

if (route.query && route.query.token) {
  window.localStorage.setItem("token", route.query.token);
}
</script> -->
<style>
@font-face {
  font-family: "Aeonik";
  src: local("Aeonik"), url(/fonts/Aeonik-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Aeonik";
  src: local("Aeonik"), url(/fonts/Aeonik-Bold.otf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "MADE Mellow";
  font-weight: 500;
  src: local("MADEMellow"), url(/fonts/MADEMellow-Medium.otf) format("truetype");
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.easiGradient {
  /* background: linear-gradient(89.6deg, #0a244f 0.35%, #cf3101 268.44%); */
  background: linear-gradient(90deg, #11453b 0%, #b4a572 100%);
}

.gradient {
  background: linear-gradient(90deg, #11453b 0%, #b4a572 100%);

  /* background: linear-gradient(93.11deg, #0a244f 14.05%, #c92f02 101.64%); */
}

.reverse-gradient {
  background: linear-gradient(90deg, #b4a572 0%, #11453b 100%);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
