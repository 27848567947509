<template>
  <main>
    <div
      @click.self="clickOutSide"
      style="
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #032821b9;
      "
      class="inset-0 -z-5 w-full overflow-y-auto py-16"
    >
      <div
        style="border-radius: 40px"
        class="relative z-10 md:w-1/3 w-11/12 flex flex-col items-center justify-center px-12 mx-auto bg-white overflow-y-auto overflow-x-hidden shadow-xs"
      >
        <div class="absolute right-0 top-0 mt-5 mr-4 rounded-lg bg-newAsh p-1">
          <svg
            class="cursor-pointer"
            @click="close"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="#292A29"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="justify-center pt-6">
          <span>
            <img class="w-16 md:w-auto" :src="`${icon}`" alt="" />
          </span>
          <!-- <img class="w-32" :src="success" alt="" /> -->
        </div>

        <div
          class="mt-8 px-0 text-base md:text-lg font-bold text-dark-800 leading-8 md:pr-5 md:text-center"
        >
          <slot name="message" />
        </div>

        <main
          class="flex flex-col gap-7 mb-7 items-center text-center w-full justify-center my-2"
        >
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            <slot name="action" />
          </div>
        </main>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed } from "vue";
import success from "@/assets/icons/success.svg";
import deleteIcon from "@/assets/icons/delete-icon.svg";
const props = defineProps({
  icon: {
    default: deleteIcon,
  },

  showIcon: {
    type: Boolean,
    default: true,
  },

  persistence: {
    type: Boolean,
    default: false,
  },
});

const displayIcon = computed(() => {
  return props.icon ? props.icon : deleteIcon;
});
const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
  //   window.location.reload();
}

const clickOutSide = () => {
  if (!props.persistence) {
    close();
  }
};
</script>

<style></style>
