<template>
  <Teleport to="body">
    <Transition name="modal">
      <div
        v-if="modelValue"
        @click.self="close"
        class="modal fixed left-0 top-0 w-full h-full grid place-items-center p-4"
      >
        <div
          class="modal-content relative bg-white py-6 px-4"
          :class="[$attrs.class]"
        >
          <div class="absolute right-0 left-0 top-0 py-4 px-5 flex justify-end">
            <button
              @click="close"
              class="w-6 h-6 grid place-items-center font-medium text-[18px] leading-none bg-green-700 text-white rounded-full"
            >
              &times;
            </button>
          </div>
          <slot />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
const props = defineProps({
  modelValue: Boolean,
  disableClose: Boolean,
});

const emits = defineEmits(["update:modelValue", "close"]);

const close = () => {
  if (!props.disableClose) {
    emits("update:modelValue", false);
  }
  emits("close");
};
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.modal {
  background: rgba(17, 69, 59, 0.5);
  z-index: 10;
}

.modal-content {
  border-radius: 32px;
}

.modal-enter-active,
.modal-leave-active {
  transition: 0.25s ease;
}

.modal-enter-from {
  transform: scale(1.04);
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}
</style>
