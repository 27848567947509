<template>
  <div class="bg-blueGray-100 onprint">
    <h4
      class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Employee Management /
      {{ $route.path.includes("edit") ? "Edit" : "View" }}
      Employee /
      {{ employeeName }}
    </h4>

    <div class="text-left my-5">
      <easiBackButton
        :link="
          $route.path.includes('edit')
            ? 'EditEmployee'
            : `${fromArchived ? 'Archived' : 'ViewEmployee'}`
        "
      ></easiBackButton>
    </div>
    <div class="text-left bg-blueGray-100 px-2 sm:px-4 onprint">
      <EmployeeMenu />
      <!-- <header-stats /> -->
      <div class="mx-auto w-full mt-2">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script setup>
import EmployeeMenu from "@/components/Employee/EmployeeMenu.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query } = store;
const route = useRoute();

const { getEmployeeById, getArchivedEmployeeById } = storeToRefs(store);

const fromArchived = computed(() => store.getViewFromArchived);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const queryEmployees = async () => {
  //   loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    // loading.value = false;
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};
queryEmployees();

const employeeName = computed(() => {
  const emp = fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id);

  return emp ? `${emp.profile.firstName} ${emp.profile.lastName}` : "N/A";
});

onMounted(() => {
  queryEmployees();
});
</script>
