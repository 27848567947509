<template>
  <div class="switch-group mt-2">
    <div class="switch-group-label w-10/12">{{ label }}</div>
    <div
      class="border border-outlineGray bg-newAsh flex items-center gap-3 p-1 rounded-lg"
    >
      <P
        class="cursor-pointer text-sm font-medium py-1 px-2"
        :class="!checked ? 'bg-white   rounded text-grey' : 'text-dark-200'"
        @click="toggleItem"
        >NO</P
      >
      <p
        class="cursor-pointer text-sm font-medium py-1 px-2"
        :class="checked ? 'bg-white  rounded text-grey' : 'text-dark-200'"
        @click="toggleItem"
      >
        YES
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  label: String,
  value: String,
  modelValue: [String, Array],
});

const toggleItem = () => {
  if (checked.value) {
    checked.value = false;
    emit("update:modelValue", false);
  } else {
    checked.value = true;
    emit("update:modelValue", true);
  }
};

const emit = defineEmits(["update:modelValue"]);

const checked = ref(false);
</script>

<style lang="scss" scoped>
.switch-group {
  @apply flex justify-between  items-center;
  padding: 6px 10px;
}

.switch-group-label {
  font-size: 16px;
  font-weight: 400;
  color: #515251;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: block;
  height: 100%;
  border-radius: 99px;
  width: 64px;
  height: 26px;
  position: relative;
  background-color: #f2f1f1;
  cursor: pointer;
  overflow: hidden;
  transition: 0.15s ease-in-out;
}

.switch-inner {
  display: flex;
  height: 100%;
  width: 200%;
  transition: 0.15s ease-in-out;
  transform: translateX(-50%);
}

.switch-inner-half {
  display: inline-flex;
  align-items: center;
  width: 50%;
  height: 100%;
  color: #515251;
  padding: 0 10px;
  font-size: 12px;
  text-transform: uppercase;

  &.-no {
    justify-content: flex-end;
  }

  &.-yes {
    color: #fff;
    /* background: #11453b; */
    background-color: #b4a572;
    justify-content: flex-start;
  }
}

.switch-switch {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 99px;
  transition: 0.15s ease-in-out;
}

.switch-checkbox:checked ~ .switch-label .switch-switch {
  left: calc(100% - 20px - 4px);
  background: #fff;
  border-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.switch-checkbox:checked ~ .switch-label .switch-inner {
  transform: translateX(0);
}

.switch-checkbox:checked ~ .switch-label {
  border-color: #11453b;
}
</style>
