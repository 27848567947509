<template>
  <div
    v-if="data && totalPages >= 1"
    class="flex items-center justify-start gap-4 md:justify-between w-full"
  >
    <!-- <p class="text-left text-xs md:text-base md:my-4 text-dark-200">
      Showing

      <span
        >{{ currentPage == 0 ? currentPage + 1 : pageSize + currentPage }} -
        {{
          currentPage == 0
            ? pageSize > data.length
              ? data.length
              : pageSize
            : pageSize + visibleData.length
        }}
        of {{ data.length }}</span
      >
    </p> -->
    <div class="flex gap-x-4 md:items-center">
      <select
        name=""
        id=""
        class="px-1 pr-8 md:py-2 py-3 rounded-xl focus:outline-none focus:border-outlineGray border border-outlineGray text-center text-sm font-medium uppercase"
        @change="changeSize"
        v-model="selectedSize"
        v-if="showSizes"
      >
        <option
          v-for="s in [
            '15',
            '20',
            '30',
            '40',
            '50',
            '60',
            '70',
            '80',
            '90',
            '100',
          ]"
          :key="s"
          :value="s"
          :selected="s === '15' ? true : false"
        >
          {{ s }}
        </option>
      </select>
      <p
        class="hidden md:block text-left text-xs md:text-sm md:my-4 text-dark-200"
      >
        Showing

        <span
          >{{ startItem }} -
          {{ endItem }}
          of {{ data.length }}</span
        >
      </p>
    </div>

    <div
      v-if="!fromCompliance && showNextLink()"
      class="flex items-center justify-center md:mt-4"
    >
      <div class="flex gap-2 items-center">
        <div
          @click="jumpToFirstPage"
          v-if="showPreviousLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-white text-dark-400 rounded-xl p-2 text-base"
        >
          <i class="pi pi-angle-double-left" />
        </div>
        <div
          v-if="showPreviousLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-white text-dark-400 rounded-xl p-2 text-base"
          @click="updatePage(currentPage - 1)"
        >
          <i class="pi pi-angle-left" />
        </div>
      </div>
      <ul class="flex items-center">
        <li
          @click="updatePage(0)"
          :class="[
            currentPage === 0
              ? ' disabled cursor-not-allowed bg-primary text-white rounded-xl h-8 w-8'
              : 'text-dark-800 h-8 w-8',
            'flex items-center justify-center',
          ]"
        >
          <a class="pagination-link" :disabled="currentPage === 0">1</a>
        </li>
        <li v-if="currentPage > 2">
          <span class="pagination-link disabled">...</span>
        </li>
        <li
          v-for="pageNumber in visiblePages"
          :key="pageNumber"
          @click="updatePage(pageNumber - 1)"
          :class="[
            activePage === pageNumber
              ? ' disabled cursor-not-allowed bg-primary text-white rounded-xl h-8 w-8'
              : 'text-dark-800 h-8 w-8',
            'flex items-center justify-center',
          ]"
        >
          <a class="pagination-link">{{ pageNumber }}</a>
        </li>
        <li v-if="currentPage < totalPages - 2">
          <span class="pagination-link disabled">...</span>
        </li>
        <li
          @click="updatePage(totalPages - 1)"
          :class="[
            currentPage === totalPages - 1
              ? ' disabled cursor-not-allowed bg-primary text-white rounded-xl h-8 w-8'
              : 'text-dark-800 h-8 w-8',
            'flex items-center justify-center',
          ]"
        >
          <a
            class="pagination-link"
            :disabled="currentPage === totalPages - 1"
            >{{ totalPages }}</a
          >
        </li>
      </ul>

      <div class="flex gap-2 items-center">
        <div
          v-if="showNextLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-primary bg-opacity-20 text-primary rounded-xl p-2 text-base"
          @click="updatePage(currentPage + 1)"
        >
          <i class="pi pi-angle-right" />
        </div>

        <div
          @click="jumpToLastPage"
          v-if="showNextLink()"
          class="flex cursor-pointer font-medium border-t border-gray bg-primary bg-opacity-20 text-primary rounded-xl p-2 text-base"
        >
          <i class="pi pi-angle-double-right" />
        </div>
      </div>
    </div>

    <div
      v-else-if="fromCompliance"
      class="flex items-center justify-end md:justify-center gap-8 md:mt-4"
    >
      <div class="flex gap-2 items-center">
        <div
          :class="
            showPreviousLink()
              ? 'text-primary cursor-pointer font-bold'
              : 'text-primary cursor-not-allowed text-opacity-50'
          "
          class="flex items-center gap-2 font-medium rounded-xl p-2 text-base"
          @click="updatePage(currentPage - 1)"
        >
          <i class="pi pi-angle-left" />
          <span class="text-sm">Back</span>
        </div>
      </div>

      <div class="flex gap-2 items-center">
        <div
          :class="
            showNextLink()
              ? 'text-primary cursor-pointer font-bold'
              : 'text-primary cursor-not-allowed text-opacity-50'
          "
          class="flex items-center gap-2 font-medium rounded-xl p-2 text-base"
          @click="updatePage(currentPage + 1)"
        >
          <span class="text-sm">Next page</span>
          <i class="pi pi-angle-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

// const props = defineProps(["data", "currentPage", "pageSize", "visibleData"]);
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  currentPage: {
    type: Number,
    default: 0,
  },
  pageSize: {
    type: [String, Number],
    default: 0,
  },
  visibleData: {
    type: Array,
    default: () => [],
  },
  showSizes: {
    type: Boolean,
    default: false,
  },
  fromCompliance: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["page:update", "query", "size"]);

const queryFunc = () => {
  emit("query");
};

const activePage = ref(0);

const startItem = computed(() => {
  return props.currentPage * Number(props.pageSize) + 1;
});

const endItem = computed(() => {
  const lastItem = (props.currentPage + 1) * Number(props.pageSize);
  return Math.min(lastItem, props.data.length);
});

const updatePage = (pageNumber) => {
  console.log(pageNumber, "PN", props.currentPage);
  activePage.value = pageNumber + 1;
  emit("page:update", pageNumber, props.data);
  queryFunc();
};

// const currentPage = ref(1);
// const totalItems = 100; // Change this value according to your total number of items

const totalPages = computed(() =>
  Math.ceil(props.data.length / Number(props.pageSize))
);
console.log(totalPages.value, "TP");

const visiblePages = computed(() => {
  const startPage = Math.max(2, props.currentPage);
  const endPage = Math.min(startPage + 2, totalPages.value - 1);
  return Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
});

function goToPage(page) {
  if (page >= 1 && page <= totalPages.value) {
    props.currentPage = page;
  }
}

const showPreviousLink = () => {
  return props.currentPage == 0 ? false : true;
};
const showNextLink = () => {
  return props.currentPage == totalPages.value - 1 ? false : true;
};
const jumpToLastPage = () => {
  updatePage(totalPages.value - 1);
};
const jumpToFirstPage = () => {
  updatePage(0);
};
const selectedSize = ref(15);

function changeSize(e) {
  selectedSize.value = e.target.value;
  emit("size", Number(selectedSize.value));
}
const watchSize = computed(() => props.pageSize);
watch(watchSize, (val) => {
  selectedSize.value = val;
});
</script>

<style scoped>
.pagination-link {
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.25rem;
  /* border: 1px solid #ddd; */
  /* background-color: #fff; */
  /* color: #515251; */
}

/* .pagination-link:hover {
  background-color: #f0f0f0;
} */

.disabled {
  /* opacity: 0.5; */
  cursor: not-allowed;
}

.active {
  background-color: #f0f0f0;
}
</style>
