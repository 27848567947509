<template>
  <main
    class="min-h-screen onprint bg-blueGray-100 md:ml-8 p-3 md:p-0 mx-auto md:mx-0 w-full md:w-11/12"
  >
    <div class="text-right md:text-left md:mt-5 font-medium text-sm">
      <p class="text-headerText">Support/Talk to us</p>
    </div>
    <div class="text-left mt-5 font-bold text-xl md:text-2xl">Talk to us</div>
    <div
      class="flex items-center md:text-center w-full w-fit mx-auto border border-outlineGray rounded-xl tracking-wide justify-center md:mb-- mb-0 gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
    >
      <span
        @click="toggleAdd"
        class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
        :class="bonus ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
        >Talk to us</span
      >
      <span
        @click="toggleAdd"
        class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
        :class="!bonus ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
      >
        <span class="hidden sm:block"> Frequently Asked Questions </span>
        <span class="sm:hidden"> FAQ </span>
      </span>
    </div>

    <div class="w-fit mx-auto rounded-2xl bg-newAsh pb-11">
      <div class="bg-white">
        <div v-if="bonus">
          <div
            class="back_ground rounded-2xl h-background flex flex-col justify-center px-0 sm:px-10 md:px-36"
          >
            <p class="text-white font-bold text-xl sm:text-3xl ml-5 sm:ml-0">
              Send us a message
            </p>
            <p class="hidden sm:block text-white text-lg mt-2 font-medium">
              Our support team is available to assist with your questions and
              inquiries. Fill the form and we will be in touch within 24 hours
            </p>
          </div>

          <div class="md:w-2/3 mt-5 mx-auto">
            <form
              @submit.prevent="submit"
              autocomplete="on"
              class="flex flex-col px-6 sm:px-12 lg:px-8 xl:px-10"
            >
              <easiTextInput
                placeholder="Title"
                v-model="args.title"
                type="text"
                name="firstName"
                class="mt-1 mb-5"
                required
              />
              <easiTextInput
                placeholder="Email"
                v-model="args.email"
                type="email"
                name="email"
                class="mt-1 mb-5"
                autocom
              />
              <easiTextArea
                v-model="args.message"
                class="mt-4focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-5 mb-6"
                name="message"
                id="description"
                placeholder="Message"
                cols="30"
                rows="5"
                required
              ></easiTextArea>
              <div class="mt-5">
                <easiButton :loading="isSubmitting" :block="true">
                  <span class="text-sm"> Send Message </span>
                </easiButton>
              </div>

              <!-- <button
                class="focus:outline-none text-sm mt-2 py-3 px-4 md:mx-auto justify-center bg-primary rounded-full text-white w-full"
                
              >
              Send Message
              </button> -->
            </form>
          </div>
          <hr
            class="my-14 w-2/3 mx-auto border border-dividerColor text-dividerColor"
          />
        </div>

        <div v-else class="pt-20 px-12">
          <div v-for="question in questions" :key="question.id" class="mb-10">
            <div
              class="flex justify-between gap-x-3 cursor-pointer mb-9"
              @click="makeQuestion(question.id)"
            >
              <p class="font-extrabold text-lg text-grey">
                {{ question.question }}
              </p>
              <div>
                <i
                  :class="
                    selectedQuestion === question.id
                      ? 'fas fa-angle-down'
                      : 'fas fa-angle-right'
                  "
                  class="inline-flex justify-end self-end text-right cursor-pointer text-sm text-grey"
                />
              </div>
            </div>
            <div
              v-if="selectedQuestion === question.id"
              class="w-90/100 font-normal mx-auto text-left mt-3"
            >
              <div v-if="question.sub">
                <div v-for="(sub, subIndex) in question.sub" :key="subIndex">
                  <div
                    class="flex justify-between gap-x-3 cursor-pointer mb-5"
                    @click="makeSubQuestion(subIndex)"
                  >
                    <p class="font-semibold text-lg text-grey">
                      {{ sub.question }}
                    </p>
                    <div>
                      <img
                        v-if="selectedSubQuestion === subIndex"
                        src="../../assets/icons/MinusIcon.svg"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../assets/icons/newplusSupport.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    v-if="selectedSubQuestion === subIndex"
                    class="w-90/100 font-normal mx-auto text-left mt-3 mb-5"
                  >
                    {{ sub.answer }}
                  </div>
                </div>
              </div>
            </div>
            <hr class="border border-newerGray my-5" />
          </div>

          <hr
            class="my-14 mx-auto border border-newLightGray text-dividerColor"
          />
        </div>

        <div class="pb-14 flex flex-col gap-y-7">
          <div class="flex items-center gap-x-2 justify-center">
            <div
              class="bg-newGrey p-2 rounded-full flex items-center justify-center"
            >
              <img src="@/assets/img/newEmail.svg" alt="" />
            </div>
            <p class="text-grey text-base font-normal">hi@myeazipay.com</p>
            <div
              class="ml-3 cursor-pointer"
              @click="copyText('hi@myeazipay.com')"
            >
              <img src="@/assets/icons/newCopy.svg" alt="" />
            </div>
          </div>
          <div class="flex items-center gap-x-2 justify-center">
            <div
              class="bg-newGrey p-2 rounded-full flex items-center justify-center"
            >
              <img src="@/assets/icons/newPhone.svg" alt="" />
            </div>
            <p class="text-grey text-base font-normal">09134766185</p>
            <div class="ml-3 cursor-pointer" @click="copyText('09134766185')">
              <img src="@/assets/icons/newCopy.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-8 items-center justify-center mt-11">
        <img class="" src="@/assets/icons/new-linkedin.svg" alt="" />
        <img class="" src="@/assets/icons/new-instagram.svg" alt="" />
        <img class="" src="@/assets/icons/new-twitter.svg" alt="" />
        <img class="" src="@/assets/icons/new-whatsapp.svg" alt="" />
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";

const toast = useToast();
const store = useDataStore();

const { mutate } = store;

const bonus = ref(true);
const isSubmitting = ref(false);
let args = reactive({
  title: "",
  email: "",
  message: "",
});
const questions = [
  {
    id: 0,
    question: "General",
    sub: [
      {
        question: "What is Eazipay?",
        answer:
          "Eazipay is a simple, easy-to-use software solution for individuals and businesses to manage their employees and run their monthly payroll and tax remittance in five minutes or less. We are here to support your growth.",
      },
      {
        question: "How do I start using Eazipay?",
        answer:
          "Creating an account on Eazipay is made as ‘eazi’ as possible. All you need do is register on www.myeazipay.com or download the Eazipay app on your AppStore.",
      },
      {
        question: "Is Eazipay only for business owners?",
        answer:
          "Not at all. Eazipay is for both individuals and businesses (small, medium and large-scale organisations).",
      },
      {
        question: "I forgot my password, can I still access my account?",
        answer:
          "Definitely. Click here to log in and select ‘forgot pin. An OTP would be sent to your registered email or phone number which you can input to verify your account and set a new password.",
      },
      {
        question: "How do I contact the team?",
        answer:
          "We are available to attend to your enquiries 24/7. Feel free to reach us via: Email: hi@myeazipay.com WhatsApp & Phone:+2349134766185 Social Media: @myeazipay ",
      },
      {
        question: "How secure is Eazipay?",
        answer:
          "Security is paramount at Eazipay. We have implemented good security practices to ensure robust protection for our customers and user-based security features like 2FA, biometrics, and other security verifications. Our annual audits also ensure that we are NDPR compliant.",
      },
      {
        question: "Is Eazipay just a payroll and payment software?",
        answer:
          "We are more than just a payroll business. We are on a mission to support the growth of businesses. As a user, you have access to other free products and services on our website — Hiring, Assessments, HR Stores, Bookkeeping, Device Management, Salary Advance, Annual PAYE Filing, and more!",
      },
    ],
  },
  {
    id: 1,
    question: "Payroll",
    sub: [
      {
        question: "How can I run payroll for my personal staff on Eazipay?",
        answer:
          "Simply create a personal account or log into your personal account, add your personal staff as employees and select 'run payroll'",
      },
      {
        question: "I know little about filing taxes, can Eazipay help?",
        answer:
          "Not to worry 😊. We help provide all the information you need to know about tax laws and regulations, and filing taxes. We also have ready hands to guide you through the process. Contact us to get started.",
      },
      {
        question: "Do you offer your payroll service in my state?",
        answer:
          "Distance is no barrier for us. We offer our payroll services in all 36 states of Nigeria and the Federal Capital Territory.",
      },
      {
        question: "Is payroll run weekly or monthly?",
        answer:
          "It all depends on you and the agreement you have between your employees. You can run weekly, bi-weekly or monthly payroll with Eazipay.",
      },
      {
        question: "What compliance requirements can I use Eazipay for?",
        answer:
          "Eazipay can be used for employee benefits and requirements such as Pension, PAYE, State IRS and FIRS, and Insurance (HMO, NHF, NSITF). ",
      },
      {
        question: "How long does it take to run payroll?",
        answer:
          "It takes less than five minutes, regardless of your employee size.  ",
      },
    ],
  },
  {
    id: 2,
    question: "Employee Management",
    sub: [
      {
        question: "How can I add my 125 employees at once?",
        answer:
          "Log into your account via www.myeazipay.com and you can upload your employee excel sheet to add them all at once.",
      },
      {
        question:
          "What other benefits can I provide for my employees on Eazipay?",
        answer:
          "Asides prompt payment of salaries, you can also register your employees for HMO, Pension and other insurances. Regular users of Eazipay can also access loans for their employees.",
      },
      {
        question:
          "Can I store my employee data on Eazipay, and how safe is it?",
        answer:
          "Absolutely! You can save and manage your employee personal information, health information, family & emergency information, and professional information on Eazipay. We are 100% safe and would never share this data with a third party.",
      },
    ],
  },
  {
    id: 3,
    question: "Loans",
    sub: [
      {
        question: "What loans are available for me on Eazipay?",
        answer:
          "You can access a variety of loan options: Payroll Cover for when your monthly payroll falls short; Enterprise Loans for your business operations; as well as Salary Advance and Employee Loans for your staff.",
      },
      {
        question: "What’s the eligibility criteria for loans?",
        answer:
          "Loans are available for customers who have used Eazipay consecutively for at least 3 months or 90 days.",
      },
      {
        question:
          "What’s the highest loan amount that I can access for a payroll cover?",
        answer:
          "Your available loan amount would be determined by your payroll load per month. ",
      },
      {
        question: "Can I take loans as an SME business owner?",
        answer:
          "Absolutely! There are small business loans and microloans on Eazipay for you to take advantage of.",
      },
      {
        question: "What are the interest rates on your loans?",
        answer:
          "Our interest rates vary depending on the amount and repayment period selected, but typically between 3-5%, which is significantly lower than 9% offered by traditional banks and other financial institutions.",
      },
    ],
  },
  {
    id: 4,
    question: "Payments",
    sub: [
      {
        question: "Are the funds in my wallet secure?",
        answer:
          "Your money is securely encrypted and protected, and stored in an actual bank.",
      },
      {
        question: "How do I buy airtime or internet data?",
        answer:
          "Open your Eazipay mobile app and click ‘pay bills’ or ‘buy airtime’ directly from the dashboard. Select your airtime or internet provider, enter amount or choose your subscription package, then enter your pin to confirm your transaction.",
      },
      {
        question: "What bills can I pay?",
        answer:
          "You can pay your internet, electricity, TV or utility bills with the Eazipay mobile app.",
      },
      {
        question: "Does Eazipay function as a payment app for non-employees?",
        answer:
          "Absolutely! The Eazipay app allows you to send payments to your family, friends, religious organizations, and so on. You can also save them as beneficiaries and set monthly recurring payments for them.",
      },
    ],
  },

  {
    id: 5,
    question: "Pricing",
    sub: [
      {
        question:
          "My payroll is a little flexible each month, can this be customized with Eazipay?",
        answer:
          "Yes, it can. We understand that the payroll needs of each company varies, so we have built the software with this in mind. Kindly reach out to us to discuss your unique payroll needs.",
      },
      {
        question: "How much does Eazipay cost?",
        answer:
          "We’ve fully catered to the business needs of your company and we’ve designed the most reasonable pricing plan for you. To view our packages, kindly sign up or login to your account and select ‘pricing’. You can also take advantage of our 30-day free trial period that is available when you first register.",
      },
      {
        question: "Is Eazipay free?",
        answer:
          "There’s a free subscription plan on Eazipay, depending on your payroll needs. We advise you choose a pricing plan that absolutely suits your needs.",
      },
      {
        question: "How can I check the pricing?",
        answer:
          "Whilst you’re logged into your account, navigate to ‘settings’, and click ‘pricing’ to view all the information about our pricing packages.",
      },
    ],
  },
];
const selectedQuestion = ref(0);
const selectedSubQuestion = ref(0);
function makeQuestion(question) {
  if (selectedQuestion.value === question) {
    selectedQuestion.value = null;
  } else {
    selectedQuestion.value = question;
  }
}
function makeSubQuestion(question) {
  if (selectedSubQuestion.value === question) {
    selectedSubQuestion.value = null;
  } else {
    selectedSubQuestion.value = question;
  }
}

function copyText(val) {
  const text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}
async function toggleAdd() {
  bonus.value = !bonus.value;
}
async function submit() {
  try {
    isSubmitting.value = true;
    const payload = {
      email: args.email,
      title: args.title,
      message: args.message,
    };
    const res = await mutate({
      endpoint: "TalktoUs",
      data: {
        input: payload,
      },
      service: "SETTINGS",
    });
    args = {
      title: "",
      email: "",
      message: "",
    };
    console.log("res", res);
    toast.success("Message Submitted!");

    console.log("res is ,", res);
  } catch (e) {
    console.error(e);
  } finally {
    isSubmitting.value = false;
  }
}
// onMounted(() => {
//   const jivo = document.createElement("script");
//   jivo.setAttribute("src", "//code.jivosite.com/widget/XsM4BGKrdm");
//   document.body.appendChild(jivo);
// });
</script>

<style scoped>
.w-90\/100 {
  width: 90%;
}
.w-fit {
  width: 85%;
  margin-top: 35px;
}
.mx-fit {
  padding-left: 130px;
  padding-right: 130px;
}
.back_ground {
  background-image: url("../../assets/img/sendUsBackground.svg");

  background-repeat: no-repeat;
  background-size: 100%;
}
.h-background {
  height: 15vh;
}
@media (min-width: 500px) {
  .h-background {
    height: 10rem;
  }
}
@media (min-width: 640px) {
  .back_ground {
    background-image: url("../../assets/img/sendUsBackground.svg");
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .h-background {
    /*  height: 13rem; */
  }
}
@media (min-width: 825px) {
  .h-background {
    height: 30vh;
  }
}
@media (min-width: 900px) {
  .h-background {
    height: 22rem;
  }
}
</style>
