<template>
  <div v-if="data" class="flex items-center justify-between gap-2">
    <div class="w-full">
      <div class="flex flex-col gap-1">
        <div class="flex items-center gap-2">
          <p class="font-medium text-base">{{ data.name || "N/A" }}</p>
          <p v-if="data.membership == 'owner'" class="text-xs">(You)</p>
          <div
            class="rounded capitalize px-3 py-1 text-xs"
            :class="
              data.membership == 'owner'
                ? 'text-primary bg-newerAsh'
                : 'text-deepGold bg-lighterOrange'
            "
          >
            {{ data.membership }}
          </div>
        </div>
        <div class="flex items-center flex-wrap md:gap-x-6">
          <div class="flex gap-2">
            <p class="text-grey">Email:</p>
            <p class="text-dark-200">{{ data.email || "N/A" }}</p>
          </div>
          <div class="flex gap-2 text-sm items-end">
            <p class="text-dark-800">Last Activity:</p>
            <p class="text-newGray">{{ data.lastActivity || "N/A" }}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="userProfile && userProfile.membership == 'owner'"
      class="flex justify-end"
    >
      <div class="flex relative">
        <img
          v-if="data.membership == 'member'"
          @click="showDropdown = !showDropdown"
          src="@/assets/icons/ellipsis.svg"
          class="cursor-pointer"
          alt=""
        />

        <OnClickOutside @trigger="showDropdown = false">
          <div
            v-show="showDropdown"
            class="rounded-xl flex p-2 z-10 flex-col w-40 bg-white absolute -left-40 top-0 shadow-lg text-sm"
          >
            <div
              class="flex gap-2 cursor-pointer hover:bg-mint p-1"
              @click="
                showModal = true;
                showDropdown = false;
              "
            >
              <img src="@/assets/icons/shield-check.svg" alt="" />
              <p class="text-grey">User Permission</p>
            </div>
            <hr class="my-2" />
            <div
              class="flex gap-2 cursor-pointer hover:bg-secondary-crepe p-1"
              @click="removeDeveloper"
            >
              <img src="@/assets/icons/red-x-mark.svg" alt="" />
              <p class="text-error">Remove</p>
            </div>
          </div>
        </OnClickOutside>
      </div>
    </div>

    <easiModal
      v-if="showModal"
      @close="showModal = false"
      :isHeader="false"
      :isBorder="false"
      :lite="false"
      :persistence="true"
      :showCloseButton="false"
      apiDashboard
      rounded="3xl"
    >
      <form
        @submit.prevent="sendInvite"
        autocomplete="on"
        class="flex flex-col gap-y-3 pl-8 pt-8 pr-2 relative"
      >
        <img
          class="w-7 h-7 cursor-pointer absolute right-2 top-6"
          src="@/assets/icons/x.svg"
          alt=""
          @click="showModal = false"
        />
        <p class="font-medium text-xl text-grey mb-3 text-center">
          {{ data.name.trim() || "User" }}'s Permission
        </p>

        <ApiInviteProduct
          label="Products user have access to "
          @done="args.products = $event"
          :products="transformedProducts"
          :selected="selectedProducts"
        />

        <hr class="mt-4 mb-2" />
        <div
          v-for="option in permissionOptions"
          :key="option.title"
          class="flex justify-between border-b border-outlined py-4"
        >
          <p class="text-base text-dark-800">{{ option.title }}</p>

          <div
            class="bg-newAsh flex items-center gap-2 border border-outlineGray rounded-lg p-1"
          >
            <span
              @click="addRemovePermission('add', option.permission)"
              :class="[
                args.permission.includes(option.permission)
                  ? 'bg-white text-black'
                  : 'text-newGray',
              ]"
              class="p-1 px-2 rounded cursor-pointer"
              >Yes</span
            >
            <span
              @click="addRemovePermission('remove', option.permission)"
              :class="[
                !args.permission.includes(option.permission)
                  ? 'bg-white text-black'
                  : 'text-newGray',
              ]"
              class="p-1 px-2 rounded cursor-pointer"
              >No</span
            >
          </div>
        </div>

        <div
          class="w-full mb-3 mt-8 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <div class="w-full md:w-full">
            <easiButton
              type="submit"
              :loading="loading"
              class="w-full"
              @click="updateSettings"
            >
              Update Setting
            </easiButton>
          </div>
        </div>
      </form>
    </easiModal>
  </div>
</template>

<script setup>
import ApiInviteProduct from "@/components/ApiDashboard/ApiInviteProduct.vue";

import { OnClickOutside } from "@vueuse/components";

import { ref, computed, watch, reactive } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;

const props = defineProps({
  data: Object,
});

const emit = defineEmits("query");

const userProfile = computed(() => store.apiProfile);

const loading = ref(false);
const showDropdown = ref(false);
const showModal = ref(false);

const selectedProducts = ref([]);
const allProducts = computed(() => store.getAllproducts || []);
const transformedProducts = computed(() => {
  return allProducts.value.map((product) => {
    return {
      label: product.name,
      value: product._id,
    };
  });
});

const permissionOptions = reactive([
  {
    title: "Allow user to add new product",
    permission: "ADD_PRODUCT",
  },
  {
    title: "Allow user to edit product details",
    permission: "EDIT_PRODUCT",
  },
]);

const args = reactive({
  permission: [],
  products: [],
});

watch(showModal, () => {
  if (showModal.value) {
    const { products, permission } = props.data;
    args.products = products.map((el) => el.productId);
    args.permission = [...permission];

    selectedProducts.value = products
      ? products.map((el) => {
          return {
            label: el.productName,
            value: el.productId,
          };
        })
      : [];
  }
});

const addRemovePermission = (type, permission) => {
  if (type == "add") {
    if (args.permission.includes(permission)) return;
    args.permission.push(permission);
  } else {
    args.permission = args.permission.filter((el) => el !== permission);
  }
};

const updateSettings = async () => {
  const payload = { ...args };
  payload.employeeId = props.data.employeeId;

  try {
    loading.value = true;

    const res = await mutate({
      endpoint: "UpdateUserPermissionSettings",
      data: {
        input: payload,
      },
      service: "API",
    });

    if (res.success) {
      showModal.value = false;
      emit("query");
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};

const removeDeveloper = async () => {
  try {
    loading.value = true;

    const res = await mutate({
      endpoint: "RemoveFromTeam",
      data: {
        input: {
          employeeId: props.data.employeeId,
        },
      },
      service: "API",
    });

    if (res.success) {
      showDropdown.value = false;
      emit("query");
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
