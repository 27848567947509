<template>
  <main class="px-0 md:px-4 py-4">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        TAX FILING SERVICE / APPLICATION
      </h4>
      <div class="text-left sm:mt-4 sm:mb-6">
        <easiBackButton></easiBackButton>
      </div>
    </div>
    <div class="bg-white rounded-3xl py-8 lg:px-32">
      <form
        @submit.prevent="submit"
        autocomplete="on"
        class="flex flex-col mt-5 px-3 md:px-12 lg:px-8 xl:px-10"
      >
        <p class="font-bold text-2xl text-center">Annual PAYE Returns</p>
        <p class="mt-7 font-normal text-base text-center md:text-left">
          Kindly fill in the following information
          <br class="md:hidden" />
          <span class="italic"> (all fields are required) </span>
        </p>
        <div class="mt-7">
          <span class="font-medium text-lg">Personal Information</span>
        </div>
        <div class="md:flex justify-between gap-6">
          <div class="w-full">
            <easiTextInput
              placeholder="First Name"
              v-model="args.firstName"
              type="text"
              name="firstName"
              required
            />
          </div>
          <div class="w-full">
            <easiTextInput
              placeholder="Last Name"
              v-model="args.lastName"
              type="text"
              name="lastName"
              required
            />
          </div>
        </div>
        <div class="md:flex justify-between gap-6">
          <div class="w-full">
            <easiTextInput
              placeholder="Phone Number"
              v-model="args.phoneNumber"
              type="number"
              name="phoneNumber"
              required
            />
          </div>
          <div class="w-full">
            <easiTextInput
              placeholder="Email Address"
              v-model="args.email"
              type="text"
              name="email"
              required
            />
          </div>
        </div>
        <div class="w-full">
          <easiTextInput
            placeholder="Address"
            v-model="args.address"
            type="text"
            name="address"
            required
          />
        </div>
        <div class="mt-7">
          <span class="font-medium text-lg">Company Information</span>
        </div>
        <div class="w-full">
          <easiTextInput
            placeholder="Company Name"
            v-model="args.companyName"
            type="text"
            name="companyName"
            required
          />
        </div>
        <div class="w-full">
          <easiTextInput
            placeholder="Company Address "
            v-model="args.companyAddress"
            type="text"
            name="companyAddress"
            required
          />
        </div>

        <div class="">
          <p class="text-base font-normal text-grey relative top-3">
            How many years would you like to file for?
          </p>
          <easiTextInput
            newLabel=""
            placeholder="Number of Years"
            v-model="args.years"
            type="text"
            name="years"
            required
          />
        </div>
        <div class="">
          <easiTextInput
            placeholder="Number of Staff"
            v-model="args.staffCount"
            type="text"
            name="staffCount"
            required
          />
        </div>

        <AddResidentialState
          label="Kindly select residential state of your employees"
          @done="handleDone"
          :propValue="allStates"
        />

        <p
          v-if="showResisdentialError && residentialStates.length === 0"
          class="text-red text-sm"
        >
          Please select atleast one residential state
        </p>
        <Switch
          label="Have you completed the remittance of PAYE from January to December of this year for filing?"
          v-model="args.completedAnnualRemittance"
        />
        <AddResidentialState
          label="Please select the months that are still pending tax filing."
          @done="handleDone2"
          :propValue="AllMonths"
          noDesc
        />
        <p
          v-if="showSelectedMonthsError && selectedMonths.length === 0"
          class="text-red text-sm"
        >
          Please select atleast one residential state
        </p>

        <Switch
          label="Have you settled the business premises and development levy?"
          v-model="args.premisesDevelopmentLevy"
        />
        <Switch
          label="Does your company possess a Corporate Payer ID number and corresponding password?"
          v-model="args.payerIdPassword"
        />
        <Switch
          label="Have all employees been assigned a Payer ID number?"
          v-model="args.allEmployeesPayerId"
        />
        <div class="mt-7">
          <span class="font-medium text-lg">Tax filing Information</span>
        </div>
        <AddResidentialState
          label="Kindly select all services you need"
          @done="handleDoneServices"
          type="service"
          :propValue="allServices"
        />
        <p
          v-if="showServiceError && services.length === 0"
          class="text-red text-sm"
        >
          Please select atleast one service
        </p>

        <div class="flex justify-end mt-8">
          <easiButton
            color="primary"
            class="rounded-full bg-white w-full md:w-52"
          >
            Submit Application
          </easiButton>
        </div>
      </form>
    </div>

    <easiAlert
      v-if="thanksModal"
      @close="thanksModal = false"
      noIcon
      :showDefaultTop="false"
    >
      <div class="flex justify-center pt-10">
        <span>
          <img src="@/assets/img/success.gif" class="h-32" alt="" />
        </span>
        <!-- <img class="w-32" :src="success" alt="" /> -->
      </div>
      <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
        Thank you, your application has been submitted! We will be in touch with
        you soon
      </div>
      <main class="flex flex-col items-center w-full mt-6">
        <easiButton
          class="w-40 min-w-full"
          @click="
            thanksModal = false;
            $router.push('/admin/tax-filing');
          "
          >Okay !</easiButton
        >
      </main>
    </easiAlert>
    <easiRoundLoader v-if="setisLoading" />
  </main>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import Switch from "@/components/TaxFiling/TaxSwitch.vue";

import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import AddResidentialState from "@/components/TaxFiling/AddResidentialState.vue";

const toast = useToast();
const store = useDataStore();
const { query, mutate, set } = store;
const setisLoading = ref(false);

const thanksModal = ref(false);
// const companyAdmin = computed(() => store.getCompanyAdmin);

async function queryCompany() {
  try {
    setisLoading.value = true;
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
    await fetchCompanyAdminDetails();
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  } finally {
    setisLoading.value = false;
  }
}
const fetchCompanyAdminDetails = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });
};

const queryCompanyAdmin = async () => {
  try {
    const data = await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
    // loading.value = false;
    args.value.companyName = data.company.company.companyName;
    args.value.staffCount = data.totalEmployeeCount;
    args.value.firstName = data.companyAdmin.firstName;
    args.value.lastName = data.companyAdmin.lastName;
    args.value.phoneNumber = data.companyAdmin.phoneNumber;
    args.value.email = data.companyAdmin.email;
  } catch (err) {
    console.log(err);
  }
};
const getCompanyKycInfo = async () => {
  try {
    const { data } = await query({
      endpoint: "GetCompanyKycInformation",
      payload: {},
      service: "SETTINGS",
      storeKey: "companyKyc",
    });
    if (data.companyAddress) {
      args.value.companyAddress = data.companyAddress.officeAddress;
    }
    await queryCompanyAdmin();
  } catch (err) {
    console.log(err);
  }
};
const queryKyc = async () => {
  try {
    setisLoading.value = true;
    const { data } = await query({
      endpoint: "GetKycInformation",
      payload: {},
      service: "SETTINGS",
      storeKey: "listKyc",
    });
    if (data.personalInformation) {
      args.value.address = data.personalInformation.addressInformation.address;
    }
    await getCompanyKycInfo();
  } catch (err) {
    console.log(err);
  } finally {
    setisLoading.value = false;
  }
};
onMounted(async () => {
  // queryCompany();
  await queryKyc();
});
const AllMonths = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
const allStates = ref([
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
]);
const allServices = ref([
  "Direct Assesment",
  "State Tax clearance",
  "Annual PAYE Filing",
]);
const residentialStates = ref([]);
const selectedMonths = ref([]);
const services = ref([]);
const showResisdentialError = ref(false);
const showSelectedMonthsError = ref(false);
const showServiceError = ref(false);
// const args = ref({
//   firstName: companyAdmin.value.companyAdmin.firstName,
//   lastName: companyAdmin.value.companyAdmin.lastName,
//   phoneNumber: companyAdmin.value.companyAdmin.phoneNumber,
//   address: "",
//   companyName: companyAdmin.value.company.company.companyName,
//   companyAddress: "",
//   staffCount:  companyAdmin.value.company.company.companySize,
// });
const args = ref({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  address: "",
  companyName: "",
  companyAddress: "",
  staffCount: "",
  years: "",
  premisesDevelopmentLevy: true,
  payerIdPassword: false,
  allEmployeesPayerId: false,
  completedAnnualRemittance: false,
});
const errorRules = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  companyName: "",
  companyAddress: "",
  staffCount: "",
  years: "",
  premisesDevelopmentLevy: false,
  payerIdPassword: false,
  allEmployeesPayerId: false,
  completedAnnualRemittance: false,
};

const handleDone = (e) => {
  residentialStates.value = e;
};
const handleDone2 = (e) => {
  selectedMonths.value = e;
};

const handleDoneServices = (e) => {
  services.value = e;
};
async function submit() {
  if (residentialStates.value.length === 0) {
    showResisdentialError.value = true;
    return;
  }
  if (selectedMonths.value.length === 0) {
    showSelectedMonthsError.value = true;
    return;
  }
  if (services.value.length === 0) {
    showServiceError.value = true;
    return;
  }
  try {
    setisLoading.value = true;

    let newApiService = [];
    services.value.forEach((item) => {
      if (item === "Direct Assesment") {
        newApiService.push("Direct_Assessment");
      } else if (item === "State Tax clearance") {
        newApiService.push("State_Tax_Clearance");
      } else if (item === "Annual PAYE Filing") {
        newApiService.push("Annual_Paye_Filing");
      } else if (item === "State Tax Registration") {
        newApiService.push("State_Tax_Registration");
      } else if (item === "STR/ For Company") {
        newApiService.push("STR_Company");
      } else if (item === "STR/ For Staff") {
        newApiService.push("STR_Staff");
      }
    });

    const payload = {
      personalTaxInfo: {
        firstName: args.value.firstName,
        lastName: args.value.lastName,
        phoneNumber: args.value.phoneNumber,
        address: args.value.address,
        email: args.value.email,
      },
      companyTaxInfo: {
        companyName: args.value.companyName,
        address: args.value.companyAddress,
        staffNo: args.value.staffCount.toString(),
        employeeStates: residentialStates.value,
        completedAnnualRemittance:  args.value.completedAnnualRemittance,

      },
      taxInformation: {
        services: newApiService,
        premisesDevelopmentLevy: args.value.premisesDevelopmentLevy,
        payerIdPassword: args.value.payerIdPassword,
        allEmployeesPayerId: args.value.allEmployeesPayerId,
        noOfYears: Number(args.value.years),
        selectedMonths: selectedMonths.value.map((each) =>
          AllMonths.value.indexOf(each)
        ),
      },

    };
    console.log("others", payload);
    let res = await mutate({
      endpoint: "SubmitTaxForm",
      service: "PAYROLL",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      thanksModal.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.log("error", e);
    toast.error(e.message);
  } finally {
    setisLoading.value = false;
  }
}
</script>

<style scoped>
.items-container {
  min-height: 40vh;
}
.bannerText {
  background: linear-gradient(90deg, #11453b 0%, rgba(17, 69, 59, 0) 99.14%);
}
.back_ground {
  background-image: url("../../../assets/img/taxFilingBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 768px) {
  .back_ground {
    background-image: url("../../../assets/img/taxFilingBackgroundMobile.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
